import React, { useState } from "react";
import "../App.css";
import AdminHeader from "./AdminHeader.js";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { polygon_flashloan_address } from "../Network_Address.js";
import { polygon_flashloan_abi } from "../Network_Abi.js";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import TranSpinners from "../TransSpinner";
import { NODE_URL, ADMIN_ADDRESS } from '../config';

const Removelist = () => {

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [TransactionSpinner, setTransactionSpinner] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const checkUserRole = async (Enteredaddress) => {
    try {
      let checkUser = await axios.get(`${NODE_URL}:3006/apiv4/checkWhiteList?walletAddress=${Enteredaddress}`)
      if (checkUser.data.status === 'success') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const checkAdminRole = async () => {
    if (address === ADMIN_ADDRESS) {
      return true;
    } else {
      return false;
    }
  }

  const RemoveUser = async () => {
    try {
      setTransactionSpinner(true);
      const Enteredaddress = selectedOption;

      if (address === undefined) {
        toast.error("Please Connect Your Wallet");
        setTransactionSpinner(false);
        return false;
      }

      if (!Enteredaddress) {
        toast.error("Please Enter Wallet Address");
        setTransactionSpinner(false);
        return false;
      }

      try {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          polygon_flashloan_address,
          polygon_flashloan_abi,
          signer
        );

        const checkAdmin = await checkAdminRole();
        if (checkAdmin === false) {
          toast.error("You Are not a Admin User to Whitelist");
          setTransactionSpinner(false);
          return false;
        }

        const checkWhite = await checkUserRole(Enteredaddress);
        if (checkWhite === false) {
          toast.error("Account not White Listed");
          setTransactionSpinner(false);
          return false;
        } else {
          toast.loading("Please Wait While Transaction is in Progress", {
            duration: 1000,
          });

          const RemoveWhiteList = await contract.deWhitelist(Enteredaddress, {
            gasPrice: ethers.utils.parseUnits("100", "gwei"),
            gasLimit: 2100000,
          });

          const result = await RemoveWhiteList.wait();
          console.log(result, "result");

          const whiteListUser = await axios.get(`${NODE_URL}:3006/apiv4/removeWhiteListUser?walletAddress=${Enteredaddress}&chainId=${chainId}`);

          if (whiteListUser.data.status === "success") {
            toast.success("User Removed successfully");
            setTransactionSpinner(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            toast.error("Failed...Please try agin later");
            setTransactionSpinner(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      } catch (err) {
        console.error("error", err);
        toast.error("Please try agin later");
        setTransactionSpinner(false);
      }
    } catch (err) {
      console.error(err);
      toast.error("Please try agin later");
      setTransactionSpinner(false);
    }
  };

  return (
    <div className=" text-white font-poppins ">
      <div
        className="bg-cover bg-no-repeat bg-center bg-black min-h-screen"
        style={{ backgroundImage: `url(assets/bg.jpeg)` }}
      >
        <AdminHeader />
        <Toaster toastOptions={{ position: "top-center" }} />
        <div className="md:absolute md:bottom-0 md:right-0 md:w-[30rem] md:h-[30rem]  md:filter md:blur-[7rem]  md:bg-[#E43875A3]/20 md:rounded-full"></div>
        <div className="md:absolute md:top-20 md:-left-10 md:w-[25rem] md:h-[27rem]  md:filter md:blur-3xl   md:bg-[#E43875A3]/20 md:rounded-full"></div>

        <div className="container mx-auto block h-full pb-20 mt-10  ">
          <div className="flex items-center justify-center w-full h-full">
            <div className="md:pt-7 pt-10">
              <div className="p-[2px] rounded-2xl bg-[#E4387536] text-white md:mx-auto mx-5 sm:w-[25rem]">
                <div className="h-full  p-2 rounded-2xl block md:px-10 px-3 py-10 ">
                  <div className="flex w-full justify-center items-center">
                    <span className="object-contain object-center">
                      <img
                        src="assets/whitelist_head.png"
                        alt="login"
                        className="w-[4rem] height-[4rem]"
                      />
                    </span>
                  </div>
                  <h4 className="w-full text-center font-bold pt-4 text-sm mb-4">
                    Remove Users
                  </h4>
                  <div className="flex flex-col w-full text-left">
                    <label
                      htmlFor="walt-address"
                      className="my-2 text-white text-xs"
                    >
                      Wallet Address
                    </label>
                    <input
                      type="text"
                      name="walt-link"
                      id="walt-address"
                      className="mb-4 bg-[#FFFFFFD1] px-3 py-1  text-xs border-0 outline-0 rounded-md text-black "
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="mt-12 flex justify-center items-center">
                    <button
                      type="button"
                      className="bg-[#E43875A3] py-2 md:px-16 px-7 text-white font-bold text-center  rounded-lg md:text-xs text-[10px]  leading-1"
                      onClick={RemoveUser}
                    >
                      Remove Whitelisted User
                    </button>
                  </div>
                  <p className="mt-4 text-white text-[9px] w-full text-center">
                    By clicking Remove, You agree to remove Whitelist User
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {TransactionSpinner ? <TranSpinners /> : ""}
    </div>
  );
};

export default Removelist;

import React, { useEffect, useState } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import axios from "axios";
import { NODE_URL } from "./config.js";

export default function Header() {

  const { address, isConnected } = useWeb3ModalAccount();

  const [useStatus, setUserStatus] = useState(0);
  const [isDropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const checkUserRole = async () => {
    try {
      let checkUser = await axios.get(`${NODE_URL}:3006/apiv4/getWhiteListDetails?walletAddress=${address}`)
      if (checkUser.data.status === true) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handlePageChange = async (event, target) => {
    event.preventDefault();
    const checkwhitelist = await checkUserRole();
    if (checkwhitelist !== false && useStatus === 1) {
      window.location.href = target;
    }
  };

  useEffect(() => {
    const fetchStatus = async () => {
      const result = await axios.get(`${NODE_URL}:3006/apiv4/checkStatus?address=${address}`);
      setUserStatus(result.data.status);
    };

    if (isConnected === true && address !== undefined) {
      fetchStatus();
    }
  }, [address, isConnected]);


  return (
    <div>
      <div className="hidden lg:block">
        <div className="bg-[#70233f] flex justify-around px-2 py-2">
          <div className="w-[20%]">
            <img src="assets/MBC Logo_Final 1.png" alt="Logo"></img>
          </div>
          <div className="w-[50%] flex gap-20 justify-center items-center">
            <a
              className="text-white font-syne cursor-pointer"
              onClick={(e) => handlePageChange(e, "/")}
            >
              Home
            </a>
            <a
              className="text-white font-syne cursor-pointer"
              onClick={(e) => handlePageChange(e, "/Dapp")}
            >
              Dapp
            </a>
            <a
              className="text-white font-syne cursor-pointer"
              onClick={(e) => handlePageChange(e, "/learn")}
            >
              Learn
            </a>
          </div>
          <div className="w-[30%] flex items-center justify-center">
            <w3m-button />
          </div>
        </div>
      </div>
      <div className="lg:hidden block bg-[#70233f] flex justify-between items-center px-2 py-1">
        <div>
          <img src="assets/MBC Logo_Final 1.png" alt="Logo"></img>
        </div>
        <div>
          <a className="text-white font-bold text-2xl" onClick={handleDropdown}>
            <i className="fa-solid fa-bars"></i>
          </a>
        </div>
        {isDropdown && (
          <div
            className="dropdown-content z-30 w-[12rem] absolute right-5 top-[4rem] mt-2 top-20  animate__animated animate__fadeInUp "
            data-aos="fade-up"
          >
            <div
              className="flex flex-col gap-3 border-2  backdrop-blur-md text-center  rounded-lg p-3 bg-no-repeat bg-cover bg-center bg-black"
              style={{ backgroundImage: "url('/Background (4).png')" }}
            >
              <a
                className="text-white font-syne cursor-pointer"
                onClick={(e) => handlePageChange(e, "/")}
              >
                Home
              </a>
              <a
                className="text-white font-syne cursor-pointer"
                onClick={(e) => handlePageChange(e, "/dapp")}
              >
                Dapp
              </a>
              <a
                className="text-white font-syne cursor-pointer"
                onClick={(e) => handlePageChange(e, "/learn")}
              >
                Learn
              </a>

              <div className="flex items-center justify-center">
                <w3m-button />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "../App.css";
import AdminHeader from "./AdminHeader";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import DataSpinners from "../FetchDatas";
import { NODE_URL } from "../config";

const Whitelistusers = () => {

  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const [isOpen, setIsOpen] = useState(false);
  const [FetchingDatasSpinner, setFetchingDatasSpinner] = useState(false);
  const [TotalTrades, setTotalTrades] = useState("");
  const [transactions, setTransactions] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState(null);
  const [userEnteredAddress, setUserEnteredAddress] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const dateformat = async (date) => {
    const originalDate = new Date(date);
    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1;
    const year = originalDate.getUTCFullYear() % 100;
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const fetchData = async (filter) => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getAllWhitelistedUser?chainId=${chainId}`);
      const res = response.data.data;
      const filteredData = filter ? res.filter((item) => item.Whitelist_type === filter) : res;
      setTotalTrades(filteredData.length);

      const transactions = await Promise.all(
        filteredData.map(async (trans) => {
          const formattedDate = await dateformat(trans.createdAt);
          return {
            address: trans.userAddress,
            date: formattedDate,
            type: trans.Whitelist_type,
            purchase: trans.Purchases,
            TotalTradeLimit: trans.TotalTradeLimit,
          };
        })
      );
      setTransactions(userEnteredAddress ? transactions.filter((trans) => trans.address.includes(userEnteredAddress.trim())) : transactions);
      setFetchingDatasSpinner(false);
    } catch (error) {
      console.log("Error while fetching whitelisted list: ", error);
      setFetchingDatasSpinner(false);
    }
  };

  useEffect(() => {
    if (chainId !== undefined) {
      fetchData();
    }
  }, [chainId]);

  useEffect(() => {
    fetchData();
  }, [userEnteredAddress]);

  const itemsPerPage = 10;

  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalPages = Math.ceil(TotalTrades / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleFilterSelect = (selectedFilter) => {
    setIsOpen(false);
    setCurrentPage(1);
    setFilter(selectedFilter);
    fetchData(selectedFilter);
  };
  const clearFilter = () => {
    setIsOpen(false);
    setFilter(null);
  };
  const clearAddress = () => {
    setUserEnteredAddress("");
    setTransactions(transactions);
    setCurrentPage(1);
  };

  return (
    <div className="text-white font-poppins ">
      <div
        className=" bg-cover min-h-screen bg-black"
        style={{ backgroundImage: `url(assets/ bg2.png)` }}
      >
        <div className="md:absolute md:bottom-0 md:right-0 md:w-[30rem] md:h-[30rem]  md:filter md:blur-[7rem]  md:bg-[#E43875A3]/20 md:rounded-full"></div>
        <div className="md:absolute md:top-20 md:-left-10 md:w-[25rem] md:h-[27rem]  md:filter md:blur-3xl   md:bg-[#E43875A3]/20 md:rounded-full"></div>
        <div className="z-30">
          <AdminHeader />
          <div className="md:px-16 px-5 mt-10 md:mt-5 pb-20">
            <div className="flex space-x-2 items-center">
              <img className="w-5 h-5" src="assets/Sparkle.svg" />
              <h1 className="text-lg font-bold font-Artemus text-[#F98736]">
                Whitelisted Users
              </h1>
            </div>

            <div className="flex items-center mt-10  space-x-5">
              <input
                className="w-[90%] text-[10px] md:text-xs rounded-xl px-5 py-2 outline-none bg-[#D9D9D930]"
                type="text"
                placeholder="Search for the wallet Address"
                value={userEnteredAddress}
                onChange={(e) => setUserEnteredAddress(e.target.value)}
              />
              {userEnteredAddress && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className=""
                  onClick={clearAddress}
                />
              )}
              <div className="">
                <button
                  className="flex items-center md:mt-1 space-x-3"
                  onClick={toggleDropdown}
                >
                  <FontAwesomeIcon icon={faSliders} />
                  <p className="text-sm">
                    {filter === null ? "Filter" : filter}
                  </p>
                  {filter !== null && (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="cursor-pointer"
                      onClick={clearFilter}
                    />
                  )}
                </button>
                {isOpen && (
                  <div className="absolute w-44 mt-2 bg-black shadow-md rounded-md">
                    <div className="px-10 text-xs py-2 border-b border-gray-500">
                      Recently Added
                    </div>
                    <ul className="flex flex-col text-xs gap-3 p-5 px-10">
                      <button
                        className={`flex justify-center py-1 border-white border rounded-md ${filter === "Licence 1" ? "text-[#FFBA00]" : ""
                          }`}
                        onClick={() => handleFilterSelect("license1")}
                      >
                        Licence 1
                      </button>
                      <button
                        className={`flex justify-center py-1 border-white border rounded-md ${filter === "Licence 2" ? "text-[#FFBA00]" : ""
                          }`}
                        onClick={() => handleFilterSelect("license2")}
                      >
                        Licence 2
                      </button>
                      <button
                        className={`flex justify-center py-1 border-white border rounded-md ${filter === "Licence 3" ? "text-[#FFBA00]" : ""
                          }`}
                        onClick={() => handleFilterSelect("license3")}
                      >
                        Licence 3
                      </button>
                      <button
                        className={`flex justify-center py-1 border-white border rounded-md ${filter === "Licence 4" ? "text-[#FFBA00]" : ""
                          }`}
                        onClick={() => handleFilterSelect("license4")}
                      >
                        Licence 4
                      </button>
                      <button
                        className={`flex justify-center py-1 border-white border rounded-md ${filter === "Licence 5" ? "text-[#FFBA00]" : ""
                          }`}
                        onClick={() => handleFilterSelect("license5")}
                      >
                        Licence 5
                      </button>
                    </ul>
                  </div>
                )}
              </div>
              {/* <button className="bg-[#E43875A3] md:px-8 px-4 py-2 text-xs font-semibold rounded-xl "
                >
                Search
              </button> */}
            </div>

            <div className="p-[2px] rounded-2xl bg-[#E4387536] mt-5">
              <div className="pt-5 py-3 overflow-hidden overflow-x-scroll h-full p-2 rounded-2xl block">
                <table className="table-auto w-full">
                  <thead>
                    <tr className="text-base font-bold text-center">
                      <th className="px-5 pb-5">S.No</th>
                      <th className="px-5 pb-5">Wallet Address</th>
                      <th className="px-5 pb-5">Date</th>
                      <th className="px-5 pb-5">Whitelist Type</th>
                      <th className="px-5 pb-5">Trade Limit</th>
                      <th className="px-5 pb-5">Purchases</th>
                    </tr>
                  </thead>
                  <tbody className="text-xs text-center">
                    {currentTransactions && currentTransactions.length > 0 ? (
                      currentTransactions.map((trans, index) => (
                        <tr key={index}>
                          <td className="px-5 py-5">
                            {indexOfFirstTransaction + index + 1}
                          </td>
                          <td className="px-5 py-5 font-bold">
                            {trans.address}
                          </td>
                          <td className="px-5 py-5">{trans.date}</td>
                          <td className="px-5 py-5">{trans.type}</td>
                          <td className="px-5 py-5">{trans.TotalTradeLimit}</td>

                          <td className="px-5 py-5" title={trans.purchase}>
                            {trans.purchase.length > 10
                              ? `${trans.purchase.slice(0, 10)}...`
                              : trans.purchase}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center py-5">
                          No transactions available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="flex justify-between mt-4 px-10 py-2 text-xs">
                  <button
                    className="mr-20 hover:bg-[#E43875A3] rounded-lg text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875A3]"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous Page
                  </button>
                  <div className="flex items-center">
                    <p>{`Page ${currentPage} of ${totalPages}`}</p>
                  </div>
                  <button
                    className="ml-20 hover:bg-[#E43875A3] rounded-lg text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875A3]"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next Page
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {FetchingDatasSpinner ? <DataSpinners /> : ""}
    </div>
  );
};

export default Whitelistusers;

import React, { useEffect, useState } from "react";
import "../App.css";
import Header from "./Header";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

import { Wallet, ethers } from "ethers";
import BatchABI from "../UserWhiteListed.json";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import {
  polygon_flashloan_address,
  polygon_whitelist_address,
} from "../Network_Address.js";

import {
  polygon_flashloan_abi,
  polygon_whitelist_abi,token_abi
} from "../Network_Abi.js";

const Subscription = () => {
  const [Amount, setAmount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("USDT");
  const [licenseType, setLicenseType] = useState();
  const [FinalPrice, setFinalPrice] = useState(0.0);
  const [USDPrice, setUSDPrice] = useState(0.0);
  const [CryptoPrice, setCryptoPrice] = useState(0.0);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  // console.log(address, chainId, isConnected);

  const currencyOptions = [
    {
      label: "USDT",
      symbol: "USDT",
      Address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimals: 6,
    },
  ];

  const licenseOptions = [
    { type: 1, value: 1, label: "Silver" },
    { type: 2, value: 10, label: "Gold" },
    { type: 3, value: 100, label: "Diamond" },
    { type: 4, value: 1000, label: "Crown" },
    { type: 5, value: 10000, label: "Ace" },
  ];

  const handleSelectChange = (e) => {
    const selectedValue = parseInt(e.target.value);
    const selectedLicense = licenseOptions.find(
      (option) => option.value === selectedValue
    );

    if (selectedLicense) {
      console.log(selectedLicense.type);
      setAmount(selectedValue);
      setSelectedOption(selectedValue);
      setLicenseType(selectedLicense.type);
    } else {
      console.error("Selected license not found.");
    }
  };

  const handleCurrencyChange = (event) => {
    console.log(event.target.value);
    setSelectedCurrency(event.target.value);

    const selectedOptionDetails = currencyOptions.find(
      (option) => option.label === event.target.value
    );
    if (selectedOptionDetails) {
      setSelectedCurrency(selectedOptionDetails);
    } else {
      console.log("Selected option not found");
    }
    // handleChange(event.target.value);
  };

  const handleChange = async () => {
    try {
      if (selectedCurrency) {
        const symbol = selectedCurrency.symbol;
        const result = await axios.get(
          `https://min-api.cryptocompare.com/data/price?api_key=afb9c2ae7b1584cd4fdf5185d22982633f6ac3d5f3cdf109debfe8e307d2b940&fsym=${
            symbol === "WMATIC" ? "MATIC" : symbol
          }&tsyms=USD`
        );

        if (result.data.USD) {
          const rem = result.data.USD;
          setCryptoPrice(rem);
          setUSDPrice(1 / rem);
          const price = Amount * rem;
          console.log("usd price:", price);
          setFinalPrice(price.toFixed(4));
        }
      } else {
        toast.error("Please choose Payment token");
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if(selectedCurrency){
      handleChange()
    }
  }, [selectedCurrency,selectedOption]);


  const checkUserRole = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      let contract = new ethers.Contract(
        polygon_whitelist_address,
        polygon_whitelist_abi,
        provider
      );
      let data = await contract.checkUserRole(address);
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const IntiatePayment = async () => {
    try {
      if (!selectedCurrency) {
        toast.error("Please choose Payment Method");
        return false;
      }

      if (!FinalPrice || FinalPrice === 0) {
        toast.error("Something went wrong Please try again Later");
        return false;
      }

      if (address === undefined) {
        toast.error("Please Connect Your Wallet");
        return false;
      }
      const walletAddress = address;
      const paymentMode = selectedCurrency.symbol === "MATIC" ? 0 : 1;
      const tokenAddress = selectedCurrency.Address;
      const checkWhitelisted = await checkUserRole();
      console.log(
        tokenAddress,
        licenseType,
        paymentMode,
        FinalPrice,
        selectedCurrency.decimals
      );
      if (checkWhitelisted === false) {
        const newWhiteList = await getWhitelisted(
          tokenAddress,
          licenseType,
          paymentMode,
          FinalPrice,
          selectedCurrency.decimals
        );

        if (newWhiteList.status === "success") {
          const whiteListUser = await axios.get(
            `http://localhost:3006/apiv4/WhiteListUser?walletAddress=${walletAddress}&plan=${
              licenseType == 1
                ? "license1"
                : licenseType == 2
                ? "license2"
                : licenseType == 3
                ? "license3"
                : licenseType == 4
                ? "license4"
                : "license5"
            }`
          );
          console.log("New transaction added with key:", whiteListUser);
          if (whiteListUser.data.status == "success") {
            const dataParams = {
              walletAddress: walletAddress,
              plan:
                licenseType == 1
                  ? "license1"
                  : licenseType == 2
                  ? "license2"
                  : licenseType == 3
                  ? "license3"
                  : licenseType == 4
                  ? "license4"
                  : "license5",
              trxHash: newWhiteList.hash,
              PaymentAmount: FinalPrice,
              PaymentMethod: selectedCurrency.symbol,
            };
            console.log(dataParams);
            const savePurchases = await axios.post(
              `http://localhost:3006/apiv4/PurchasesOfUser`,
              dataParams
            );
            if (savePurchases.data.status == "success") {
              toast.success("Your BetaPlan Subscribed Successfully..");
              // setTimeout(() => {
              //   window.location.reload()
              // }, 1500)
            } else {
              toast.error("Something went wrong Please try again Later");
              // setTimeout(() => {
              //   window.location.reload()
              // }, 500)
              return false;
            }
          } else {
            toast.error("Something went wrong Please try again Later");
            setTimeout(() => {
              window.location.reload();
            }, 500);
            return;
          }
        } else {
          toast.error("Something went wrong Please try again Later");
          // setTimeout(() => {
          //   window.location.reload()
          // }, 500)
          // return
        }
      } else {
        const upgradeWhiteList = await upgradeUserWhitelisted(
          tokenAddress,
          licenseType,
          paymentMode,
          FinalPrice,
          selectedCurrency.decimals
        );

        if (upgradeWhiteList.status === "success") {
          const upgradewhiteListUser = await axios.get(
            `http://localhost:3006/apiv4/WhiteListUser?walletAddress=${walletAddress}&plan=${
              licenseType === 1
                ? "license1"
                : licenseType === 2
                ? "license2"
                : licenseType === 3
                ? "license3"
                : licenseType === 4
                ? "license4"
                : "license5"
            }`
          );
          console.log("New transaction added with key:", upgradewhiteListUser);
          if (upgradewhiteListUser.data.status === "success") {
            const dataParams = {
              walletAddress: walletAddress,
              plan:
                licenseType === 1
                  ? "license1"
                  : licenseType === 2
                  ? "license2"
                  : licenseType === 3
                  ? "license3"
                  : licenseType === 4
                  ? "license4"
                  : "license5",
              trxHash: upgradeWhiteList.hash,
              PaymentAmount: FinalPrice,
              PaymentMethod: selectedCurrency.symbol,
            };
            // const savePurchasesUpgrade = await axios.post(`http://localhost:3006/apiv4/PurchasesOfUser`, dataParams);
            // if (savePurchasesUpgrade.data.status == 'success') {
            //   toast.success('Your BetaPlan Subscribed Successfully..');
            //   setTimeout(() => {
            //     window.location.reload()
            //   }, 1500)
            // } else {
            //   toast.error('Something went wrong Please try again Later');
            //   return false
            // }
          } else {
            toast.error("Something went wrong upgradewhiteListUser ");
            return false;
          }
        } else {
          toast.error("Please try again Later");
          // setTimeout(() => {
          //   window.location.reload()
          // }, 500)
          return;
        }
      }
    } catch (err) {
      toast.error("catch error");
      // setTimeout(() => {
      //   window.location.reload()
      // }, 1500)
      //return
    }
  };

  async function generateWhitelistSign(
    account,
    contractAddress,
    tokenAddress,
    amount,
    plan
  ) {
    let nonce = await getRandomNonce();
    const provider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await provider.getSigner()
    // let sign_wallet = new Wallet(PRIVATE_KEY, provider);

    let _signMessage = ethers.utils.solidityKeccak256(
      ["address", "address", "address", "uint256", "uint256", "uint256"],
      [contractAddress, account, tokenAddress, plan, amount, nonce]
    );

    _signMessage = ethers.utils.arrayify(_signMessage);
    let signature = await signer.signMessage(_signMessage);
    signature = ethers.utils.splitSignature(signature);
    signature = [signature.v, signature.r, signature.s, nonce];
    console.log(signature);
    return signature;
  }

  async function getRandomNonce() {
    return Math.floor(new Date().getTime() / 1000);
  }

  async function estimateGasPrice() {
    try {
      const Auth = btoa(
        "d27832daf2d945868f1c7af308de068d" +
          ":" +
          "WCo39w9WCDQ6Y7lBlKvZDjpBJNAx1iuAJ0NmF0qOlSTvL/jrrzGyUQ"
      );

      const { data } = await axios.get(
        `https://gas.api.infura.io/networks/137/suggestedGasFees`,
        {
          headers: {
            Authorization: `Basic ${Auth}`,
          },
        }
      );
      //console.log("Suggested gas fees:", data.high.suggestedMaxFeePerGas);
      return data.high.suggestedMaxFeePerGas;
    } catch (error) {
      //console.log("Server responded with:", error);
    }
  }

  async function upgradeUserWhitelisted(
    tokenAddress,
    plan,
    paymentMode,
    amount,
    decimals
  ) {
    try {
      console.log(tokenAddress, "tokenaddress");

      const amountInWei = ethers.BigNumber.from(
        ethers.utils.parseUnits(amount.toString(), decimals)
      );
      console.log(amountInWei.toString(), "amountInWei");

      let signature = await generateWhitelistSign(
        address,
        polygon_whitelist_address,
        tokenAddress,
        amountInWei,
        plan
      );

      if (amount < 0 || parseFloat(amount) < 0) {
        console.log("coming here");
        return { status: "failed" };
      }
      console.log(amount, amount < 480, parseFloat(480));

      if (
        tokenAddress === "0x0000000000000000000000000000000000001010" ||
        tokenAddress === "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
      ) {
        let maxAmount;
        switch (plan) {
          case 1:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          case 2:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          case 3:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          case 4:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          case 5:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          default:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
        }
        console.log(parseFloat(amountInWei), parseFloat(maxAmount), "upgarde");

        if (
          amountInWei < maxAmount ||
          parseFloat(amountInWei) < parseFloat(maxAmount)
        ) {
          console.log("coming inside error");
          return { status: "failed" };
        }
      } else {
        let maxAmountUSDT;
        switch (plan) {
          case 1:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 2:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 3:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 4:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 5:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;

          default:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
        }
        console.log(parseFloat(amountInWei), parseFloat(maxAmountUSDT));

        // if (amountInWei < maxAmountUSDT || parseFloat(amountInWei) < parseFloat(maxAmountUSDT)) {
        //   return { status: "failed" };
        // }
      }

      const provider = new ethers.providers.Web3Provider(walletProvider);
      const Usersigner = provider.getSigner();
      const TokenContract = new ethers.Contract(
        tokenAddress,
        token_abi,
        Usersigner
      );

      //console.log(TokenContract,'contractttt')
      // const Balance = await TokenContract.balanceOf(accounts);
      // //console.log(Balance.toNumber()/10 ** decimals,'balance')
      // if(Balance.toNumber()/10 ** decimals < amount){
      //   return { status: 'failed',balance:false };
      // }
      let contract = new ethers.Contract(
        polygon_whitelist_address,
        BatchABI,
        Usersigner
      );

      let gasPrice = await estimateGasPrice();
      if (paymentMode === 0) {
        let tx = await contract.upgradeLimit(
          paymentMode,
          plan,
          tokenAddress,
          amountInWei,
          signature,
          {
            gasPrice: ethers.utils.parseUnits(
              (Number(gasPrice) * 2).toString(),
              "gwei"
            ),
            gasLimit: ethers.BigNumber.from(500000),
            value: amountInWei,
          }
        );
        tx = await tx.wait();
        return { status: "success", hash: tx.transactionHash };
      }

      if (paymentMode === 1) {
        let Approve = await TokenContract.approve(
          polygon_whitelist_address,
          amountInWei,
          {
            gasPrice: ethers.utils.parseUnits(
              (Number(gasPrice) * 2).toString(),
              "gwei"
            ),
            gasLimit: ethers.BigNumber.from(500000),
          }
        );
        Approve = await Approve.wait();
        if (!Approve) {
          return { status: "failed" };
        }
        let tx = await contract.upgradeLimit(
          paymentMode,
          plan,
          tokenAddress,
          amountInWei,
          signature,
          {
            gasPrice: ethers.utils.parseUnits(
              (Number(gasPrice) * 2).toString(),
              "gwei"
            ),
            gasLimit: ethers.BigNumber.from(500000),
          }
        );
        tx = await tx.wait();
        return { status: "success", hash: tx.transactionHash };
      }
    } catch (err) {
      console.log(err);
      return { status: "failed" };
    }
  }

  async function getWhitelisted(
    tokenAddress,
    plan,
    paymentMode,
    amount,
    decimals
  ) {
    try {
      const amountInWei = ethers.BigNumber.from(
        ethers.utils.parseUnits(amount.toString(), decimals)
      );

      let signature = await generateWhitelistSign(
        address,
        polygon_whitelist_address,
        tokenAddress,
        amountInWei,
        plan
      );
      console.log(amountInWei, "amountinWei");

      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();
      const TokenContract = new ethers.Contract(tokenAddress, token_abi, signer);

      let contract = new ethers.Contract(
        polygon_whitelist_address,
        BatchABI,
        signer
      );

      if (amount < 0 || parseFloat(amount) < 0) {
        console.log("coming here");
        return { status: "failed" };
      }
      console.log(amount, amount < 480, parseFloat(480));

      if (
        tokenAddress === "0x0000000000000000000000000000000000001010" ||
        tokenAddress === "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
      ) {
        let maxAmount;
        switch (plan) {
          case 1:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          case 2:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          case 3:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
          case 4:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;

          case 5:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;

          default:
            maxAmount = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 18)
            );
            break;
        }
        console.log(
          amountInWei._hex,
          "amountingwei",
          maxAmount._hex,
          "amount USDT",
          amount < maxAmount,
          "greater than"
        );

        if (
          amountInWei < maxAmount ||
          parseFloat(amountInWei) < parseFloat(maxAmount)
        ) {
          return { status: "failed" };
        }
      } else {
        let maxAmountUSDT;
        switch (plan) {
          case 1:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 2:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 3:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 4:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
          case 5:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;

          default:
            maxAmountUSDT = ethers.BigNumber.from(
              ethers.utils.parseUnits("1".toString(), 6)
            );
            break;
        }

        console.log(
          amountInWei,
          "amountingwei",
          maxAmountUSDT,
          "amount USDT",
          amountInWei < maxAmountUSDT,
          "greater than"
        );
        if (
          amountInWei < maxAmountUSDT ||
          parseFloat(amountInWei) < parseFloat(maxAmountUSDT)
        ) {
          return { status: "failed" };
        }
      }

      let gasPrice = await estimateGasPrice();
      if (paymentMode === 0) {
        let tx = await contract.getWhitelisted(
          paymentMode,
          plan,
          tokenAddress,
          amountInWei,
          signature,
          {
            gasPrice: ethers.utils.parseUnits(
              (Number(gasPrice) * 2).toString(),
              "gwei"
            ),
            gasLimit: ethers.BigNumber.from(500000),
            value: amountInWei,
          }
        );
        tx = await tx.wait();
        return { status: "success", hash: tx.transactionHash };
      }

      if (paymentMode === 1) {
        let Approve = await TokenContract.approve(
          polygon_whitelist_address,
          amountInWei,
          {
            gasPrice: ethers.utils.parseUnits(
              (Number(gasPrice) * 2).toString(),
              "gwei"
            ),
            gasLimit: ethers.BigNumber.from(500000),
          }
        );
        Approve = await Approve.wait();
        if (!Approve) {
          return { status: "failed" };
        }
        //console.log(Approve,'approveee')
        let tx = await contract.getWhitelisted(
          paymentMode,
          plan,
          tokenAddress,
          amountInWei,
          signature,
          {
            gasPrice: ethers.utils.parseUnits(
              (Number(gasPrice) * 2).toString(),
              "gwei"
            ),
            gasLimit: ethers.BigNumber.from(500000),
          }
        );
        tx = await tx.wait();
        return { status: "success", hash: tx.transactionHash };
      }
    } catch (err) {
      console.log(err);
      return { status: "failed" };
    }
  }

  return (
    <div className=" text-white font-inter">
      <div
        className="bg-cover bg-black min-h-screen"
        style={{ backgroundImage: `url(assetsgpt/bg3.png)` }}
      >
        <Header />
        <Toaster
          toastOptions={{
            position: "top-center",
          }}
        />
        <div className="md:px-20 mt-5 lg:flex gap-14 pb-20">
          <div className="lg:w-[65%] px-10 md:px-0 ">
            <div className="md:flex md:space-x-5 space-y-5 md:space-y-0">
              <div className="md:basis-1/3 p-[3px] rounded-2xl bg-gradient-to-tr from-[#AEAEAE2B] via-[#AEAEAE2B] to-[#AEAEAE]">
                <div className="pt-5 pb-3 rounded-2xl bg-black/80">
                  <div className="flex flex-col items-center text-center">
                    <p
                      className="font-bold text-xl"
                      style={{
                        background: "-webkit-linear-gradient(#AEAEAE, white)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Silver
                    </p>

                    <img src="assets/sym4.png" className="w-[25%] mt-3" />
                    <p className="text-2xl font-bold my-3">
                      <sup
                        className="inline-block"
                        style={{ fontSize: "0.6em" }}
                      >
                        $
                      </sup>
                      500
                    </p>

                    <hr className="border-1 px-20"></hr>
                    <p className=" text-[12px] mt-3">Trading limit 2000$</p>
                  </div>
                </div>
              </div>
              <div className="md:basis-1/3 p-[3px] rounded-2xl bg-gradient-to-tr from-[#FFBA0024] from-75% to-[#FFBA00]">
                <div className="  pt-5 pb-3 rounded-2xl bg-gradient-to-tr from-black/75  to-black">
                  <div className="flex flex-col items-center text-center">
                    <p
                      className="font-bold text-xl"
                      style={{
                        background: "-webkit-linear-gradient(#FFBA00, white)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Gold
                    </p>

                    <img src="assets/sym9.png" className="w-[33%] mt-3" />
                    <p className="text-2xl font-bold my-3">
                      <sup
                        className="inline-block"
                        style={{ fontSize: "0.6em" }}
                      >
                        $
                      </sup>
                      1000
                    </p>
                    <hr className="border-1 px-20"></hr>
                    <p className=" text-[12px] mt-3">Trading limit 6000$</p>
                  </div>
                </div>
              </div>
              <div className=" md:basis-1/3  p-[3px] rounded-2xl  bg-gradient-to-tr from-[#8E079A54] from-75% to-[#97079A] ">
                <div className="pt-5 pb-3 rounded-2xl bg-gradient-to-tr from-black/75  to-black">
                  <div className="flex flex-col items-center text-center">
                    <p
                      className="font-bold text-xl"
                      style={{
                        background: "-webkit-linear-gradient(#8E079A, white)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Diamond
                    </p>

                    <img src="assets/sym5.png" className="w-[41%] mt-3" />
                    <p className="text-2xl font-bold my-3">
                      <sup
                        className="inline-block"
                        style={{ fontSize: "0.6em" }}
                      >
                        $
                      </sup>
                      1500
                    </p>
                    <hr className="border-1 px-20"></hr>
                    <p className=" text-[12px] mt-3">Trading limit 4000$</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:flex mt-5 md:space-x-5 space-y-5 md:space-y-0 justify-center">
              <div className=" md:basis-1/3  p-[3px] rounded-2xl  bg-gradient-to-tr from-[#1EEF3230] from-75% to-[#1EEF32] ">
                <div className="pt-5 pb-3 rounded-2xl bg-gradient-to-tr from-black/75  to-black">
                  <div className="flex flex-col items-center text-center">
                    <p
                      className="font-bold text-xl"
                      style={{
                        background: "-webkit-linear-gradient(#AEAEAE, white)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Crown
                    </p>

                    <img src="assets/sym1.png" className="w-[30%] mt-3" />
                    <p className="text-2xl font-bold my-3">
                      <sup
                        className="inline-block"
                        style={{ fontSize: "0.6em" }}
                      >
                        $
                      </sup>
                      2000
                    </p>
                    <hr className="border-1 px-20"></hr>
                    <p className=" text-[12px] mt-3">Trading limit 2000$</p>
                  </div>
                </div>
              </div>
              <div className=" md:basis-1/3  p-[3px] rounded-2xl  bg-gradient-to-tr from-[#6C89D354] from-75% to-[#6C89D3] ">
                <div className="pt-5 pb-3 rounded-2xl bg-gradient-to-tr from-black/75  to-black">
                  <div className="flex flex-col items-center text-center">
                    <p
                      className="font-bold text-xl"
                      style={{
                        background: "-webkit-linear-gradient(#FFBA00, white)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}
                    >
                      Ace
                    </p>

                    <img src="assets/sym6.png" className="w-[25%] mt-3" />

                    <p className="text-2xl font-bold my-3">
                      <sup
                        className="inline-block"
                        style={{ fontSize: "0.6em" }}
                      >
                        $
                      </sup>
                      2500
                    </p>
                    <hr className="border-1 px-20"></hr>
                    <p className=" text-[12px] mt-3">Trading limit 6000$</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[35%]  px-5 md:px-0  mt-10 lg:mt-0 font-inter">
            <div className="bg-[#E438752E] md:px-10 px-5 py-5 rounded-3xl">
              <p className="font-extrabold text-xl underline decoration-1 underline-offset-8 flex items-center justify-center">
                Subscription
              </p>
              <div className="mt-10 ">
                <p className="text-xs">Choose Plan</p>
                <select
                  className=" bg-[#F98736] text-sm font-bold border-solid mt-2 py-1 px-3 rounded-md w-full focus:outline-none"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option
                    className="bg-black text-white flex justify-center items-center"
                    value=""
                  >
                    Select Plan
                  </option>
                  {licenseOptions.map((option, index) => (
                    <option
                      key={index}
                      value={option.value}
                      className="bg-black text-white flex justify-center items-center"
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-5">
                <p className="flex justify-center items-center underline decoration-1 underline-offset-8 font-bold">
                  Payment Method
                </p>
                <div className="flex justify-between mt-7 gap-4">
                  <div>
                    <p className="text-xs font-bold">Currency</p>
                    <select
                      name="currency"
                      id="currency-dropdown"
                      className="w-32 md:w-48 lg:w-56 xl:w-32 focus:outline-none mt-1 bg-transparent border border-1 border-white/30 rounded-md py-1 px-3 text-center text-xs font-bold"
                      value={selectedCurrency.label}
                      onChange={handleCurrencyChange}
                      disabled={selectedOption === ""}
                    >
                      <option
                        className="bg-transparent flex flex-col justify-center item-center focus:outline-none text-black text-left border-solid mt-2 rounded-md w-full text-xs font-bold"
                        value=""
                      >
                        Select
                      </option>
                      {currencyOptions.map((option, index) => (
                        <option
                          className="bg-black text-white flex flex-col justify-center item-center focus:outline-none text-left border-solid mt-2 rounded-md w-full text-xs font-bold"
                          key={index}
                          value={option.label}
                        >
                          {option.symbol}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <p className="text-xs font-bold">Amount</p>
                    <input
                      type="text"
                      className="w-full xl:w-32 mt-1 bg-transparent focus:outline-none text-xs font-bold border border-white/30 rounded-md py-1 px-6 text-center"
                      readOnly
                      value={Amount + " USD"}
                    />
                  </div>
                </div>
                {selectedOption === "" && (
                  <p className="text-red-500 text-xs mt-1">
                    Please select a plan.
                  </p>
                )}

                <div className="mt-7 text-[11px] font-bold space-y-5">
                  <p className="flex justify-between">
                    Amount{" "}
                    <span>
                      {Amount} USD * {CryptoPrice} = {FinalPrice}
                    </span>
                  </p>
                  <div className="flex justify-between">
                    <p>Rate</p>
                    <div className="flex flex-col items-end">
                      <p>
                        1 {selectedCurrency.label} = ${CryptoPrice.toFixed(4)}
                      </p>
                      <p className="text-[#DED9E880] mt-1">
                        1 USD = $ {USDPrice.toFixed(4)} {selectedCurrency.label}
                      </p>
                    </div>
                  </div>
                  <p className="flex justify-between">
                    Total payment{" "}
                    <span>
                      {FinalPrice} {selectedCurrency.label}
                    </span>
                  </p>
                </div>
                <div className="flex justify-center mt-7 mb-3">
                  <button
                    className="p-1 bg-[#F98736] text-black w-full py-1.5  rounded-md text-sm font-bold"
                    onClick={IntiatePayment}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;

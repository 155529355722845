import React, { useState, useEffect } from "react";
import "../App.css";
import Header from "./Header";
import axios from "axios";
import { ImageComponent } from "../Img";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { NODE_URL } from "../config";

export const Trades = () => {
  
  const [TotalTrades, setTotalTrades] = useState("");
  const [transactions, setTransactions] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { address, chainId, isConnected } = useWeb3ModalAccount();

  const dateformat = async (date) => {
    const originalDate = new Date(date);
    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1;
    const year = originalDate.getUTCFullYear() % 100;
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersTransactions?walletAddress=${address}`);
      const res = response.data.data;
      const transactions = await Promise.all(
        res.map(async (trans) => {
          const formattedDate = await dateformat(trans.createdAt);
          return {
            trxHash: trans.trxHash,
            date: formattedDate,
            status: trans.status,
            token: trans.pair1,
            profit: Number(trans.profitPercentage),
          };
        })
      );
      setTransactions(transactions);
      setTotalTrades(transactions.length);
    } catch (error) {
      console.log("Error while fetching: ", error);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      fetchData();
    }
  }, [address]);

  const itemsPerPage = 10;
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );

  const totalPages = Math.ceil(TotalTrades / itemsPerPage);

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  return (
    <div className=" text-white font-poppins ">
      <div
        className="w-full h-full"
        style={{ backgroundImage: `url(assets/image94.png)` }}
      >
        <Header />
        <div className="md:px-5 px-7 mt-10 md:mt-5 pb-20 min-h-screen">
          <div className="z-0 md:absolute md:top-14 md:-left-48 md:w-[30rem] md:h-[35rem]  md:filter md:blur-3xl  md:bg-[#E43875]/10 md:rounded-full"></div>
          <div className="z-0 md:absolute md:bottom-24 md:right-0 md:w-[24rem] md:h-[24rem]  md:filter md:blur-3xl  md:bg-[#E43875]/10 md:rounded-full"></div>
          <div className="w-full h-full block mt-5 z-10">
            <div className="md:mx-10 ">
              <div className="w-full  p-[2px] rounded-2xl bg-[#E4387526] text-white overflow-x-scroll lg:overflow-hidden">
                <div className="h-full py-3 p-2 rounded-2xl block overflow-x-scroll lg:overflow-hidden">
                  <table className="border-none w-full h-auto overflow-x-scroll lg:overflow-hidden">
                    <thead className="py-4">
                      <tr>
                        <th className="mx-2 text-white font-bold text-lg lg:text-2xl flex items-center gap-2">
                          <img
                            src="./assetsgpt/Vector.png"
                            className="w-5 h-5"
                            alt="taborr logo"
                          />
                          <p className="lg:text-base sm:text-sm text-xs font-Artemus">
                            Transaction Hash
                          </p>
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Date
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Token
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Result
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-xs text-center ">
                      {currentTransactions && currentTransactions.length > 0 ? (
                        currentTransactions.map((trans) => (
                          <tr key={trans.trxHash}>
                            <td className="font-bold px-10 py-3 text-sm ">
                              <a
                                href={`https://polygonscan.com/tx/${trans.trxHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-[#1EEF32] hover:underline truncate"
                              >
                                {trans.trxHash}
                              </a>
                            </td>
                            <td className="font-bold px-4 py-2 text-sm">
                              {trans.date}
                            </td>
                            <td className="font-bold px-4 py-2 text-sm flex items-center justify-center">
                              <ImageComponent
                                symbol={trans.token ? trans.token : "USDC"}
                              />{" "}
                              &nbsp;
                              <p>{trans.token ? trans.token : "USDC"}</p>
                            </td>

                            <td className="font-bold text-xs">
                              <span
                                style={{
                                  color: trans.profit < 0 ? "red" : "#1EEF32",
                                }}
                              >
                                <p className="text-center py-2 px-2">
                                  {trans.profit.toFixed(3)}%
                                </p>
                              </span>
                            </td>

                            <td
                              className="font-bold px-4 py-2 text-sm text-center"
                              style={{
                                color:
                                  trans.status === "success"
                                    ? "#1EEF32"
                                    : "red",
                              }}
                            >
                              <p className="text-center text-xs">
                                {trans.status === "success"
                                  ? "Profitable Trade"
                                  : "Loss Blocked"}
                              </p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5 ">No transactions available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="flex justify-between mt-4 px-10 pt-3 text-xs">
                    <button
                      className="mr-20 bg-[#E43875] z-50 px-6 py-2 rounded-lg mt-2 cursor-pointer font-semibold"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Prev
                    </button>
                    <div className="flex items-center">
                      <p>{`Page ${currentPage} of ${totalPages}`}</p>
                    </div>
                    <button
                      className="ml-20 bg-[#E43875] z-50 px-6 py-2 rounded-lg mt-2 cursor-pointer font-semibold"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

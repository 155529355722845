import React from "react";
import { Routes, Route } from "react-router-dom";
import Dapp from "./User_Panel/Dapp";

// import Marketopp from './User_Panel/Marketopp';
import Subscription from "./User_Panel/Subscription";
import { Trades } from "./User_Panel/Trades";
import TradingWallet from "./User_Panel/TradingWallet.js";

import Admin from "./Admin_Panel/Admin";
// import Addfund from './Admin_Panel/Addfund'
import Users from "./Admin_Panel/Users";
import Loginpage from "./Admin_Panel/Loginpage";
import Purchasehistory from "./Admin_Panel/Purchasehistory";
import Removelist from "./Admin_Panel/Removelist";
import Whitelist from "./Admin_Panel/Whitelist";
import Whitelistusers from "./Admin_Panel/Whitelistusers";
// import Withdraw from './Admin_Panel/Withdraw';
import Ai from "./User_Panel/Ai";
import Pair from "./User_Panel/Pair";
import PlatformStats from "./Admin_Panel/PlatformStats";
import UserProfile from "./User_Panel/Profile";
import PreviousQuery from "./Admin_Panel/PreviousQuery.js";
import Mbc from "./Mbc.js";
import Learn from "./User_Panel/Learn.js";

import RaiseQuery from "./User_Panel/RaiseQuery.js";


function App() {
  return (
    <div>
      {/* sudo chown -R $(whoami) /home/dell/Videos/arbi_ai/client */}

      <Routes>
        <Route path="/" element={<Mbc />} />
        <Route path="/Dapp" element={<Dapp />} />
        <Route path="/Ai" element={<Ai />} /> ....................
        <Route path="/Pair" element={<Pair />} /> ....................
        <Route path="/Learn" element={<Learn />} />
        <Route path="/RaiseQuery" element={<RaiseQuery />} />
        {/* <Route path="/Marketopp" element={<Marketopp />} /> ............. */}
        <Route path="/Subscription" element={<Subscription />} /> ..............
        <Route path="/Trades" element={<Trades />} /> ................
        <Route path="/wallet" element={<TradingWallet />} />
        <Route path="/Dapp" element={<Dapp />} />
        <Route path="/Platformstats" element={<PlatformStats />} />
        <Route path="/profile" element={<UserProfile />} /> ................
        <Route path="/Purchasehistory" element={<Purchasehistory />} />{" "}
        <Route path="/Query" element={<PreviousQuery />} />
        <Route path="/Users" element={<Users />} /> .................
        <Route path="/Admin" element={<Admin />} /> ...................
        {/* <Route path="/Addfund" element={<Addfund />} />  .................. */}
        <Route path="/Login" element={<Loginpage />} />
        <Route path="/Removelist" element={<Removelist />} /> ................
        <Route path="/Whitelist" element={<Whitelist />} />
        <Route path="/Whitelistusers" element={<Whitelistusers />} />{" "}
        ................
        {/* <Route path="/Withdraw" element={<Withdraw />} /> */}
      </Routes>
    </div>
  );
}

export default App;

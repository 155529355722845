import React from "react";
import Header from "./Header";
const Learn = () => {
  return (
    <div className="min-h-screen">
      <div
        className="min-h-screen bg-no-repeat bg-cover bg-center bg-black"
        style={{ backgroundImage: "url('assets/Background (4).png')" }}
      >
        <Header />
        <div className=" w-full">
          <div className="modal-container w-full flex flex-col justify-center items-center pt-10">
            <div>
              <p className="text-white text-center font-syne text-2xl font-bold">
                Understanding Flash Loans in DeFi:
              </p>
              <p className="text-white text-center text-sm">
                {" "}
                Theoretical Documentation on Blockchain Technology
              </p>
            </div>

            <div className="flex flex-col text-white  gap-5 font-syne mt-10 w-[75%] mx-auto">
              <div>
                <p className="font-bold text-xl">
                  1. Introduction to Blockchain
                </p>
                <p className="w-[90%] px-5 my-5">
                  {" "}
                  Blockchain is a decentralized, distributed ledger technology
                  that records transactions across many computers in such a way
                  that the registered transactions cannot be altered
                  retroactively. This ensures the security and transparency of
                  data.
                </p>
              </div>

              <div>
                <p className="font-bold px-5">Core Characteristics:</p>
                <ul className="w-[90%] p-5">
                  <li>
                    Decentralization: No central authority controls the data.
                  </li>
                  <li>
                    Immutability: Once data is recorded, it cannot be changed or
                    deleted.
                  </li>
                  <li>
                    Transparency: All transactions are visible to participants.
                  </li>
                  <li>
                    Security: Cryptographic techniques ensure data integrity and
                    security.
                  </li>
                </ul>
              </div>

              <div>
                <p className="font-bold text-xl">2. Types of Blockchains</p>
                <div className="p-5">
                  <p className="font-bold">1. Public Blockchain:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Open to everyone, anyone can join and
                      participate.
                    </li>
                    <li> - Examples: Bitcoin, Ethereum.</li>
                    <li>
                      - Use Cases: Cryptocurrencies, public records, supply
                      chain tracking.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">2. Private Blockchain:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Restricted access, only authorized
                      participants can join.
                    </li>
                    <li> - Examples: Hyperledger, Corda.</li>
                    <li>
                      - Use Cases: Enterprise solutions, confidential data
                      management, internal voting systems.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">3. Consortium Blockchain:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Controlled by a group of organizations,
                      rather than a single entity.
                    </li>
                    <li> - Examples: Quorum, Energy Web Foundation.</li>
                    <li>
                      - Use Cases: Banking and finance, cross-organization
                      operations, consortium-driven projects.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">4. Hybrid Blockchain:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Combines elements of both public and
                      private blockchains
                    </li>
                    <li> - Examples: Dragonchain, XinFin.</li>
                    <li>
                      - Use Cases: Supply chain management, asset management,
                      regulatory compliance.
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <p className="font-bold text-xl">3. Use Cases of Blockchain</p>

                <div className="p-5">
                  <p className="font-bold">1. Cryptocurrencies:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Digital currencies that use blockchain
                      technology for secure transactions.
                    </li>
                    <li> - Examples: Bitcoin, Ethereum, Litecoin.</li>
                    <li>
                      - Functionalities: Peer-to-peer transactions,
                      decentralized finance (DeFi) applications, smart
                      contracts.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">2. Supply Chain Management:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Blockchain ensures transparency and
                      traceability in the supply chain.
                    </li>
                    <li> - Examples: IBM Food Trust, VeChain.</li>
                    <li>
                      - Functionalities: Tracking product origin, verifying
                      authenticity, reducing fraud.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">3. Healthcare:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Blockchain can secure and streamline
                      healthcare records.
                    </li>
                    <li> - Examples: MedicalChain, Patientory.</li>
                    <li>
                      - Functionalities: Secure patient records,
                      interoperability between healthcare providers, patient
                      consent management.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">4. Voting Systems:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Blockchain can create secure, transparent,
                      and tamper-proof voting systems.
                    </li>
                    <li> - Examples: Follow My Vote, Voatz.</li>
                    <li>
                      - Functionalities: Voter registration, vote casting, vote
                      counting and verification.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">5. Real Estate:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Blockchain can simplify and secure real
                      estate transactions.
                    </li>
                    <li> - Examples: Propy, RealBlocks.</li>
                    <li>
                      - Functionalities: Property title management, transparent
                      property transactions, fractional ownership.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">6. Identity Management:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Blockchain can provide secure and
                      verifiable identity solutions.
                    </li>
                    <li> - Examples: Civic, uPort.</li>
                    <li>
                      - Functionalities: Identity verification, digital identity
                      creation, privacy-preserving identity management.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">7. Internet of Things (IoT):</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Blockchain can enhance the security and
                      interoperability of IoT devices.
                    </li>
                    <li> - Examples: IOTA, IoTeX.</li>
                    <li>
                      - Functionalities: Secure device communication, automated
                      smart contracts, decentralized IoT networks.
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <p className="font-bold text-xl">
                  4. Blockchain Functionalities
                </p>

                <div className="p-5">
                  <p className="font-bold">1. Smart Contracts:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Self-executing contracts with the terms
                      directly written into code.
                    </li>
                    <li>
                      - Benefits: Automate and streamline processes, reduce need
                      for intermediaries, enforce contract terms automatically.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">
                    2. Decentralized Applications (dApps):
                  </p>
                  <ul className="pt-3">
                    <li>
                      - Description: Applications that run on a blockchain
                      network rather than a centralized server.
                    </li>
                    <li>
                      {" "}
                      - Benefits: Increased security, transparency, and
                      resistance to censorship.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">3. Tokenization:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Converting rights to an asset into a
                      digital token on the blockchain.
                    </li>
                    <li>
                      - Benefits: Improved liquidity, fractional ownership,
                      easier transferability.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">4. Consensus Mechanisms:</p>

                  <ul className="pt-3">
                    <li>
                      - Description: Methods to achieve agreement on the state
                      of the blockchain among distributed nodes.
                    </li>
                    <li>
                      {" "}
                      - Types: Proof of Work (PoW), Proof of Stake (PoS),
                      Delegated Proof of Stake (DPoS), Byzantine Fault Tolerance
                      (BFT).
                    </li>
                    <li>- Benefits: Ensures network security and integrity.</li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">5. Cryptographic Hashing:</p>
                  <ul className="pt-3">
                    <li>
                      - Description: Creating a unique digital fingerprint of
                      data.
                    </li>
                    <li>
                      - Benefits: Ensures data integrity, secures transactions,
                      prevents tampering.
                    </li>
                  </ul>
                </div>

                <div className="p-5">
                  <p className="font-bold">
                    6. Distributed Ledger Technology (DLT):
                  </p>
                  <ul className="pt-3">
                    <li>
                      - Description: Database spread across multiple nodes.
                    </li>
                    <li>
                      {" "}
                      - Benefits: Enhances transparency, security, and
                      decentralization.
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <p className="font-bold text-xl">Introduction of Flash loan:</p>

                <div className="mt-5">
                  <p className="font-bold">1. What are Flash Loans?</p>
                  <p className="mt-3">
                    - Flash loans are unique financial instruments in
                    decentralized finance (DeFi) that allow users to borrow
                    assets without providing collateral, as long as the borrowed
                    amount plus a fee is returned within the same transaction
                    block.
                  </p>
                </div>

                <div className="mt-5">
                  <p className="font-bold">
                    2. Why Flash Loans are Revolutionary??
                  </p>
                  <p className="mt-3">
                    - Flash loans exploit the unique properties of blockchain
                    transactions, enabling instantaneous and temporary borrowing
                    for various advanced financial operations.
                  </p>
                </div>
              </div>

              <div className="p-5">
                <p className="font-bold my-3"> Conceptual Overview:</p>
                <div className="pt-3">
                  <ul>
                    <li className="font-bold">1. How Flash Loans Work:</li>
                    <li className="pt-2">
                      - Flash loans operate within the confines of a single
                      blockchain transaction. If the loan and fees are not
                      repaid by the end of the transaction, the transaction
                      reverts, ensuring no risk to the lender.
                    </li>
                  </ul>
                  <ul>
                    <li className=" my-2 font-bold">2. Key Characteristics:</li>
                    <li>- No collateral needed.</li>
                    <li>- Must be repaid within one block.</li>
                    <li>
                      - Mainly used by developers due to the complexity
                      involved.
                    </li>
                  </ul>
                  <ul>
                    <p className="font-bold  my-3">
                      {" "}
                      Technical Execution Flow:
                    </p>
                    <li className="my-3 font-bold">
                      1. Initiating a Flash Loan:
                    </li>
                    <li>
                      - The borrower’s contract requests a flash loan from the
                      liquidity pool.
                    </li>
                    <li>
                      - The pool checks the request and transfers the funds to
                      the borrower’s contract.
                    </li>
                  </ul>
                  <ul className="">
                    <li className="mt-3 font-bold">
                      2. Using the Borrowed Funds:
                    </li>
                    <li>
                      {" "}
                      - The borrower’s contract performs any desired operations
                      with the borrowed funds, such as arbitrage or collateral
                      swapping.
                    </li>
                  </ul>
                  <ul>
                    <li className="pt-3 font-bold">
                      3. Repayment within the Same Transaction:
                    </li>
                    <li>
                      {" "}
                      - The borrower’s contract must approve the pool to
                      withdraw the loaned amount plus a fee by the end of the
                      transaction.
                    </li>
                    <li>
                      - If funds are insufficient or approval is not given, the
                      transaction reverts.
                    </li>
                  </ul>
                  <p className="font-bold py-3">
                    Types of Flash Loans in Aave V3:
                  </p>
                  <ul>
                    <li className="py-3 font-bold">1. flashLoan:</li>
                    <li> - Allows borrowing from multiple reserves.</li>
                    <li>
                      - Option to open a stable or variable rate debt position.
                    </li>
                    <li>
                      - Flash loan fees can be waived for approved borrowers.
                    </li>
                  </ul>
                  <ul>
                    <li className="py-3 font-bold">2. flashLoanSimple:</li>
                    <li> - Allows borrowing from a single reserve.</li>
                    <li>
                      - More gas-efficient, suitable for simpler operations.
                    </li>
                    <li>
                      - Fees are not waived and no debt position can be opened.
                    </li>
                  </ul>
                  <p className="font-bold py-3">
                    {" "}
                    Applications of Flash Loans:
                  </p>
                  <ul>
                    <li className="pt-3 font-bold">1. Arbitrage:</li>
                    <li>
                      - Exploiting price differences across exchanges without
                      needing upfront capital.
                    </li>
                    <li className="pt-3 font-bold">2. Collateral Swapping:</li>
                    <li>
                      - Replacing one type of collateral with another to
                      optimize debt positions.
                    </li>
                    <li className="pt-3 font-bold">3. Liquidation:</li>
                    <li>
                      - Facilitating the repayment of debt positions by using
                      the collateral to repay the flash loan.
                    </li>
                  </ul>
                  <p className="font-bold my-3"> Fee Structure:</p>
                  <ul>
                    <li className="pt-3 font-bold">1. Flash Loan Fee:</li>
                    <li>
                      - Initially set at 0.05%, can be updated via governance.
                    </li>
                    <li>
                      - Split between liquidity providers (LPs) and the protocol
                      treasury.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className="flex flex-col gap-5 font-syne mt-10 w-[80%] mx-auto">
          
               
               
                
            

               
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Learn;

import React, { useState, useEffect } from "react";
import "../App.css";
import Header from "./Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWeb3ModalProvider, useWeb3ModalAccount, } from "@web3modal/ethers5/react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { NODE_URL } from "../config";

const RaiseQuery = () => {
  const { address } = useWeb3ModalAccount();

  const [subject, setSubject] = useState(null);
  const [trxHash, setTrxHash] = useState(null);
  const [description, setDescription] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleSubject = (e) => {
    setSubject(e.target.value);
  };
  const handleTrxHash = (e) => {
    setTrxHash(e.target.value);
  };
  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const RaiseTicket = async () => {

    if (subject === null) {
      toast.error("Enter the subject");
      return false;
    }
    if (description === null) {
      toast.error("Enter the Description");
      return false;
    }

    const response = await axios.post(`${NODE_URL}:3006/apiv4/addTicket`, {
      address: address,
      subject: subject,
      trxHash: trxHash,
      description: description,
    });

    if (response.status === 200) {
      toast.success("Ticket Created");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const fetchTickets = async () => {
    try {
      const response = await axios.get(
        `${NODE_URL}:3006/apiv4/fetchUserTickets?address=${address}`
      );

      if (response && response.data) {
        const list = await Promise.all(
          response.data.map(async (trans) => ({
            id: trans.id,
            userAddress: trans.userAddress,
            subject: trans.subject,
            trxHash: trans.trxHash,
            description: trans.description,
            createdAt: await convertUTCToIST(trans.createdAt),
            status: trans.status,
            ticket_id: trans.ticket_id,
            resolved_description: trans.resolved_description,
          }))
        );
        setTickets(list);
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
    }
  };

  const convertUTCToIST = (utcDate) => {
    const date = new Date(utcDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  useEffect(() => {
    if (address !== undefined) {
      fetchTickets();
    }
  }, [address]);

  const openCount = tickets.filter((entity) => entity.status === "Open").length;
  const closedCount = tickets.filter((entity) => entity.status === "Closed").length;

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tickets.slice(indexOfFirstEntity, indexOfLastEntity);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };
  const handleDetailsQuery = (ticket) => {
    setSelectedTicket(ticket);
  };
  const closeDetailsQuery = () => {
    setSelectedTicket(null);
  };

  return (
    <>
      <div
        className="text-white font-syne"
        style={{ backgroundImage: 'url("assets/AinsteinBG.png")' }}
      >
        <Header />
        <Toaster toastOptions={{ position: "top-center", }} />
        <div className="bg-gradient-to-b  py-10 from-[#000]/50 to-[#000] h-screen overflow-hidden overflow-y-auto">
          <div className="container mx-auto py-8">
            <div className="sm:w-[50%] w-full text-center mx-auto block">
              <h2 className="font-bold text-white text-center font-poppins lg:text-2xl text-xl ">
                Raise a Query
              </h2>
            </div>
            <div className="md:w-[75%] w-full mx-auto block my-8">
              <form>
                <div className="flex items-center justify-center flex-col md:flex-row">
                  <div className="md:w-[50%] w-full lg:px-12 px-2">
                    <input
                      type="text"
                      className="border border-white rounded-xl p-2 bg-transparent placeholder:text-white sm:text-sm w-full"
                      placeholder="Subject"
                      onChange={handleSubject}
                    />
                    <div className="my-8 py-4">
                      <input
                        type="text"
                        className="border border-white rounded-xl p-2 bg-transparent placeholder:text-white sm:text-sm w-full"
                        placeholder="Transaction Hash (optional)"
                        onChange={handleTrxHash}
                      />
                    </div>
                  </div>
                  <div className="md:w-[50%] w-full lg:px-12 px-2 relative">
                    <textarea
                      onChange={handleDescription}
                      placeholder="Explain your query in detail.."
                      rows={8}
                      cols={10}
                      className="border border-white rounded-2xl p-2 bg-transparent placeholder:text-white sm:text-sm w-full"
                    ></textarea>
                    <div className="absolute right-16 bottom-4">
                      <button
                        type="button"
                        className="px-4 py-2 border-none outline-none bg-[#0087A57D] rounded-lg font-semibold text-sm"
                        onClick={RaiseTicket}
                      >
                        Raise a Ticket
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="flex justify-between md:w-[85%] w-full mx-auto">
              <div>
                <h1 className="text-lg font-bold">Previous Query</h1>
              </div>
              <div className="flex justify-end gap-10">
                <div className="text-sm flex items-center">
                  <p>
                    Open:{" "}
                    <span className="text-blue-500 font-bold">{openCount}</span>
                  </p>
                </div>
                <div className="text-sm flex items-center">
                  <p>
                    Resolved:{" "}
                    <span className="text-blue-500 font-bold">
                      {closedCount}
                    </span>
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="text-end text-sm px-3 mb-2">
                    Show no of entity
                    <select
                      className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                      onChange={handleDropdownChange}
                      value={entitiesPerPage}
                    >
                      <option className="text-black" value="5">
                        5
                      </option>
                      <option className="text-black" value="7">
                        7
                      </option>
                      <option className="text-black" value="10">
                        10
                      </option>
                    </select>
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-lg md:w-[85%] w-full mx-auto block">
              <div className="block">
                <table className="w-full border-2 border-white bg-black/60 rounded-xl overflow-hidden text-center">
                  <thead className="bg-[#D9D9D9]/60 text-black ">
                    <tr className="bg-[#D9D9D9]/60 text-black font-semibold">
                      <th className="py-3 rounded-tl-xl px-3">s.no</th>
                      <th>Subject</th>
                      <th>Date & time</th>
                      <th>Status</th>
                      <th>solution</th>
                    </tr>
                  </thead>
                  <tbody className="border-2 border-white ">
                    {currentEntities.map((entity, index) => (
                      <tr
                        key={index}
                        className="h-16 text-sm border border-white text-center rounded-md"
                      >
                        <td className="px-5 md:px-0 ">
                          {indexOfFirstEntity + index + 1}
                        </td>
                        <td className="px-5 md:px-0 ">{entity.subject}</td>
                        <td className="px-5 md:px-0 ">{entity.createdAt}</td>
                        <td
                          className={`px-5 md:px-0 ${entity.status === "Open"
                            ? "text-[#FFBA00]"
                            : "text-[#36a90e]"
                            }`}
                        >
                          {entity.status}
                        </td>

                        <td className="px-5 md:px-0 ">
                          <button
                            className="bg-[#313131]  px-5 py-1 rounded-full"
                            onClick={() => handleDetailsQuery(entity)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-2 flex justify-between md:w-[85%] w-full mx-auto text-xs px-3">
              <button
                className="bg-white/30 rounded-md px-5 py-1"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button
                className="bg-white/30 rounded-md px-5 py-1"
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                Next
              </button>
            </div>

            {selectedTicket && (
              <div className="xl:fixed absolute w-full mx-auto bg-black/60 top-0 left-0 z-30 xl:inset-0 model-overlay overflow-hidden overflow-y-auto backdrop-blur-md flex items-center">
                <div className="w-[95%] lg:w-[60%] mx-auto">
                  <div className="flex justify-end">
                    <FontAwesomeIcon
                      icon={faXmarkCircle}
                      className="font-bold hover:translate-y-1 duration-300 cursor-pointer"
                      onClick={closeDetailsQuery}
                    />
                  </div>
                  <p className="text-lg font-bold">Detailed Query</p>
                  <div className="flex flex-col md:flex-row gap:5 md:gap-10 mt-5">
                    <div className="md:w-[50%]">
                      <p className="text-white/50 text-sm">Subject</p>
                      <input
                        className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 placeholder:text-sm"
                        value={selectedTicket.subject}
                        readOnly
                      />
                      <p className="text-white/50 text-sm mt-5">
                        Transaction Hash{" "}
                      </p>
                      <a
                        href={`https://polygonscan.com/tx/${selectedTicket.trxHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 placeholder:text-sm">
                          <p className="truncate">{selectedTicket.trxHash}</p>
                        </div>
                      </a>
                    </div>
                    <div className="md:w-[50%]">
                      <p className="text-white/50 text-sm ">Query in detail </p>
                      <textarea
                        className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 h-[85%] placeholder:text-sm"
                        value={selectedTicket.description}
                        readOnly
                      ></textarea>
                    </div>
                  </div>
                  {selectedTicket.resolved_description ? (
                    <>
                      <p className="text-white/50 text-sm mt-5">
                        Your solution
                      </p>
                      <textarea
                        className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 h-[150px] placeholder:text-sm"
                        value={selectedTicket.resolved_description}
                        readOnly
                      ></textarea>
                    </>
                  ) : (
                    <p className="text-white/50 text-sm mt-5">
                      Support team is working on it...
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RaiseQuery;

import React, { useState, useEffect } from "react";
import AdminHeader from "./AdminHeader";
import "../App.css";
import Chart from "react-apexcharts";
import axios from "axios";
import DataSpinners from "../FetchDatas";
import { NODE_URL } from "../config.js";

const Admin = () => {

  const [TotalUsers, setTotalUsers] = useState(0);
  const [SubUsers, setSubUsers] = useState(0);
  const [TotalTransactions, setTotalTransactions] = useState(0);
  const [TotalProfits, setTotalProfits] = useState(0);
  const [TotalVolume, setTotalVolume] = useState(0);
  const [separateCountForSubs, setSeparateCountForSubs] = useState({});
  const [isOpen, setisOpen] = useState(false);
  const [graph, setGraph] = useState([]);
  const [FetchingDatasSpinner, setFetchingDatasSpinner] = useState(false);

  const toggleModal = () => {
    setisOpen(!isOpen);
  };

  const fetchData = async () => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersCount`);
      const res = response.data;
      setTotalUsers(res.userCount);
      setTotalTransactions(res.transactionCount);
      setTotalVolume(res.borrowAmount)
      setSubUsers(res.subsCount);
      setTotalProfits(res.totalProfitUSD);

      const CountForSubs = {
        Basic: res.separateCount.license1Count,
        Survival: res.separateCount.license2Count,
        Professional: res.separateCount.license3Count,
        Premium: res.separateCount.license4Count,
        Rocketship: res.separateCount.license5Count,
      };
      const totalCount = Object.values(CountForSubs).reduce((acc, count) => acc + count, 0);
      const percentages = {};
      for (const [key, count] of Object.entries(CountForSubs)) {
        percentages[key] = (count / totalCount) * 100;
      }
      setSeparateCountForSubs(percentages);
      setFetchingDatasSpinner(false);
    } catch (error) {
      console.log("Error while fetching: ", error);
      setFetchingDatasSpinner(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchHistory();
    getDynamicData();
  }, []);

  const fetchHistory = async () => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getTransactionsCountForPlatform`);
      const res = response.data;
      setGraph(res);
      return res;
    } catch (error) {
      console.log("Error while fetching: ", error);
    }
  };

  const getPreviousDays = () => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date().getDay();
    const previousDays = [];

    for (let i = today; i >= 0; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }
    for (let i = daysOfWeek.length - 1; i > today; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }
    return previousDays;
  };

  const getDynamicData = async (walletAddress) => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getTransactionsCountForUser?walletAddress=${walletAddress}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const state = {
    options: {
      colors: ["#F98736"],
      stroke: { width: 2, curve: "smooth" },
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: getPreviousDays(),
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        style: {
          background: "#9381FF",
        },
        theme: "dark",
        y: {
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
      },
    },
    series: [
      {
        name: "Transactions ",
        data: graph,
      },
    ],
  };

  return (
    <div className=" text-white font-poppins">
      <div className="w-full h-full">
        <div
          className="bg-black min-h-full bg-cover bg-no-repeat"
          style={{ backgroundImage: `url(assets/bg.jpeg)` }}
        >
          <div className="md:absolute md:-bottom-0 md:right-0 md:w-[30rem] md:h-[30rem]  md:filter md:blur-[7rem]  md:bg-[#E43875A3]/20 md:rounded-full"></div>
          <div className="md:absolute md:top-20 md:-left-20 md:w-[25rem] md:h-[28rem]  md:filter md:blur-3xl  md:bg-[#E43875A3]/20 md:rounded-full"></div>
          <div className="">
            <AdminHeader />
            <div className="w-[90%] mx-auto pb-20 pt-10 ">
              <div className="relative flex justify-normal  sm:px-5 px-3 py-5 md:py-0 h-full bg-gradient-to-br from-[#F9873617] to-[#E4387517] rounded-2xl">
                <div
                  className="md:w-1/4 w-1/4  md:flex md:justify-center bg-no-repeat bg-center bg-cover block h-auto"
                  style={{ backgroundImage: `url(assets/Mask Group.svg)` }}
                >
                  <img
                    src="assets/mbc-bot.png"
                    className="md:w-[50%]  transform md:scale-125 scale-150  md:-translate-y-6 -translate-y-3  "
                    style={{ zIndex: 10 }}
                  />
                </div>

                <div className="md:w-[65%] sm:w-3/4 w-full flex justify-center items-center">
                  <p className="lg:text-[36px] md:text-[22px] sm:text-xl text-lg text-[#F98736] font-bold font-Artemus">
                    Welcome Back Admin!
                  </p>
                </div>
              </div>
              <div className="flex lg:flex-nowrap lg:justify-between justify-center flex-wrap items-center  mt-10">
                <a
                  href="/Users"
                  className="lg:w-[18%] md:w-[30%] sm:w-[45%] w-full mx-2 lg:my-0 my-4"
                >
                  <div className="h-full rounded-2xl p-[2px] bg-[#FFFFFF08] drop-shadow-lg">
                    <div className="h-full flex flex-col space-y-5 py-5 items-center justify-center  rounded-2xl">
                      <p className="text-[#979797] font-semibold">
                        Total Users
                      </p>
                      <p className="font-bold text-4xl">{TotalUsers} +</p>
                      <img
                        src="assets/user.png"
                        className="md:w-4/12 h-10 w-2/12 object-contain"
                      />
                    </div>
                  </div>
                </a>
                <div className="lg:w-[18%] md:w-[30%] sm:w-[45%] w-full rounded-2xl p-[2px] bg-[#FFFFFF08] drop-shadow-lg mx-2 lg:my-0 my-4">
                  <div
                    className="h-full flex flex-col space-y-5 py-5 justify-center items-center  rounded-2xl cursor-pointer"
                    onClick={toggleModal}
                  >
                    <p className="text-[#979797] font-semibold">
                      Total Subscription
                    </p>
                    <p className="font-bold text-4xl">{SubUsers} +</p>
                    <img
                      src="assets/click.png"
                      className="md:w-4/12 h-10 w-2/12 object-contain"
                    />
                  </div>
                </div>
                {/* {isOpen && (
                  <div className="w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-10">
                    <div className="w-[90%] lg:w-[50%] h-[15rem]  backdrop-blur-sm bg-black rounded-2xl shadow-2xl pb-10  z-30 bg-opacity-90">
                      <div className="flex justify-end">
                        <button
                          className=" text-sm text-white transition-transform transform-gpu hover:-translate-y-1  mt-3 mr-2"
                          onClick={toggleModal}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </div>
                      <div className="flex justify-center items-center gap-5 h-full">
                        <div className="w-[35%] flex justify-center items-center">
                          <div className="flex bg-white/20 px-3 py-2 rounded-xl">
                            <div className="w-[30%] flex justify-center items-center">
                              <img
                                src="./assets/image 123.png"
                                className="w-[7rem]"
                              ></img>
                            </div>
                            <div className="w-[70%]">
                              <p className="text-center text-sm">
                                Total Subscription
                              </p>
                              <p className="text-center font-bold">
                                {SubUsers} +
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <img
                            src="./assets/Piechart.png"
                            className="w-[8rem]"
                          ></img>
                          <p className="text-center -translate-y-20">Plan %</p>
                        </div>
                        <div className="w-[30%] flex items-center justify-center">
                          <div className="w-[80%] flex flex-col gap-3">
                            <p className="text-[#15803d] flex justify-between">
                              Basic <span className="text-white">-</span>
                              <p>{separateCountForSubs.Basic.toFixed(2)}%</p>
                            </p>
                            <p className="text-[#2563eb] flex justify-between">
                              Survival <span className="text-white">-</span>
                              <p>{separateCountForSubs.Survival.toFixed(2)}%</p>
                            </p>
                            <p className="text-[#97079A] flex justify-between">
                              Professional <span className="text-white">-</span>
                              <p>
                                {separateCountForSubs.Professional.toFixed(2)}%
                              </p>
                            </p>
                            <p className="text-[#FFBA00] flex justify-between">
                              Premium <span className="text-white">-</span>
                              <p>{separateCountForSubs.Premium.toFixed(2)}%</p>
                            </p>
                            <p className="text-[#dc2626] flex justify-between">
                              Rocketship <span className="text-white">-</span>
                              <p>
                                {separateCountForSubs.Rocketship.toFixed(2)}%
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
                <div className="lg:w-[18%] md:w-[30%] sm:w-[45%] w-full rounded-2xl p-[2px] bg-[#FFFFFF08] drop-shadow-lg mx-2 lg:my-0 my-4">
                  <div className="h-full flex flex-col space-y-5 py-5 justify-center items-center rounded-2xl">
                    <p className="text-[#979797] font-semibold">
                      Total Transactions
                    </p>
                    <p className="font-bold text-4xl">{TotalTransactions} +</p>
                    <img
                      src="assets/graph2.png"
                      className="lg:w-9/12 h-10 object-contain"
                    />
                  </div>
                </div>
                <div className="lg:w-[18%] md:w-[30%] sm:w-[45%] w-full rounded-2xl p-[2px] bg-[#FFFFFF08] drop-shadow-lg mx-2 lg:my-0 my-4">
                  <div className=" h-full flex flex-col space-y-5 py-5   justify-center items-center  rounded-2xl">
                    <p className="text-[#979797] font-semibold">
                      Total Profits
                    </p>
                    <p className="font-bold text-4xl">
                      {TotalProfits ? Number(TotalProfits).toFixed(3) : 0} +
                    </p>
                    <img
                      src="assets/graph3.png"
                      className="lg:w-6/12 h-10 object-contain"
                    />
                  </div>
                </div>
                <div className="lg:w-[18%] md:w-[30%] sm:w-[45%] w-full rounded-2xl p-[2px] bg-[#FFFFFF08] drop-shadow-lg mx-2 lg:my-0 my-4">
                  <div className="h-full flex flex-col space-y-5 py-5  justify-center items-center  rounded-2xl">
                    <p className="text-[#979797] font-semibold">Total Volume</p>
                    <p className="font-bold text-4xl">
                      {Number(TotalVolume).toFixed(3)} +
                    </p>
                    <img
                      src="assets/graph4.png"
                      className="lg:w-11/12 h-10 object-contain"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-[#FFFFFF14] rounded-2xl mt-10 p-[2px] drop-shadow-xl">
                <div className=" pb-32 h-80 sm:h-96 md:h-128 text-black lg:h-144 xl:h-[30rem]  rounded-2xl ">
                  <div className="flex justify-end p-5 ">
                    {/* <select
                  className="bg-transparent rounded-md text-xs outline-none border border-white "
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="" className="text-black">For last 7 days</option>
                  <option value="" className="text-black">Arbitrum</option>
                  <option value="" className="text-black">Avalanche</option>
                  <option value="" className="text-black">Polygon</option>
                  <option value="" className="text-black">Fantom</option>
                </select> */}
                  </div>
                  <p className="text-center text-[#979797] font-bold ">
                    Transactions
                  </p>
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="line"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>

          {FetchingDatasSpinner ? <DataSpinners /> : ""}
        </div>
      </div>
    </div>
  );
};

export default Admin;

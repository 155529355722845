import React from "react";
import { useState, useEffect } from "react";
import Header from "./Header";
import Select from "react-select";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { NODE_URL } from "../config.js";

export default function Ai() {

  const { address, isConnected } = useWeb3ModalAccount();

  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);

  const from_tokens = [
    {
      name: "Dai",
      symbol: "DAI",
      address: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
      decimals: 18,
      img: "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      address: "0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39",
      decimals: 18,
      img: "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png",
    },
    {
      name: "Tether USDt",
      symbol: "USDT",
      address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      decimals: 6,
      img: "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    },
    {
      name: "Wrapped Bitcoin",
      symbol: "WBTC",
      address: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
      decimals: 8,
      img: "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png",
    },
    {
      name: "WETH",
      symbol: "WETH",
      address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
      decimals: 18,
      img: "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png",
    },
    {
      name: "Wrapped Matic",
      symbol: "WMATIC",
      address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      decimals: 18,
      img: "https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png",
    },
    {
      symbol: "USDC.e",
      address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      decimals: 6,
      name: "USD Coin",
      img: "https://s3.openocean.finance/token_logos/logos/polygon/0x2791bca1f2de4661ed88a30c99a7a9449aa84174.png",
    },
  ];

  const getTokensByNetwork = () => {
    return from_tokens.map((token) => ({
      name: token.name,
      logo: token.img,
      address: token.address,
      decimal: token.decimals,
      symbol: token.symbol,
    }));
  };
  const options = getTokensByNetwork();

  const handleChange = async (selectedOption) => {
    console.log(selectedOption?.address, selectedOption?.symbol);
    setSelectedOption(selectedOption);
  };
  const Network_option = [{ label: "Polygon", name: "Polygon" }];

  const handleChangeNetwork = async (selectedOption1) => {
    console.log(selectedOption1?.label);
    setSelectedOption1(selectedOption1);
  };

  const fetchData = async () => {
    if (selectedOption === null || selectedAmount === null || selectedOption1 === null) {
      toast.error("Please select all the options");
    }

    let checkWhitelist = await checkUserRole();
    if (checkWhitelist === false) {
      toast.error("Account not Whitelisted");
      return false;
    }

    if (selectedOption !== null && selectedAmount !== null && selectedOption1 !== null) {
      try {
        let Token;
        if (selectedOption?.symbol === "USDC.e") {
          Token = "USDC";
        } else if (selectedOption?.symbol === "WBTC") {
          Token = "BTC";
        } else if (selectedOption?.symbol === "WMATIC") {
          Token = "MATIC";
        } else if (selectedOption?.symbol === "WETH") {
          Token = "ETH";
        } else {
          Token = selectedOption?.symbol;
        }
        const Pricedata = await axios.get(`https://min-api.cryptocompare.com/data/price?api_key=afb9c2ae7b1584cd4fdf5185d22982633f6ac3d5f3cdf109debfe8e307d2b940&fsym=${Token}&tsyms=USD`);
        const amount = selectedAmount / Pricedata.data.USD;

        navigate(`/pair?from=${selectedOption?.symbol}&&amount=${selectedAmount}`);

        const requests = [];
        const ports = [3001, 3002, 3003, 3004, 3005];

        for (let i = 0; i < ports.length; i++) {
          setTimeout(() => {
            let j;
            if (i === 4) {
              j = 5;
            } else {
              j = i;
            }
            requests.push(
              axios.post(`${NODE_URL}:${ports[i]}/apiv${i}/generateAI`, {
                data: {
                  address: selectedOption?.address,
                  symbol: selectedOption?.symbol,
                  decimals: selectedOption?.decimal,
                  amount: amount.toFixed(5),
                  walletAddress: address,
                  Network: selectedOption1?.name,
                  EnterAmount: selectedAmount,
                },
              })
            );
          }, i * 2500);
        }
        const responses = await Promise.all(requests);
        setSelectedOption1(null);
        setSelectedOption(null);
        setSelectedAmount(null);
      } catch (error) {
        console.error("Error while fetching: ", error);
      }
    }
  };

  const handleButtonClick = async (amount) => {
    console.log(amount);
    setSelectedAmount(amount);
  };

  const checkUserRole = async () => {
    try {
      let checkUser = await axios.get(`${NODE_URL}:3006/apiv4/checkWhiteList?walletAddress=${address}`)
      if (checkUser.data.status === 'success') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      generateAddress(address);
    }
  }, [address, isConnected]);

  const [dummyAddress, setDummyAddress] = useState("");

  const generateAddress = async (address) => {
    try {
      const response = await axios.get(`${NODE_URL}:3010/apiv10/checkAddress?address=${address}`);
      if (response.data.success) {
        setDummyAddress(response.data.dummyAddress);
      }
    } catch (error) {
      console.error("Error while getting address : ", error);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      backgroundColor: state.isFocused ? "#dd7c0b" : "#dd7c0b",
      borderColor: state.isFocused ? "#00897b" : "#ccc",
      boxShadow: state.isFocused ? "0 0 0 1px #00897b" : "none",
      color: "#ffffff",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#27062b", // Set dropdown menu background color
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#b2dfdb" : "#dd7c0b", // Set option background color
      color: state.isFocused ? "#00897b" : "#fff", // Set option text color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff", // Set text color for selected option
    }),
  };

  useEffect(() => {
    const handlePageRefresh = async () => {
      try {
        await axios.post(`${NODE_URL}:3006/apiv4/resetPairs?walletAddress=${address}`);
      } catch (error) {
        console.error("Error resetting pairs:", error);
      }
    };

    if (address !== undefined && isConnected !== false) {
      handlePageRefresh();
    }
  }, [address]);

  return (
    <div className="text-white font-poppins overflow-hidden ">
      <div
        className="min-h-screen "
        style={{ backgroundImage: `url(assets/image94.png)` }}
      >
        <Header />
        <Toaster toastOptions={{ position: "top-center", }} />
        <div className="bg-black/50">
          <div className="">
            <img
              src="./assets/poppers.png"
              className="w-[10rem]  md:w-[20rem] 2xl:w-[24rem] absolute"
            ></img>
            <img
              src="./assets/poppers.png"
              className="w-[10rem] md:w-[20rem] 2xl:w-[24rem] scale-x-[-1] absolute right-0"
            ></img>
          </div>
          <div className="md:absolute md:top-14 md:-left-48 md:w-[30rem] md:h-[30rem]  md:filter md:blur-3xl  md:bg-[#E43875]/20 md:rounded-full"></div>

          <div className="relative z-10">
            <div className="md:px-10 px-5 2xl:mt-10 md:mt-0  mt-10 pb-20">
              <div className="md:absolute md:-top-16 md:-right-10 md:w-[26rem] md:h-[26rem] md:filter md:blur-2xl   md:bg-[#E43875]/20 md:rounded-full "></div>
            </div>
            <p className="text-center font-bold text-white text-2xl md:text-3xl 2xl:[48px] tracking-widest font-Artemus">
              Welcome to Ainstein AI!
            </p>
            <div className="flex justify-center py-10 md:py-20">
              <div className="w-[85%] md:w-[75%] border-2 border-[#E43875]/80  bg-[#E43875]/20 p-5 2xl:p-10 rounded-xl">
                <p className="text-center text-xs 2xl:text-[15px]">
                  Click to choose Ticket in usd!*
                </p>
                <div className="md:flex">
                  <div className="w-[95%] font-Artemus flex justify-center items-center gap-3 md:gap-10 2xl:gap-14 py-7 md:py-12 2xl:py-16">
                    <div
                      className={`flex justify-center items-center cursor-pointer active:translate-y-2 duration-500 ${selectedAmount === 1
                        ? "active brightness-100 rounded-lg"
                        : selectedAmount
                          ? "brightness-50"
                          : ""
                        }`}
                      onClick={() => handleButtonClick(1)}
                    >
                      <img
                        src="./assets/image 153.png"
                        className="w-[6rem] md:w-[5rem] 2xl:w-[6rem]"
                        alt="Your alt text"
                      />
                      <p className="font-bold md:text-xl 2xl:text-3xl absolute">
                        1
                      </p>
                    </div>

                    <div
                      className={`flex justify-center items-center cursor-pointer active:translate-y-2 duration-500 ${selectedAmount === 3
                        ? "active brightness-100 rounded-lg"
                        : selectedAmount
                          ? "brightness-50"
                          : ""
                        }`}
                      onClick={() => handleButtonClick(3)}
                    >
                      <img
                        src="./assets/image 153.png"
                        className="w-[6rem] md:w-[5rem] 2xl:w-[6rem]"
                        alt="Your alt text"
                      />
                      <p className="font-bold md:text-xl 2xl:text-3xl absolute">
                        3
                      </p>
                    </div>

                    <div
                      className={`flex justify-center items-center cursor-pointer active:translate-y-2 duration-500 ${selectedAmount === 5
                        ? "active brightness-100 rounded-lg"
                        : selectedAmount
                          ? "brightness-50"
                          : ""
                        }`}
                      onClick={() => handleButtonClick(5)}
                    >
                      <img
                        src="./assets/image 153.png"
                        className="w-[6rem] md:w-[5rem] 2xl:w-[6rem]"
                        alt="Your alt text"
                      />
                      <p className="font-bold md:text-xl 2xl:text-3xl absolute">
                        5
                      </p>
                    </div>

                    <div
                      className={`flex justify-center items-center cursor-pointer active:translate-y-2 duration-500 ${selectedAmount === 8
                        ? "active brightness-100 rounded-lg"
                        : selectedAmount
                          ? "brightness-50"
                          : ""
                        }`}
                      onClick={() => handleButtonClick(8)}
                    >
                      <img
                        src="./assets/image 153.png"
                        className="w-[6rem] md:w-[5rem] 2xl:w-[6rem]"
                        alt="Your alt text"
                      />
                      <p className="font-bold md:text-xl 2xl:text-3xl absolute">
                        8
                      </p>
                    </div>
                    <div
                      className={`flex justify-center items-center cursor-pointer active:translate-y-2 duration-500 ${selectedAmount === 10
                        ? "active brightness-100 rounded-lg"
                        : selectedAmount
                          ? "brightness-50"
                          : ""
                        }`}
                      onClick={() => handleButtonClick(10)}
                    >
                      <img
                        src="./assets/image 153.png"
                        className="w-[6rem] md:w-[5rem] 2xl:w-[6rem]"
                        alt="Your alt text"
                      />
                      <p className="font-bold md:text-xl 2xl:text-3xl absolute">
                        10
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex md:flex-row flex-col justify-center gap-8 w-[60%] mx-auto text-white">
                  <Select
                    className="md:w-[30%] w-[80%] mx-auto"
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    getOptionLabel={(option) => (
                      <div className="flex">
                        <img
                          src={option.logo}
                          alt={option.name}
                          className="h-5 mr-2"
                        />
                        {option.name}
                      </div>
                    )}
                    getOptionValue={(option) => option.address}
                    placeholder="select"
                    styles={customStyles}
                  />

                  <Select
                    className="md:w-[30%] w-[80%] mx-auto"
                    value={selectedOption1}
                    onChange={handleChangeNetwork}
                    options={Network_option}
                    getOptionLabel={(option) => (
                      <div className="flex">{option.name}</div>
                    )}
                    getOptionValue={(option) => option.address}
                    placeholder="select"
                    styles={customStyles}
                  />
                </div>
                <div className="flex mt-10 justify-center gap-8 text-white">
                  <button
                    className="bg-[#E43875] px-7 py-2 rounded-xl"
                    onClick={fetchData}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "../App.css";
import axios from "axios";
import { NavLink } from "react-router-dom";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Typography,
} from "@material-tailwind/react";
import {
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  console.log(address, chainId, isConnected);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      const result = await axios.get(
        `http://localhost:3006/apiv4/checkStatus?address=${address}`
      );
      console.log(result.data.status);
      if (result.data.status === 0) {
        navigate("/");
      }
    };

    if (isConnected === true && address !== undefined) {
      fetchStatus();
    }
  }, [address, isConnected]);

  return (
    <nav className="flex bg-black/20 py-2 items-center">
      <div className="flex items-center md:w-[15%] w-[40%]">
        <NavLink to="/">
          <img src="assets/MBC Logo_Final 1.png" className="" />
        </NavLink>
      </div>

      <div
        className={`lg:static text-xs lg:flex-row flex-col absolute lg:min-h-fit min-h-[30vh] lg:space-x-32 py-5 left-0 flex justify-center text-center items-center lg:justify-between ${open ? "top-[8%] bg-black z-30 text-white" : "top-[-100%]"
          } w-full`}
      >
        <ul className="w-[80%] flex lg:flex-row flex-col text-white items-center gap-8">
          <a className="hover:text-gray-500" href="/Dapp">
            Dapp
          </a>

          <a className="hover:text-gray-500" href="/Trades">
            Transaction History
          </a>

          <a className="hover:text-gray-500" href="/ai">
            AI Bot
          </a>
          <a className="hover:text-gray-500" href="/wallet">
            Wallet
          </a>

          <a className="hover:text-gray-500" href="/Learn">
            Learn
          </a>

          <a className="hover:text-gray-500" href="/RaiseQuery">
            Raise Query
          </a>


          <div className="lg:block hidden">
            <Menu>
              <MenuHandler>
                <div className="flex items-center cursor-pointer">
                  <Avatar
                    variant="circular"
                    alt="tania andrew"
                    className="w-10 h-10 lg:inline-block hidden rounded-full"
                    src="assets/mbc-bot.png"
                  />
                  <span className="lg:hidden block text-white">My Profile</span>
                </div>
              </MenuHandler>
              <MenuList>
                <MenuItem className="flex items-center gap-2">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM10 5C10 5.53043 9.78929 6.03914 9.41421 6.41421C9.03914 6.78929 8.53043 7 8 7C7.46957 7 6.96086 6.78929 6.58579 6.41421C6.21071 6.03914 6 5.53043 6 5C6 4.46957 6.21071 3.96086 6.58579 3.58579C6.96086 3.21071 7.46957 3 8 3C8.53043 3 9.03914 3.21071 9.41421 3.58579C9.78929 3.96086 10 4.46957 10 5ZM8 9C7.0426 8.99981 6.10528 9.27449 5.29942 9.7914C4.49356 10.3083 3.85304 11.0457 3.454 11.916C4.01668 12.5706 4.71427 13.0958 5.49894 13.4555C6.28362 13.8152 7.13681 14.0009 8 14C8.86319 14.0009 9.71638 13.8152 10.5011 13.4555C11.2857 13.0958 11.9833 12.5706 12.546 11.916C12.147 11.0457 11.5064 10.3083 10.7006 9.7914C9.89472 9.27449 8.9574 8.99981 8 9Z"
                      fill="#90A4AE"
                    />
                  </svg>

                  <Typography variant="small" className="font-medium">
                    <a href="/profile">My Profile</a>
                  </Typography>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="lg:hidden block">
            <a className="hover:text-gray-500" href="/profile">
              My Profile
            </a>
          </div>

        </ul>

        <div className="gap-4 lg:mt-0 mt-10">
          <w3m-button />
        </div>
      </div>
      <div
        onClick={() => setOpen(!open)}
        className="lg:hidden block cursor-pointer absolute right-8 top-6"
      >
        <ion-icon name={open ? "close" : "menu"} />
      </div>
    </nav>
  );
};

export default Header;

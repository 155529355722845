import React, { useState, useEffect } from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faChevronDown, faCircleXmark, } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import axios from "axios";
import { ethers, Wallet } from "ethers";
import BigNumber from "bignumber.js";
import toast, { Toaster } from "react-hot-toast";
import { useWeb3ModalProvider, useWeb3ModalAccount, } from "@web3modal/ethers5/react";
import Polytokens from "../FromPolygon.json";
import to_Polytokens from "../ToPolygon.json";
import TranSpinners from "../TransSpinner";
import DataSpinners from "../FetchDatas";
import Spinners from "../Spinner";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import { polygon_flashloan_address, polygon_whitelist_address, } from "../Network_Address.js";
import { polygon_flashloan_abi, polygon_whitelist_abi, } from "../Network_Abi.js";
import { useNavigate } from "react-router-dom";
import { NODE_URL } from "../config.js";

function Dapp() {
  const [showPopupBorrow, setShowPopupBorrow] = useState(false);
  const [showPopupTo, setShowPopupTo] = useState(false);
  const [selectedBorrowToken, setSelectedBorrowToken] = useState(null);
  const [amountPopUp, setAmountPopUp] = useState("");
  const [enteredBorrowAmount, setEnteredBorrowAmount] = useState(0);
  const [enteredUSDAmount, setEnteredUSDAmount] = useState(0);
  const [payBackamount, setPayBackamount] = useState(0);
  const [aaveAmount, setAaveAmount] = useState(0);
  const [selectedToToken, setSelectedToToken] = useState(null);
  const [profitusd, setProfitusd] = useState(0);
  const [priceResponse, setPriceResponse] = useState(null);
  const [confirmPopUp, setConfirmPopUp] = useState(false);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [FetchingDatasSpinner, setFetchingDatasSpinner] = useState(false);
  const [FetchingPricesSpinner, setFetchingPricesSpinner] = useState(false);
  const [TransactionSpinner, setTransactionSpinner] = useState(false);

  const [fromSearchInput, setFromSearchInput] = useState("");
  const [toSearchInput, setToSearchInput] = useState("");

  const [fromFilteredTokens, setFromFilteredTokens] = useState([]);
  const [toFilteredTokens, setToFilteredTokens] = useState([]);

  const handleSearchFromToken = (event) => {
    setFromSearchInput(event.target.value);
  };
  const handleSearchToToken = (event) => {
    setToSearchInput(event.target.value);
  };

  useEffect(() => {
    if (Polytokens !== null) {
      if (typeof fromSearchInput === "string") {
        const fromFiltered = Polytokens.filter((token) => {
          const label = token.symbol ?? "";
          return label.toLowerCase().includes(fromSearchInput.toLowerCase());
        });
        if (selectedToToken) {
          setFromFilteredTokens(
            fromFiltered.filter(
              (token) => token.symbol !== selectedToToken?.symbol
            )
          );
        } else {
          setFromFilteredTokens(fromFiltered);
        }
      } else {
        console.warn("fromSearchInput is undefined or not a string.");
      }
    }

    if (to_Polytokens !== null) {
      if (typeof toSearchInput === "string") {
        const toFiltered = to_Polytokens.filter((token) => {
          const label = token.symbol ?? "";
          return label.toLowerCase().includes(toSearchInput.toLowerCase());
        });
        if (selectedBorrowToken) {
          setToFilteredTokens(
            toFiltered.filter(
              (token) => token.symbol !== selectedBorrowToken?.symbol
            )
          );
        } else {
          setToFilteredTokens(toFiltered);
        }
      } else {
        console.warn("toSearchInput is undefined or not a string.");
      }
    }
  }, [
    fromSearchInput,
    toSearchInput,
    selectedBorrowToken,
    selectedToToken,
    Polytokens,
    to_Polytokens,
  ]);

  const [PlatformTransactionCount, setPlatformTransactionCount] = useState(0);
  const [UserCount, setUserCount] = useState(0);
  const [PlatformProfit, setPlatformProfit] = useState(0);
  const [totalBorrowedAmount, setTotalBorrowedAmount] = useState(0);
  const [userStats, setUserStats] = useState(null);
  const [UserTransactionCount, setUserTransactionCount] = useState(0)

  const [UserBorrowedAmount, setUserBorrowedAmount] = useState(0);
  const [UserProfitAmount, setUserProfitAmount] = useState(0);



  const fetchPlatformDatas = async () => {
    try {
      setFetchingDatasSpinner(true)

      const getPlatforms = await getPlatformStats();

      setPlatformTransactionCount(getPlatforms.transactionCount ?? 0);
      setPlatformProfit(getPlatforms.totalProfitUSD ?? 0);
      setTotalBorrowedAmount(getPlatforms.borrowAmount ?? 0);

      const walletAddress = address;

      if (walletAddress && walletAddress !== undefined) {
        const checkWhiteListed = await checkUserRole(walletAddress);

        if (checkWhiteListed === true) {
          const getUsersBorrowed = await axios.get(`${NODE_URL}:3006/apiv4/getUserTransactions?walletAddress=${address}`);
          console.log(getUsersBorrowed)
          setUserProfitAmount(getUsersBorrowed.data.profitAmount ?? 0)
          setUserBorrowedAmount(getUsersBorrowed.data.borrowedAmount ?? 0)
          setUserTransactionCount(getUsersBorrowed.data.total)
        } else {
          toast.error('Account Not Whitelisted');
        }
      }
      setFetchingDatasSpinner(false)
    } catch (error) {
      console.error('Error fetching data:', error);
      setFetchingDatasSpinner(false)

    }
  };
  const fetchUserTransactions = async () => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersCount`);
      const res = response.data
      setUserCount(res.userCount)
    } catch (error) {
      console.log('Error while fetching user transactions: ', error);
    }
  };
  useEffect(() => {
    if (isConnected === true && address !== undefined) {
      fetchPlatformDatas()
      fetchUserTransactions()
    }
  }, [address, isConnected]);


  const handleconfirm = () => {
    setConfirmPopUp(!confirmPopUp);
  };
  const handleBorrowToken = (token) => {
    console.log(token);
    setSelectedBorrowToken(token);
    setShowPopupBorrow(false);
  };
  const handleEnteredAmount = () => {
    setAmountPopUp(!amountPopUp);
  };
  const handleToToken = (token) => {
    setSelectedToToken(token);
    setShowPopupTo(false);
  };
  const validateInput = async (event) => {
    try {
      let inputValue = event.target.value;

      setEnteredBorrowAmount(inputValue);
      console.log(event.target.value);
      inputValue = inputValue.replace(/[^0-9.]/g, "");
      if (inputValue.charAt(0) === ".") {
        inputValue = "0" + inputValue;
      }
      inputValue = inputValue.replace(/[^0-9.]/g, "");
      inputValue = inputValue.replace(/^0+(?=\d)/, "");

      event.target.value = inputValue;

      const fromSymbol = selectedBorrowToken?.symbol;
      console.log("from_symbol", fromSymbol);

      let Token;
      switch (fromSymbol) {
        case "USDC.e":
          Token = "USDC";
          break;
        case "WBTC":
          Token = "BTC";
          break;
        case "WMATIC":
        case "stMATIC":
          Token = "MATIC";
          break;
        case "WETH":
          Token = "ETH";
          break;
        default:
          Token = fromSymbol;
      }

      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/price?api_key=afb9c2ae7b1584cd4fdf5185d22982633f6ac3d5f3cdf109debfe8e307d2b940&fsym=${Token}&tsyms=USD`
      );
      const data = response.data;

      if (data.Response === "Error") {
        console.error("Error in API response:", data.Message);
        return;
      }

      const conversionRate = data ? data.USD : 0;
      console.log("Conversion Rate:", conversionRate);

      setEnteredUSDAmount((inputValue * conversionRate).toFixed(4));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const changeDestValue = async () => {
    setAmountPopUp(false);
    try {
      console.log(enteredBorrowAmount);

      if (enteredBorrowAmount > 0) {
        if (enteredUSDAmount === 0 || enteredUSDAmount > 50000) {
          toast.error("Entered Amount is Greater than 50000 USD", {
            duration: 1000,
          });
        } else if (enteredUSDAmount <= 0) {
          toast.error("Entered Amount should be Greater than 0 USD", {
            duration: 1000,
          });
        } else if (enteredUSDAmount <= 50000) {
          window.localStorage.setItem("amount", enteredBorrowAmount);
          //handleEnteredAmount();
        }
      }

      let PayAmount =
        parseInt(enteredBorrowAmount) +
        parseFloat(enteredBorrowAmount * (0.05 / 100));
      setPayBackamount(PayAmount);
      let PayBack = parseFloat(enteredBorrowAmount * (0.05 / 100));
      setAaveAmount(PayBack);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchProfitData = async (Amounts) => {
    try {
      setFetchingPricesSpinner(true);

      if (address === undefined) {
        toast.error("Please Connect Your Wallet Before Trading");
        return false;
      }

      const addressIn = selectedBorrowToken?.address;
      const addressOut = selectedToToken?.address;
      const Amount = enteredBorrowAmount;
      const from_symbol = selectedBorrowToken?.symbol;
      const to_symbol = selectedToToken?.symbol;
      const from_decimal = selectedBorrowToken?.decimals;
      const to_decimal = selectedToToken?.decimals;

      const data = {
        amount: Amount,
        networkID: 137,
        from_token: from_symbol,
        to_token: to_symbol,
        from_address: addressIn,
        to_address: addressOut,
        from_decimal: from_decimal,
        to_decimal: to_decimal,
      };
      console.log("Sending data:", data);

      const response = await axios.post(`${NODE_URL}:3006/apiv4/generateAIfor2dex`, { data });

      console.log("API Response:", response.data);
      setPriceResponse(response.data);
      setFetchingPricesSpinner(false);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error While Fetching the Datas");
      setTimeout(() => {
        // window.location.reload();
        setFetchingPricesSpinner(false);
      }, 1200);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      if (selectedBorrowToken && selectedToToken && enteredUSDAmount > 10) {
        fetchProfitData();
      }
    };
    fetchData();

    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, [selectedBorrowToken, selectedToToken, enteredUSDAmount]);

  const profitUSDFunction = async (priceResponse) => {
    const to_symbol = selectedBorrowToken?.symbol;
    let Token;

    if (to_symbol == "USDC.e") {
      Token = "USDC";
    } else if (to_symbol == "WBTC") {
      Token = "BTC";
    } else if (to_symbol == "WMATIC") {
      Token = "MATIC";
    } else if (to_symbol == "WETH") {
      Token = "ETH";
    } else if (to_symbol == "stMATIC") {
      Token = "MATIC";
    } else {
      Token = to_symbol;
    }
    const data = await axios.get(
      `https://min-api.cryptocompare.com/data/price?api_key=afb9c2ae7b1584cd4fdf5185d22982633f6ac3d5f3cdf109debfe8e307d2b940&fsym=${Token}&tsyms=USD`
    );
    const check = data ? data.data.USD : 0;

    console.log(check);
    setProfitusd(check);
  };
  useEffect(() => {
    profitUSDFunction();
  }, [priceResponse]);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const fromValue = queryParams.get("from");
  const toValue = queryParams.get("to");
  const fromAmount = queryParams.get("amount");

  useEffect(() => {
    console.log(fromValue, toValue, fromAmount, fromFilteredTokens);

    if (fromValue !== null && toValue !== null && fromAmount) {
      QueryParams();
    }
  }, [fromValue, toValue, fromAmount, Polytokens]);

  const QueryParams = async () => {
    let from_token;
    if (fromValue) {
      const selectedFromOption = Polytokens.find(
        (option) => option.symbol === fromValue
      );
      console.log(selectedFromOption);
      if (selectedFromOption) {
        setSelectedBorrowToken(selectedFromOption);
        from_token = selectedFromOption?.symbol;
      }
    }

    if (fromAmount) {
      validateInputParam(fromAmount, from_token);
    }

    if (toValue) {
      const selectedToOption = to_Polytokens.find(
        (option) => option.symbol === toValue
      );
      console.log(selectedToOption);
      if (selectedToOption) {
        setSelectedToToken(selectedToOption);
      }
    }
  };

  useEffect(() => {
    const fetchData = () => {
      if (
        selectedBorrowToken &&
        selectedToToken &&
        amountPopUp !== true &&
        address !== undefined &&
        enteredUSDAmount > 0
      ) {
        //enteredUSDAmount > 10 &&
        fetchProfitData();
      }
    };
    fetchData();

    const fetchDataNew = () => {
      if (
        selectedBorrowToken &&
        selectedToToken &&
        amountPopUp !== true &&
        address !== undefined
      ) {
        //enteredUSDAmount > 10 &&
        fetchProfitDataNew(address);
      }
    };

    const interval = setInterval(fetchDataNew, 3000);
    return () => clearInterval(interval);
  }, [
    selectedBorrowToken,
    selectedToToken,
    enteredUSDAmount,
    amountPopUp,
    address,
  ]);

  const fetchProfitDataNew = async (address) => {
    try {
      if (address === undefined) {
        toast.error("Please Connect Your Wallet Before Trading");
        return false;
      }
      console.log(selectedToToken);
      const addressIn = selectedBorrowToken?.address;
      const addressOut = selectedToToken?.address;
      const Amount = enteredBorrowAmount;
      const from_symbol = selectedBorrowToken?.symbol;
      const to_symbol = selectedToToken?.symbol;
      const from_decimal = selectedBorrowToken?.decimals;
      const to_decimal = selectedToToken?.decimals;

      const data = {
        amount: Amount,
        networkID: 137,
        from_token: from_symbol,
        to_token: to_symbol,
        from_address: addressIn,
        to_address: addressOut,
        from_decimal: from_decimal,
        to_decimal: to_decimal,
      };
      console.log("Sending data:", data);

      const response = await axios.post(
        `${NODE_URL}:3006/apiv4/generateAIfor2dex`,
        { data }
      );

      console.log("API Response:", response.data);
      setPriceResponse(response.data);
    } catch (error) {
      console.error("Error:", error);
      // toast.error('Error While Fetching the Datas')
    }
  };

  const validateInputParam = async (amount, symbol) => {
    try {
      let inputValue = amount;
      const fromSymbol = symbol;
      console.log("from_symbol", fromSymbol);

      let Token;
      switch (fromSymbol) {
        case "USDC.e":
          Token = "USDC";
          break;
        case "WBTC":
          Token = "BTC";
          break;
        case "WMATIC":
        case "stMATIC":
          Token = "MATIC";
          break;
        case "WETH":
          Token = "ETH";
          break;
        default:
          Token = fromSymbol;
      }

      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/price?api_key=afb9c2ae7b1584cd4fdf5185d22982633f6ac3d5f3cdf109debfe8e307d2b940&fsym=${Token}&tsyms=USD`
      );
      const data = response.data;

      if (data.Response === "Error") {
        console.error("Error in API response:", data.Message);
        return;
      }

      const conversionRate = data ? data.USD : 0;
      console.log("Conversion Rate:", conversionRate);

      setEnteredUSDAmount((inputValue * conversionRate).toFixed(4));

      if ((inputValue * conversionRate).toFixed(4)) {
        setEnteredBorrowAmount(inputValue);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleBorrow = async () => {
    try {
      if (address === undefined) {
        toast.error("Please Connect Your Wallet");
        return false;
      }
      const walletAddress = address;
      setConfirmPopUp(!confirmPopUp);

      setTransactionSpinner(true);

      if (priceResponse.profit_amount < 0) {
        toast.error("Non-Profitable Trade");
        setTransactionSpinner(false);
        return false;
      }

      const timestamp = Date.now();
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      let amount = enteredBorrowAmount;
      const from_addr = selectedBorrowToken?.address;
      const to_addr = selectedToToken?.address;
      const from_decimal = selectedBorrowToken?.decimals;
      const to_decimal = selectedToToken?.decimals;
      const amount1 = amount;
      const dex = [priceResponse.from_dex, priceResponse.to_dex];
      let profitAmount = priceResponse.profit_amount;
      let profitPercentage = priceResponse.profit_percentage;

      const checkWhiteList = await checkUserRole();
      if (checkWhiteList === false) {
        setTransactionSpinner(false);
        toast.error("Account is Not Whitelisted");
        return false;
      }

      console.log(
        "data",
        from_addr,
        to_addr,
        from_decimal,
        to_decimal,
        amount1,
        dex
      );
      const data = await requestForSwap(
        from_addr,
        to_addr,
        amount1,
        from_decimal,
        to_decimal,
        dex,
        selectedBorrowToken?.symbol
      );
      console.log(data);

      if (data.status === 200 && data.data.transactionHash) {
        const newTransactionData = {
          borrowAmount: amount,
          borrowAmountUSD: parseFloat(enteredUSDAmount),
          userAddress: walletAddress,
          profitUSD: profitAmount,
          profitPercentage: profitPercentage,
          pair1: selectedBorrowToken?.symbol,
          pair2: selectedToToken?.symbol,
          platform_fee: (enteredBorrowAmount * 0.05) / 100,
          status: "success",
          Date: formattedDate,
          trxHash: data.data.transactionHash ? data.data.transactionHash : null,
          chainId: chainId,
        };

        console.log(newTransactionData);
        const newTransactionRef = await axios.post(
          `${NODE_URL}:3006/apiv4/SaveTransactions`,
          newTransactionData
        );
        console.log(newTransactionRef);
        setTransactionSpinner(false);

        if (newTransactionRef.status === "success") {
          toast.success("Transaction Successfully");
          setTimeout(() => {
            // window.location.reload();
          }, 500);
        } else {
          setTransactionSpinner(false);
          toast.success("Transaction Successfully");
        }
      } else {
        const transactionData = {
          borrowAmount: amount,
          borrowAmountUSD: parseFloat(enteredUSDAmount),
          userAddress: walletAddress,
          profitUSD: -profitAmount,
          profitPercentage: -profitPercentage,
          pair1: selectedBorrowToken?.symbol,
          pair2: selectedToToken?.symbol,
          platform_fee: (enteredBorrowAmount * 0.05) / 100,
          status: "failed",
          Date: formattedDate,
          trxHash: data.error.transaction.hash
            ? data.error.transaction.hash
            : null,
          chainId: chainId,
        };
        const newTransactionRef = await axios.post(
          `${NODE_URL}:3006/apiv4/SaveTransactions`,
          transactionData
        );
        console.log(newTransactionRef);
        if (newTransactionRef.status === "success") {
          toast.success("Transaction Failed");
          setTimeout(() => {
            //window.location.reload();
          }, 500);
        }

        setTransactionSpinner(false);
        toast.error("Error during Transaction");
        setTimeout(() => {
          // window.location.reload();
        }, 500);
        return;
      }
    } catch (error) {
      console.log(error);
      setTransactionSpinner(false);
      toast.error("Error during Transaction");
      setTimeout(() => {
        //  window.location.reload();
      }, 500);
      return;
    }
  };
  const getUserStats = async () => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUserTransactions?walletAddress=${address}`);
      return response.data;
    } catch (error) {
      console.error("Error while fetching user stats: ", error);
    }
  };
  const checkUserRole = async () => {
    try {
      let checkUser = await axios.get(`${NODE_URL}:3006/apiv4/getWhiteListDetails?walletAddress=${address}`)
      if (checkUser.data.status === true) {
        setUserStats({
          TotalTradeLimit: checkUser.data.TotalTradeLimit,
          LimitRemaining: checkUser.data.LimitRemaining,
        })
        return true;
      } else {
        setUserStats({
          TotalTradeLimit: 0,
          LimitRemaining: 0,
        })
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  async function requestForSwap(
    tokenIn,
    tokenOut,
    amount,
    from_decimal,
    to_decimal,
    dex,
    from_symbol
  ) {
    try {
      if (address !== undefined) {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const contract = await new ethers.Contract(
          polygon_flashloan_address,
          polygon_flashloan_abi,
          signer
        );

        let amount1 = new BigNumber(
          parseFloat(amount) * 10 ** from_decimal
        ).toFixed();

        const newData = {
          tokenIn: tokenIn,
          tokenOut: tokenOut,
          amount: amount,
          from_decimal: from_decimal,
          to_decimal: to_decimal,
          dex: dex,
          from_symbol: selectedBorrowToken?.symbol,
          to_symbol: selectedToToken?.symbol,
          address: address,
          networkID: chainId,
        };

        let buyData;
        let sellData;
        let ProfitAmount;

        const result = await axios.post(
          `${NODE_URL}:3006/apiv4/getData`,
          newData
        );
        console.log(result);
        if (result.status === 200) {
          buyData = result.data.data.buyData;
          sellData = result.data.data.sellData;
          ProfitAmount = result.data.data.profitAmount;
        }

        console.log(from_symbol, ProfitAmount, amount);
        let profitUSDParams = await convertUSD(from_symbol, ProfitAmount);
        let BorrowUSDParams = await convertUSD(from_symbol, amount);

        let signature = await generateSign(
          address,
          polygon_flashloan_address,
          tokenIn,
          amount1,
          profitUSDParams
        );
        let tokenPairs = [tokenIn, tokenOut];

        console.log(
          tokenPairs,
          profitUSDParams,
          BorrowUSDParams,
          amount1,
          dex,
          [buyData, sellData],
          signature
        );
        if (profitUSDParams > 0) {
          const tx = await contract.requestFlashLoan(
            [
              tokenPairs,
              profitUSDParams.toFixed(0),
              BorrowUSDParams.toFixed(0),
              amount1,
              dex,
              [buyData, sellData],
            ],
            signature,
            {
              gasPrice: ethers.utils.parseUnits("100", "gwei"),
              gasLimit: 10000000,
            }
          );
          const receipt = await tx.wait();
          console.log("Success. Transaction Receipt:", receipt);
          return { status: 200, data: receipt };
        } else {
          console.log("Non-Profitable Trade");
          return { status: 400, data: "Non-Profitable Trade" };
        }
      } else {
        throw new Error(
          "MetaMask extension not detected. Please install MetaMask to proceed."
        );
      }
    } catch (error) {
      console.error("Error in requestForSwap:", error);
      //window.location.reload()
      return { status: 500, error: error };
    }
  }
  async function approve(tokens, dex) {
    try {
      if (address) {
        console.log(tokens, dex);
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const contract = await new ethers.Contract(
          polygon_flashloan_address,
          polygon_flashloan_abi,
          signer
        );

        const result = await contract.approve(dex, tokens, {
          gasLimit: 200000,
          gasPrice: ethers.utils.parseUnits("100", "gwei"),
        });
        const receipt = await result.wait();

        console.log(receipt);
        return true;
      } else {
        throw new Error(
          "MetaMask extension not detected. Please install MetaMask to proceed."
        );
      }
    } catch (error) {
      console.error("Error in token approval:", error);
      return false;
    }
  }
  async function generateSignhash(
    account,
    contractAddress,
    tokenAddress,
    amount,
    nonce
  ) {
    let _signMessage = ethers.utils.solidityKeccak256(
      ["address", "address", "address", "uint256", "uint256"],
      [contractAddress, account, tokenAddress, amount, nonce]
    );
    _signMessage = ethers.utils.arrayify(_signMessage);
    return _signMessage;
  }
  async function getRandomNonce() {
    return Math.floor(new Date().getTime() / 1000);
  }
  async function getPlatformStats() {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersCount`);
      const res = response.data;
      return res;
    } catch (error) {
      console.error("Error while fetching Platform stats: ", error);
    }
  }
  async function generateSign(account, contractAddress, tokenAddress, amount) {
    let provider = new ethers.providers.Web3Provider(walletProvider);

    let nonce = await getRandomNonce();
    let sign_wallet = new Wallet(
      "05129514f43857b058bb8a5a0791a6d91bd6794127286214ff6f841d06d2a0ea",
      provider
    );
    let signMessage = await generateSignhash(
      account,
      contractAddress,
      tokenAddress,
      amount,
      nonce
    );
    let signature = await sign_wallet.signMessage(signMessage);
    signature = ethers.utils.splitSignature(signature);
    signature = [signature.v, signature.r, signature.s, nonce];
    return signature;
  }
  async function convertUSD(fromSymbol, inputValue) {
    console.log(fromSymbol, inputValue);
    try {
      let Token;
      switch (fromSymbol) {
        case "USDC.e":
          Token = "USDC";
          break;
        case "WBTC":
          Token = "BTC";
          break;
        case "WMATIC":
        case "stMATIC":
          Token = "MATIC";
          break;
        case "WETH":
          Token = "ETH";
          break;
        default:
          Token = fromSymbol;
      }

      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/price?api_key=90f63995b7e855c6ba443d0349705b1a6240932d5c9d28dffed5af2d08e6606c&fsym=${Token}&tsyms=USD`
      );
      const data = response.data;

      if (data.Response === "Error") {
        console.error("Error in API response:", data.Message);
        return;
      }

      const conversionRate = data ? data.USD : 0;
      console.log("Conversion Rate:", conversionRate);

      let value = (inputValue * conversionRate).toFixed(4);
      console.log(value * 10 ** 6);
      return value * 10 ** 6;
    } catch (error) {
      console.log(error);
    }
  }

  const [balance, setBalance] = useState(null);
  const [dummyAddress, setDummyAddress] = useState("");
  const navigate = useNavigate();

  const handleTrade = async (pair) => {
    try {
      console.log(balance);
      setConfirmPopUp(!confirmPopUp);
      setTransactionSpinner(true);

      if (balance < 0) {
        toast.error("Please check balance");
        setTimeout(() => {
          navigate("/wallet");
        }, 3000);
        return;
      }

      setTransactionSpinner(true);
      if (address === undefined) {
        toast.error("Please Connect Your Wallet");
        return false;
      }

      if (priceResponse.profit_amount < 0) {
        toast.error("Non-Profitable Trade");
        setTransactionSpinner(false);
        return false;
      }

      const flashLoan = await axios.post(
        `${NODE_URL}:3010/apiv10/autodata`,
        {
          tokenIn: selectedBorrowToken?.address,
          tokenOut: selectedToToken?.address,
          amount: enteredBorrowAmount,
          from_decimal: selectedBorrowToken?.decimals,
          to_decimal: selectedToToken?.decimals,
          dex: [priceResponse.from_dex, priceResponse.to_dex],
          from_symbol: selectedBorrowToken?.symbol,
          to_symbol: selectedToToken?.symbol,
          address: address,
        }
      );

      console.log(flashLoan);

      if (flashLoan.data.code === "INSUFFICIENT_FUNDS") {
        console.log(flashLoan.data.code);
        setTransactionSpinner(false);
        toast.error("Insufficient fund in the Wallet Adderss");
      }
      if (flashLoan.data.argument === "profitValue") {
        console.log(flashLoan.data.code);
        setTransactionSpinner(false);
        toast.error("Error during Transaction");
      }
      if (flashLoan.data === "failed") {
        console.log(flashLoan.data);
        setTransactionSpinner(false);
        toast.error("Error during Transaction");
      }
      if (flashLoan.data === "success") {
        console.log(flashLoan.data);
        setTransactionSpinner(false);
        toast.success("Transaction Successfull");
      }
    } catch (error) {
      setTransactionSpinner(false);
      console.error("catch Error trading :", error);
    }
  };

  const handleCheckAddress = async (address) => {
    console.log(address);
    try {
      const response = await axios.get(
        `${NODE_URL}:3010/apiv10/getDummyAddress?address=${address}`
      );
      console.log(response.data.users);
      setDummyAddress(response.data.users);
    } catch (error) {
      console.error("Error checking address", error);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      handleCheckAddress(address);
    }
  }, [address, isConnected]);

  useEffect(() => {
    const fetchNativeBalance = async () => {
      try {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const balance = await provider.getBalance(dummyAddress);
        const currentBalance =
          balance.toString() / ethers.constants.WeiPerEther;
        console.log(currentBalance);
        setBalance(currentBalance);
      } catch (error) {
        console.error("Unable to get balance:", error);
        setBalance(null);
      }
    };
    if (dummyAddress !== "") {
      fetchNativeBalance();
    }
  }, [dummyAddress, chainId]);

  const isMobile = useMediaQuery({ query: "(max-width: 736px)" });

  return (
    <div className="bg-black text-white font-poppins overflow-hidden ">
      <Toaster
        toastOptions={{
          position: "top-center",
        }}
      />
      <div className="">
        <Header />
        <div className="min-h-screen font-chakra">
          <div className="relative z-10">
            <div className="md:px-10 px-5 mt-3  lg:pb-20 pb-10">
              <div className="">
                <div className="flex space-x-2 justify-start">
                  <img className="w-5" src="assets/Sparkle.svg" />
                  <h1 className="font-bold lg:text-xl text-lg font-Artemus">
                    {" "}
                    Platform Stats
                  </h1>
                </div>
                <div className="flex md:flex-row md:flex-wrap flex-nowrap flex-col justify-center lg:gap-5 gap-3 text-center mt-3 md:text-left">
                  <div className="lg:w-[23%] md:w-[45%] w-full p-2 py-5 border-4 border-[#E438751C] bg-gradient-to-r from-[#F9873630] to-[#99999930] rounded-lg flex">
                    <div className="w-[45%] mx-auto">
                      <p
                        className="md:text-3xl font-poppins text-4xl font-bold mt-2 truncate"
                        style={{
                          backgroundImage:
                            "linear-gradient(to bottom, #fb8f39, #f23c82)",
                          WebkitBackgroundClip: "text",
                          color: "transparent",
                        }}
                      >
                        ${totalBorrowedAmount}
                      </p>
                      <p className="text-sm">Trading Volume</p>
                    </div>
                    <div className="w-[35%] flex items-center justify-center">
                      <img src="../assets/Yes.png"></img>
                    </div>
                  </div>
                  <div className="lg:w-[23%] md:w-[45%] w-full p-2 py-5 border-4 border-[#E438751C] bg-gradient-to-r from-[#F9873630] to-[#99999930] rounded-lg flex ">
                    <div className="w-[40%] mx-auto">
                      <p
                        className="md:text-3xl font-poppins text-4xl font-bold mt-2 truncate"
                        style={{
                          backgroundImage:
                            "linear-gradient(to bottom, #fb8f39, #f23c82)",
                          WebkitBackgroundClip: "text",
                          color: "transparent",
                        }}
                      >
                        {PlatformTransactionCount}+
                      </p>
                      <p className="text-sm">Transactions</p>
                    </div>
                    <div className="w-[35%] flex items-center justify-center">
                      <img src="../assets/Yes.png"></img>
                    </div>
                  </div>
                  <div className="lg:w-[23%] md:w-[45%] w-full p-2 py-5 border-4 border-[#E438751C] bg-gradient-to-r from-[#F9873630] to-[#99999930] rounded-lg flex ">
                    <div className="w-[40%] mx-auto">
                      <p
                        className="md:text-3xl text-4xl font-poppins font-bold mt-2 truncate"
                        style={{
                          backgroundImage:
                            "linear-gradient(to bottom, #fb8f39, #f23c82)",
                          WebkitBackgroundClip: "text",
                          color: "transparent",
                        }}
                      >
                        ${PlatformProfit}+
                      </p>
                      <p className="text-sm">Total profit</p>
                    </div>
                    <div className="w-[35%] flex items-center justify-center">
                      <img src="../assets/Growth.svg"></img>
                    </div>
                  </div>
                  <div className="lg:w-[23%] md:w-[45%] w-full p-2 py-5 border-4 border-[#E438751C] bg-gradient-to-r from-[#F9873630] to-[#99999930] rounded-lg flex ">
                    <div className="w-[40%] mx-auto">
                      <p
                        className="md:text-3xl font-poppins text-4xl font-bold mt-2 truncate"
                        style={{
                          backgroundImage:
                            "linear-gradient(to bottom, #fb8f39, #f23c82)",
                          WebkitBackgroundClip: "text",
                          color: "transparent",
                        }}
                      >
                        {UserCount ?? 0}+
                      </p>
                      <p className="text-sm">Total Users</p>
                    </div>
                    <div className="w-[35%] flex items-center justify-center">
                      <img src="../assets/Growth.svg"></img>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`md:flex justify-between rounded-2xl border-2 md:border-y-0 md:border-x-4 border-[#E43875]/60 bg-[#E43875]/40 md:bg-[#E43875]/0 md:mt-5 mt-10 bg-cover bg-center bg-no-repeat ${isMobile ? "hidden-bg" : ""
                  }`}
                style={{
                  backgroundImage: isMobile
                    ? "none"
                    : 'url("../assets/Rectangle.png")',
                }}
              >
                <div className="md:w-[40%] text-xs rounded-2xl sm:px-5 px-2 sm:pt-3 ">
                  <div className="flex  items-center">
                    <div className="flex w-[65%] gap-2 items-center py-4">
                      <img src="assets/Sparkle.svg" className="w-5 h-5" />
                      <p className="font-bold lg:text-xl text-lg font-Artemus leading-8">
                        Borrow
                      </p>
                    </div>
                  </div>
                  {showPopupBorrow && (
                    <div className="text-white font-poppins flex justify-center items-center overflow-hidden fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md z-50">
                      <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-[85%] sm:mx-0 mx-2 bg-black/70 rounded-2xl px-2 pb-5 z-30">
                        <div className="flex flex-col gap-4 pb-2 shadow-xl rounded-t-3xl">
                          <div className="flex justify-between items-center p-5 truncate">
                            <h2 className="text-base font-bold">
                              Select a Token
                            </h2>
                            <button
                              onClick={() => setShowPopupBorrow(false)}
                              className="shadow-xl rounded-lg text-xl font-bold transform-gpu transition-transform hover:-translate-y-1 hover:shadow-lg"
                              aria-label="Close"
                            >
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                style={{ color: "#FF1313" }}
                              />
                            </button>
                          </div>
                          <div className="relative">
                            <input
                              className="w-full bg-[#E438753B] rounded-md p-1 text-sm pl-10 focus:outline-none truncate"
                              placeholder="Search Tokens"
                              value={fromSearchInput}
                              onChange={handleSearchFromToken}
                            />
                            <div className="absolute top-0 left-0 flex justify-center items-center h-full w-8">
                              <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                                size="sm"
                                style={{ color: "#FFFFFF98" }}
                              />
                            </div>
                          </div>
                          <div className="px-2 flex flex-wrap justify-center gap-2">
                            {fromFilteredTokens
                              .slice(0, 5)
                              .map((token, index) => (
                                <button
                                  key={index}
                                  className="bg-white/10 text-white font-bold flex justify-center px-3 py-2 items-center rounded-full"
                                  onClick={() => handleBorrowToken(token)}
                                >
                                  <img
                                    src={token.img}
                                    alt=''
                                    className="h-5 mr-2"
                                  />
                                  {token.symbol}
                                </button>
                              ))}
                          </div>
                          <div className="lg:w-full lg:mt-5 mt-3 max-h-56 overflow-auto custom-scrollbar px-3">
                            {fromFilteredTokens.map((token, index) => (
                              <div
                                key={index}
                                className="flex items-center space-x-2 mb-4 cursor-pointer border-b border-white/30 text-white font-bold hover:bg-gray-500 p-2"
                                onClick={() => handleBorrowToken(token)}
                              >
                                <img
                                  src={token.img}
                                  alt=''
                                  className="h-5 mr-2"
                                />
                                {token.symbol}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col lg:px-5 px-3 mt-5">
                    <div className=" bg-white/25 flex p-3 px-5 rounded-xl h-[64px]">
                      <button
                        onClick={() => setShowPopupBorrow(true)}
                        className="w-full flex items-center"
                      >
                        {selectedBorrowToken ? (
                          <>
                            <img
                              src={selectedBorrowToken.img}
                              alt=''
                              className="h-6 mr-2"
                            />
                            <p className="font-bold text-lg">
                              {selectedBorrowToken.symbol}
                            </p>
                          </>
                        ) : (
                          <div className="flex justify-between items-center w-full">
                            <p className="text-base">Select</p>
                            <p>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </p>
                          </div>
                        )}
                      </button>
                    </div>
                    <a
                      role="button"
                      onClick={selectedBorrowToken ? handleEnteredAmount : null}
                      className="w-[100%] mt-5 h-[64px] flex items-center md:text-base text-sm bg-white/25 p-3 px-5 rounded-xl outline-none"
                      placeholder="Amount"
                    >
                      {enteredBorrowAmount
                        ? "Change Quantity"
                        : "Enter Quantity"}
                    </a>
                    <p className="text-end text-sm mt-[2px]">
                      USD Price :{" "}
                      {enteredUSDAmount === "NaN" ? 0 : enteredUSDAmount} USD
                    </p>

                    {amountPopUp && selectedBorrowToken ? (
                      <div className="text-white flex justify-center items-center font-poppins overflow-hidden fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md z-50 ">
                        <div className="border-4 border-[#E438753B] bg-black/70 sm:p-10 p-7 rounded-xl relative">
                          <button
                            onClick={handleEnteredAmount}
                            className="flex justify-end "
                          >
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              size="lg"
                              style={{ color: "#FF1313" }}
                              className="absolute top-5 right-5"
                            />
                          </button>
                          <div className="flex justify-center gap-5 mt-5">
                            <p className="text-sm font-bold">Enter Quantity</p>
                            <input
                              className="w-28 sm:text-base text-sm text-center border-b border-white/70 bg-transparent text-white outline-none truncate"
                              type="text"
                              placeholder="Amount"
                              onChange={validateInput}
                            />
                          </div>
                          <div className=" flex justify-left mt-7 gap-5 font-semibold text-sm">
                            <p className="text-sm font-bold truncate">
                              Enter Quantity :{" "}
                              {enteredUSDAmount ? enteredUSDAmount : 0}
                            </p>
                          </div>
                          <div className=" flex justify-center mt-7 gap-5 font-semibold text-sm">
                            <button
                              className="bg-[#1EEF32] px-7 py-1 rounded-lg"
                              onClick={changeDestValue}
                              disabled={enteredUSDAmount < 0}
                            >
                              Confirm
                            </button>
                          </div>
                          {enteredUSDAmount && enteredUSDAmount <= 0 ? (
                            <p className="text-[#FF0000] text-xs mt-1">
                              Please Enter the value Greater than 0 USD.
                            </p>
                          ) : (
                            <p className="text-[#FF0000] text-xs mt-1"></p>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex flex-row justify-between my-2 lg:px-5 px-3">
                    <div className="">
                      <div className="flex flex-col gap-2">
                        <p className="flex justify-between gap-8 lg:text-sm">
                          Borrowed Asset
                          <span className="font-semibold ">
                            {selectedBorrowToken
                              ? selectedBorrowToken.symbol
                              : "  "}
                          </span>
                        </p>
                        <p className="flex justify-between gap-2 lg:text-sm">
                          Amount
                          <span className="font-semibold truncate">
                            {enteredBorrowAmount ? enteredBorrowAmount : 0}{" "}
                            {selectedBorrowToken
                              ? selectedBorrowToken.symbol
                              : ""}
                          </span>
                        </p>
                        <p className="flex justify-between gap-2 lg:text-sm">
                          Lender Fee
                          <span className="font-semibold truncate">
                            {aaveAmount ? aaveAmount : 0}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 ">
                      <p className="md:text-sm text-xs ">Payable Amount :</p>
                      <p className="font-bold text-center mt-0.5 truncate">
                        {" "}
                        {payBackamount ? payBackamount : 0}{" "}
                        {selectedBorrowToken ? selectedBorrowToken.symbol : ""}
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mt-4 pb-3  ">
                    <div className=" leading-8 lg:px-5 px-3">
                      <p className="flex lg:gap-3 gap-1 lg:text-sm text-xs ">
                        Estimated Bonus:
                        <span className="text-[#FF1313]">
                          {priceResponse &&
                            priceResponse.profit_amount !== null ? (
                            priceResponse.profit_amount > 0 ? (
                              <span className="text-[#1EEF32]">
                                {Number(
                                  profitusd * priceResponse.profit_amount
                                ).toFixed(4)}{" "}
                                USDT
                              </span>
                            ) : (
                              <span className="text-[#FF0000]">
                                {Number(
                                  profitusd * priceResponse.profit_amount
                                ).toFixed(4)}{" "}
                                USDT
                              </span>
                            )
                          ) : (
                            <span className="text-[#1EEF32]">0</span>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="flex items-center justify-center ">
                  <img
                    src="../assets/MBC Logo_Final 2.png"
                    className="hidden md:block"
                  ></img>
                </div>
                <div className="md:w-[40%] rounded-[20px] sm:px-5 px-2 sm:pt-3">
                  <div className="flex justify-between md:justify-end items-center py-4 md:ml-12">
                    <div className="flex md:w-[50%] space-x-2 items-center pl-2">
                      <img src="assets/Sparkle.svg" className="w-5" />
                      <h1 className="font-bold lg:text-xl text-lg font-Artemus">
                        Swap
                      </h1>
                    </div>
                    <div className="md:w-[40%] ">
                      {/* <p className="text-sm text-end">Balance: 1705</p> */}
                    </div>
                  </div>

                  <div className="">
                    <div className="md:flex flex-col">
                      <div className=" md:w-[90%] bg-white/25 rounded-xl px-3 py-2 flex items-center justify-between md:flex-row  mx-auto">
                        <div>
                          <div className="md:flex justify-center">
                            <a className="flex justify-center gap-2 cursor-pointer bg-black border-b border-solid border-indigo-400 px-5 py-0.5 rounded-md w-[8rem]">
                              {selectedBorrowToken ? (
                                <>
                                  <img
                                    src={selectedBorrowToken.img}
                                    alt=''
                                    className="h-5  mr-2"
                                  />
                                  {selectedBorrowToken.symbol}
                                </>
                              ) : (
                                <div className="flex items-center">
                                  <p className=" p-1 flex text-sm items-center">
                                    Select
                                  </p>
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="xs"
                                  />
                                </div>
                              )}
                            </a>
                          </div>
                        </div>
                        <div className="text-sm text-center ">
                          {" "}
                          <p className="">Amount</p>
                          <p className=" font-bold mt-3 truncate">
                            {enteredUSDAmount === "NaN" ? 0 : enteredUSDAmount}{" "}
                            USD
                          </p>
                        </div>
                      </div>

                      <div className="w-2 h-full bg-white"></div>
                      <div className="flex justify-center items-center my-4 md:my-0">
                        <img
                          src="assetsgpt/icon.png"
                          class="md:w-12 w-10 absolute translate-y-3"
                        />
                      </div>

                      <div className=" md:w-[90%] bg-white/25 rounded-xl px-3 py-2 flex items-center justify-between md:flex-row  mx-auto mt-5">
                        <div className="flex flex-col items-center">
                          <div className="md:flex justify-center">
                            {showPopupTo && (
                              <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-25 backdrop-blur-md z-50">
                                <div className="bg-black/70 rounded-2xl px-2 pb-5 z-30 w-full max-w-[65%] md:max-w-[35%] sm:max-w-[50%] lg:max-w-[25%]">
                                  <div className="flex flex-col gap-4 pb-2 shadow-xl rounded-t-3xl">
                                    <div className="flex justify-between items-center px-5 mt-4">
                                      <p className="text-base text-white font-bold">
                                        Select a Token
                                      </p>
                                      <button
                                        onClick={() => setShowPopupTo(false)}
                                        className="text-xl font-bold text-white transition-transform transform hover:-translate-y-1 rounded-lg shadow-xl mr-2 mt-3"
                                      >
                                        <FontAwesomeIcon
                                          icon={faCircleXmark}
                                          style={{ color: "#FF1313" }}
                                        />
                                      </button>
                                    </div>
                                    <div className="relative">
                                      <input
                                        type="text"
                                        className="w-full bg-[#DD7C0B73] rounded-md p-1 text-sm text-white pl-10 focus:outline-none"
                                        placeholder="Search Tokens"
                                        value={toSearchInput}
                                        onChange={handleSearchToToken}
                                      />
                                      <div className="absolute top-0 left-0 flex items-center justify-center h-full w-8">
                                        <FontAwesomeIcon
                                          icon={faMagnifyingGlass}
                                          size="sm"
                                          style={{ color: "#FFFFFF98" }}
                                        />
                                      </div>
                                    </div>
                                    <div className="px-2 flex flex-wrap justify-center gap-2">
                                      {toFilteredTokens
                                        .slice(0, 5)
                                        .map((token, index) => (
                                          <button
                                            key={index}
                                            className="bg-white/10 text-white font-bold flex justify-center items-center rounded-full px-3 py-2"
                                            onClick={() => handleToToken(token)}
                                          >
                                            <img
                                              src={token.img}
                                              alt=''
                                              className="h-5 mr-2"
                                            />
                                            {token.symbol}
                                          </button>
                                        ))}
                                    </div>
                                    <div className="mt-5 max-h-[14rem] overflow-auto custom-scrollbar px-3">
                                      {toFilteredTokens.map((token, index) => (
                                        <div
                                          key={index}
                                          className="flex items-center mb-4 cursor-pointer border-b border-white/30 text-white font-bold hover:bg-gray-500 p-2 space-x-2"
                                          onClick={() => handleToToken(token)}
                                        >
                                          <img
                                            src={token.img}
                                            alt=''
                                            className="h-5 mr-2"
                                          />
                                          {token.symbol}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <button
                              className="flex justify-center gap-2 cursor-pointer bg-black border-b border-indigo-400 rounded-md px-5 py-0.5 w-[8rem]"
                              onClick={() => setShowPopupTo(true)}
                              disabled={!selectedBorrowToken}
                            >
                              {selectedToToken ? (
                                <>
                                  <img
                                    src={selectedToToken.img}
                                    alt=''
                                    className="h-5 mr-2"
                                  />
                                  {selectedToToken.symbol}
                                </>
                              ) : (
                                <div className="flex justify-between items-center">
                                  <p className="p-1 text-sm">Select</p>
                                  <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="xs"
                                  />
                                </div>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="text-sm text-center">
                          {" "}
                          <p className="">Amount</p>
                          <p className=" font-bold mt-3 truncate">
                            {priceResponse?.firstBestSellAmount ?? 0.0}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center md:mx-10 lg:mx-5 mx-1 mt-5 text-sm pb-2  ">
                      <p className=" flex md:w-[50%] lg:gap-5 gap-2 md:text-sm text-xs items-center">
                        Estimated Profit:
                        <span
                          className={
                            priceResponse && priceResponse.profit_percentage < 0
                              ? "text-[#FF0000]"
                              : "text-[#1EEF32]"
                          }
                        >
                          {priceResponse && priceResponse.profit_percentage}%
                        </span>
                      </p>
                      <p className=" flex md:w-[50%] justify-end lg:mx-3 font-bold lg:gap-5 gap-2">
                        AAVE fee:
                        <span className="font-semibold ">0.05 %</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center my-6">
                <div
                  className="w-[80%] md:w-[30%] h-32 bg-cover bg-center font-poppins flex justify-center items-center cursor-pointer md:absolute md:-translate-y-12"
                  style={{
                    backgroundImage: 'url("../assets/Rectangle 39469 (1).png")',
                  }}
                >
                  <button
                    className="-translate-y-8 text-xl font-bold"
                    disabled={
                      enteredUSDAmount < 0 ||
                      selectedBorrowToken === null ||
                      selectedToToken === null
                    }
                    onClick={handleconfirm}
                  >
                    Confirm
                  </button>
                </div>
              </div>

              {confirmPopUp && (
                <div className="text-white flex justify-center items-center font-poppins overflow-hidden fixed inset-0 bg-black bg-opacity-25 backdrop-blur-md z-50 ">
                  <div className="bg-gradient-to-br from-white via-gray-700 to-[#DD7C0B] rounded-xl p-[2px] ">
                    <div className=" bg-gradient-to-br from-[#484037] to-black sm:p-10 p-7 rounded-xl relative">
                      <button
                        onClick={handleconfirm}
                        className="flex justify-end "
                      >
                        <FontAwesomeIcon
                          icon={faCircleXmark}
                          size="lg"
                          style={{ color: "#FF1313" }}
                          className="absolute top-5 right-5"
                        />
                      </button>
                      <div className="flex justify-center mt-5">
                        <p className="text-sm font-bold">
                          Are you sure want to trade this pair?
                        </p>
                      </div>
                      <div className=" flex justify-center mt-7 gap-5 font-semibold text-sm">
                        <button
                          className={`px-7 py-1 rounded-lg ${priceResponse.profit_amount < 0 ? 'bg-gray-500' : 'bg-[#1EEF32]'}`}
                          disabled={priceResponse.profit_amount < 0}
                          onClick={handleTrade}
                        >
                          Confirm
                        </button>
                        <button
                          onClick={handleconfirm}
                          className="bg-[#FF1313] px-7 py-1 rounded-lg"
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex md:flex-row flex-col  mt-5 gap-5 ">
                <div className="md:w-[50%] bg-gradient-to-b from-[#F98736]/35 to-[#E43875]/35 rounded-[20px] py-3 p-2">
                  <div className="flex space-x-2 px-3">
                    <img className="w-5" src="assets/Sparkle.svg" />
                    <h1 className="font-bold lg:text-xl text-lg font-Artemus">
                      Arbitrage Details
                    </h1>
                  </div>

                  <div className="sm:flex space-y-5 sm:space-y-0 sm:px-5 pt-5">
                    <div className="sm:w-[50%] space-y-5 px-5 sm:border-r-2 border-white/15">
                      <p className="text-white text-sm font-bold flex text-md justify-between truncate">
                        DEX:
                        <span
                          className={
                            priceResponse &&
                              priceResponse.firstBestBuyDex &&
                              priceResponse.firstBestSellDex
                              ? "text-[#1EEF32]"
                              : "text-[#FF0000]"
                          }
                        >
                          {priceResponse && priceResponse.firstBestBuyDex} -{" "}
                          {priceResponse && priceResponse.firstBestSellDex}
                        </span>
                      </p>
                      <p className="text-white text-sm font-bold flex text-md justify-between truncate">
                        Swap Tokens:
                        <span
                          className={
                            priceResponse &&
                              priceResponse.from_symbols &&
                              priceResponse.to_symbol
                              ? "text-[#1EEF32]"
                              : "text-[#FF0000]"
                          }
                        >
                          {priceResponse && priceResponse.from_symbols} -{" "}
                          {priceResponse && priceResponse.to_symbol}
                        </span>
                      </p>

                      <p className="text-white text-sm font-bold flex text-md justify-between truncate">
                        Profit percent(%):
                        <span
                          className={
                            priceResponse && priceResponse.profit_percentage < 0
                              ? "text-[#FF0000]"
                              : "text-[#1EEF32]"
                          }
                        >
                          {priceResponse && priceResponse.profit_percentage
                            ? priceResponse.profit_percentage
                            : 0}
                          %
                        </span>
                      </p>
                      <p className="text-white text-sm font-bold flex text-md justify-between truncate">
                        Profit Amount:
                        <span
                          className={
                            priceResponse && priceResponse.profit_amount < 0
                              ? "text-[#FF0000]"
                              : "text-[#1EEF32]"
                          }
                        >
                          {priceResponse && priceResponse.profit_amount
                            ? priceResponse.profit_amount
                            : 0}
                        </span>
                      </p>
                    </div>
                    <div className="sm:w-[50%] space-y-5 px-5">
                      <p className="text-white text-sm font-bold flex text-md justify-between truncate">
                        Payout Amount:
                        <span>{payBackamount ? payBackamount : 0}</span>
                      </p>

                      <p className="text-white text-sm font-bold flex text-md justify-between truncate">
                        Profit Amount(USD):
                        <span className="text-[#FF1313]">
                          {priceResponse &&
                            priceResponse.profit_amount !== null ? (
                            priceResponse.profit_amount > 0 ? (
                              <span className="text-[#1EEF32]">
                                {Number(
                                  profitusd * priceResponse.profit_amount
                                ).toFixed(4)}
                              </span>
                            ) : (
                              <span className="text-[#FF0000]">
                                {Number(
                                  profitusd * priceResponse.profit_amount
                                ).toFixed(4)}
                              </span>
                            )
                          ) : (
                            <span className="text-[#1EEF32]">0</span>
                          )}
                        </span>
                      </p>
                      <p className="text-white text-sm font-bold flex text-md justify-between">
                        AAVE Fee:<span className="text-[#1EEF32]">0.05%</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:w-[50%] bg-gradient-to-b from-[#F98736]/35 to-[#E43875]/35 rounded-[20px] py-3 p-2">
                  <div className="flex space-x-2 px-3">
                    <img className="w-5" src="assets/Sparkle.svg" />
                    <h1 className="font-bold lg:text-xl text-lg font-Artemus">
                      User Stats
                    </h1>
                  </div>
                  <div className="sm:flex space-y-5 sm:space-y-0 sm:px-5 pt-5">
                    <div className="sm:w-[50%] space-y-5 px-5 sm:border-r-2 border-white/15">
                      <p className="text-white font-bold flex text-sm justify-between">
                        Total Trades:<span>{UserTransactionCount ?? 0}</span>
                      </p>
                      <p className="text-white font-bold flex text-sm justify-between truncate">
                        Total Profit:
                        <span>$ {UserProfitAmount ? Number(UserProfitAmount).toFixed(4) : 0.000}</span>
                      </p>
                      <p className="text-white font-bold flex text-sm justify-between truncate">
                        Total Volume:
                        <span>$ {parseFloat(UserBorrowedAmount).toFixed(4) ?? 0}</span>
                      </p>
                    </div>
                    <div className="sm:w-[50%] space-y-5 px-5">
                      <p className="text-white font-bold flex text-sm justify-between">
                        Total Trade limit:
                        <span className={`text-${userStats?.TotalTradeLimit === 0 ? 'red-500' : 'green-500'} font-bold`}>$ {userStats?.TotalTradeLimit === 0 ? userStats?.TotalTradeLimit : `${userStats?.TotalTradeLimit}`}</span></p>
                      <p className="text-white font-bold flex text-sm justify-between truncate">
                        Limit Left:
                        <span>$ {userStats?.LimitRemaining ? Number(userStats.LimitRemaining).toFixed(4) : 0}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 w-[100%] bg-gradient-to-b from-[#F98736]/35 to-[#E43875]/35 rounded-[20px] p-3">
                <div className="flex space-x-3">
                  <img src="assets/Sparkle.svg" className="w-5" />
                  <p className=" font-bold lg:text-xl text-lg font-Artemus">
                    AI Suggestions
                  </p>
                </div>

                <div className="mt-3 flex flex-col md:flex-row md:space-x-5">
                  <div className="md:w-[50%] flex flex-col justify-center items-center">
                    <div>
                      <p className="text-[#1EEF32] md:text-lg font-bold underline">
                        Buy
                      </p>
                    </div>

                    <div className="flex flex-col md:flex-row md:gap-3 gap-0">
                      <div className="md:w-[50%] flex mt-3 p-3 items-center justify-between bg-gradient-to-b rounded-2xl from-[#E43875]/20">
                        <img
                          src={`assets/${priceResponse?.firstBestBuyDex === "Open Ocean"
                            ? "opean.png"
                            : priceResponse?.firstBestBuyDex === "0x"
                              ? "0x.png"
                              : priceResponse?.firstBestBuyDex === "One Inch"
                                ? "1inch.svg"
                                : priceResponse?.firstBestBuyDex === "Para Swap"
                                  ? "paraswap.svg"
                                  : "1inch.svg"
                            }`}
                          className="w-4/12 flex-shrink-0"
                        />
                        <img
                          src="assets/Yes.png"
                          className="w-3/12 flex-shrink-0"
                        />
                        <p
                          className={
                            priceResponse?.firstBestBuyAmount < 0
                              ? "text-[#FF1313]"
                              : "text-[#1EEF32]"
                          }
                        >
                          {priceResponse?.firstBestBuyAmount
                            ? priceResponse?.firstBestBuyAmount
                            : 0.0}
                        </p>
                      </div>
                      <div className="md:w-[50%] flex mt-3 p-3 items-center justify-between bg-gradient-to-b rounded-2xl from-[#E43875]/20">
                        <img
                          src={`assets/${priceResponse?.secondBestBuyDex === "Open Ocean"
                            ? "opean.png"
                            : priceResponse?.secondBestBuyDex === "0x"
                              ? "0x.png"
                              : priceResponse?.secondBestBuyDex === "One Inch"
                                ? "1inch.svg"
                                : priceResponse?.secondBestBuyDex === "Para Swap"
                                  ? "paraswap.svg"
                                  : "paraswap.svg"
                            }`}
                          className="w-4/12 flex-shrink-0"
                        />
                        <img
                          src="assets/Growth.svg"
                          className="w-3/12 flex-shrink-0"
                        />
                        <p
                          className={
                            priceResponse?.secondBestBuyAmount < 0
                              ? "text-[#FF1313]"
                              : "text-[#1EEF32]"
                          }
                        >
                          {priceResponse?.secondBestBuyAmount
                            ? priceResponse?.secondBestBuyAmount
                            : 0.0}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="md:w-[50%] flex flex-col justify-center items-center">
                    <div>
                      <p className="text-[#FF1313] md:text-lg font-bold underline">
                        Sell
                      </p>
                    </div>

                    <div className="flex flex-col md:flex-row md:gap-3 gap-0">
                      <div className="md:w-[50%] flex mt-3 p-3 items-center justify-between bg-gradient-to-b rounded-2xl from-[#E43875]/20">
                        <img
                          src={`assets/${priceResponse?.firstBestSellDex === "Open Ocean"
                            ? "opean.png"
                            : priceResponse?.firstBestSellDex === "0x"
                              ? "0x.png"
                              : priceResponse?.firstBestSellDex === "One Inch"
                                ? "1inch.svg"
                                : priceResponse?.firstBestSellDex === "Para Swap"
                                  ? "paraswap.svg"
                                  : "1inch.svg"
                            }`}
                          className="w-4/12 flex-shrink-0"
                        />
                        <img
                          src="assets/Yes.png"
                          className="w-3/12 flex-shrink-0"
                        />
                        <p
                          className={
                            priceResponse?.firstBestSellAmount < 0
                              ? "text-[#FF1313]"
                              : "text-[#1EEF32]"
                          }
                        >
                          {priceResponse?.firstBestSellAmount
                            ? priceResponse?.firstBestSellAmount
                            : 0.0}
                        </p>
                      </div>
                      <div className="md:w-[50%] flex mt-3 p-3 items-center justify-between bg-gradient-to-b rounded-2xl from-[#E43875]/20">
                        <img
                          src={`assets/${priceResponse?.secondBestSellDex === "Open Ocean"
                            ? "opean.png"
                            : priceResponse?.secondBestSellDex === "0x"
                              ? "0x.png"
                              : priceResponse?.secondBestSellDex === "One Inch"
                                ? "1inch.svg"
                                : priceResponse?.secondBestSellDex === "Para Swap"
                                  ? "paraswap.svg"
                                  : "paraswap.svg"
                            }`}
                          className="w-4/12 flex-shrink-0"
                        />
                        <img
                          src="assets/Growth.svg"
                          className="w-3/12 flex-shrink-0"
                        />
                        <p
                          className={
                            priceResponse?.secondBestSellAmount < 0
                              ? "text-[#FF1313]"
                              : "text-[#1EEF32]"
                          }
                        >
                          {priceResponse?.secondBestSellAmount
                            ? priceResponse?.secondBestSellAmount
                            : 0.0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mt-5">
                <div className="overflow-hidden overflow-x-scroll pb-5 relative bg-gradient-to-b from-[#F98736]/35 to-[#E43875]/35 rounded-[20px]">
                  <div className=" flex flex-row mt-5 space-x-3 px-5">
                    <img className="w-5" src="assets/Sparkle.svg" />
                    <h1 className="md:text-xl font-bold font-Artemus">Top Traders</h1>
                  </div>

                  <table class="w-[100%]  table-auto mt-2 ">
                    <tr className=" text-gray-300 ">
                      <th className="px-10 py-2 font-bold text-base">Transaction ID </th>
                      <th className="px-10 py-2 font-bold text-base">Pairs</th>
                      <th className="px-10 py-2 font-bold text-base">Profit</th>
                    </tr>

                    <tbody className="text-xs">
                      {topTenTransactions.length > 0 ? (
                        topTenTransactions.map((transaction, index) => (
                          <tr key={index} className="text-center">
                            <td className="px-10 py-2">
                              <a
                                href={`https://polygonscan.com/tx/${transaction.trxHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-[#1EEF32] hover:underline"
                              >
                                {transaction.trxHash}
                              </a>
                            </td>
                            <td className="font-bold py-2">
                              {transaction.pair1 ? transaction.pair1 : 'USDC'} - {transaction.pair2 ? transaction.pair2 : 'WMATIC'}
                            </td>
                            <td className="text-[#1EEF32] px-10 font-bold py-2">
                              {Number(transaction.profitPercentage).toFixed(2)}%
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-center py-2">No transactions</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div> */}

              {FetchingPricesSpinner ? <Spinners /> : ""}
              {TransactionSpinner ? <TranSpinners /> : ""}
              {FetchingDatasSpinner ? <DataSpinners /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Dapp;

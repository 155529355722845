import React, { useState, useEffect } from "react";
import "../App.css";
import Header from "./Header";
import { ethers } from "ethers";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import TranSpinners from "../TransSpinner";
import { NODE_URL } from "../config";


const TradingWallet = () => {

  const [selectedOption, setSelectedOption] = useState("");
  const [action, setAction] = useState(false);
  const [dummyAddress, setDummyAddress] = useState("");
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(false);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  useEffect(() => {
    if (address !== undefined && isConnected !== false) {
      handleCheckAddress(address);
    }
  }, [address, isConnected]);

  const handleCheckAddress = async (address) => {
    try {
      const response = await axios.get(`${NODE_URL}:3010/apiv10/getDummyAddress?address=${address}`);
      setDummyAddress(response.data.users);
    } catch (error) {
      console.error("Error checking address", error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(dummyAddress).then(() => {
      toast.success("Address copied to clipboard");
    })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  const fetchNativeBalance = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(walletProvider);
      const balance = await provider.getBalance(dummyAddress);
      const currentBalance =
        balance.toString() / ethers.constants.WeiPerEther;
      setBalance(currentBalance);
    } catch (error) {
      console.error("Unable to get balance:", error);
      setBalance(null);
    }
  };

  const withdraw = async (amount) => {
    if (Number(selectedOption) > balance) {
      toast.error('Entered Amount is greater than balance');
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(`${NODE_URL}:3010/apiv10/withdraw`, { address, amount: Number(selectedOption) });
      console.log(response);
      if (response.status === 200) {
        toast.success('Withdrawal successful');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error('Withdrawal failed. Try again');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error withdrawing:", error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dummyAddress !== "" && address !== undefined) {
      fetchNativeBalance();
    }
  }, [dummyAddress, address]);

  const handleSelectChange1 = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSingle = () => {
    setAction(true);
    localStorage.setItem("selectedAction", "single");
  };

  const handleBatch = () => {
    setAction(false);
    localStorage.setItem("selectedAction", "batch");
  };


  return (
    <div className=" text-white font-poppins">
      <div
        className="bg-cover bg-center bg-no-repeat min-h-screen"
        style={{ backgroundImage: "url('assets/bg.jpeg')" }}
      >
        <Header />
        <Toaster
          toastOptions={{
            position: "top-center",
          }}
        />
        {loading && <TranSpinners />}
        <div className="flex justify-end w-[90%] mx-auto">
          <div className=" flex px-3 pr-5 rounded-2xl bg-[#E4387536]/20 border-[2px] border-[#E4387536] bg-opacity-0 border-opacity-40">
            <div>
              <img src="/assets/image-12@2x.png" className="w-[5rem]"></img>
            </div>
            <div className="flex justify-center items-center pt-2">
              <p className="">
                Matic Balance :<p className="text-purple-200 font-bold">{balance}</p>
              </p>
            </div>
          </div>
        </div>
        <div className="container mx-auto block h-full pb-20">
          <div className="block w-full h-full ">
            <div className="md:pt-5 pt-10">
              <ul className="flex flex-row flex-wrap w-full md:text-sm text-xs  justify-center items-center">
                <li className="transition-all duration-300 ease-in-out">
                  <button
                    className={`font-bold py-1 px-4 mx-4 rounded-lg duration-500 ${action
                      ? "bg-[#E438753696]/20 border-[2px] border-[#E4387536] bg-opacity-0 border-opacity-40 hover:border-opacity-100"
                      : "border-[2px] border-transparent hover:border-[#E4387536] border-opacity-20"
                      }`}
                    onClick={handleSingle}
                  >
                    Deposit
                  </button>
                </li>
                <li className="transition-all duration-300 ease-in-out">
                  <button
                    className={`font-bold py-1 px-4 mx-4 rounded-lg duration-500 ${action
                      ? "border-[2px] border-transparent hover:border-[#E4387536] border-opacity-20"
                      : "bg-[#E438753696]/20 border-[2px] border-[#E4387536] bg-opacity-0 border-opacity-40 hover:border-opacity-100"
                      }`}
                    onClick={handleBatch}
                  >
                    Withdraw
                  </button>
                </li>
              </ul>
            </div>

            {action ? (
              <div>
                <div className=" pt-7">
                  <div className="p-[2px] rounded-2xl   text-white md:mx-auto mx-5 bg-opacity-45 sm:w-[25rem] bg-[#E4387536]" >
                    <div className="h-full   via-[#2d0f42]  to-black p-2 rounded-2xl block md:px-10 px-3 py-7">
                      <div className="flex w-full justify-center items-center">
                        <span className="object-contain object-center">
                          <img
                            src="assets/whitelist_head.png"
                            alt="login"
                            className="md:w-[4rem] w-[3rem] md:height-[4rem] height-[3rem]"
                          />
                        </span>
                      </div>
                      <h4 className="w-full text-center font-bold pt-4 text-sm mb-4">
                        {" "}
                        Deposit
                      </h4>

                      <div className="flex flex-col gap-5 items-center mt-5 text-center">
                        <img
                          src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${dummyAddress}`}
                          className="md:w-7/12 w-5/12"
                          alt="QR Code"
                        />
                        <p>Scan this QR code</p>
                      </div>

                      <div className="flex flex-col w-full text-left">
                        <label
                          htmlFor="walt-address"
                          className="my-2 text-white text-xs"
                        ></label>
                        <div className="flex items-center justify-center text-center">
                          <input
                            name="walt-link"
                            id="walt-address"
                            className="bg-[#FFFFFFD1] px-8 text-xs border-0 outline-0 rounded-md py-1 text-black sm:mr-2 mr-1"
                            value={dummyAddress}
                            readOnly
                          />
                          <button onClick={copyToClipboard}>
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </div>
                      </div>

                      <p className="mt-4 text-white  text-[11px] w-full text-center">
                        By scaning get your wallet address to deposit funds
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className=" pt-7">
                  <div className="p-[2px] rounded-2xl   text-white md:mx-auto mx-5 bg-opacity-45 sm:w-[25rem] bg-[#E4387536]">
                    <div className="h-full  p-2 rounded-2xl block md:px-10 px-3 py-7">
                      <div className="flex w-full justify-center items-center">
                        <span className="object-contain object-center">
                          <img
                            src="assets/whitelist_head.png"
                            alt="login"
                            className="w-[4rem] height-[4rem]"
                          />
                        </span>
                      </div>
                      <h4 className="w-full text-center font-bold pt-4 text-sm mb-4">
                        Withdraw
                      </h4>

                      <div className="flex flex-col w-full text-left">
                        <label
                          htmlFor="walt-address"
                          className="my-2 text-white text-xs"
                        >
                          Enter the Withdraw amount
                        </label>
                        <input
                          type="text"
                          name="walt-link"
                          id="walt-address"
                          className="mb-4 bg-[#FFFFFFD1] px-3 text-xs border-0 outline-0 rounded-md text-black py-1"
                          onChange={handleSelectChange1}
                        />
                      </div>
                      <div
                        className="mt-12 flex justify-center items-center"
                        onClick={withdraw}
                      >
                        <button
                          type="button"
                          className="bg-[#0075ff] text-white font-bold text-center md:px-16 px-7 py-2 w-full md:mx-10 rounded-lg md:text-xs text-[10px] leading-1"
                        >
                          Withdraw
                        </button>

                      </div>
                      <p className="mt-4 text-white text-[11px] w-full text-center">
                        By clicking Withdraw, You agree to Withdraw amount.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingWallet;
import React, { useState, useEffect } from "react";
import "../App.css";
import AdminHeader from "./AdminHeader";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { ethers } from "ethers";
import axios from "axios";
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { polygon_flashloan_address } from "../Network_Address.js";
import { polygon_flashloan_abi } from "../Network_Abi.js";
import TranSpinners from "../TransSpinner";
import { NODE_URL, ADMIN_ADDRESS } from "../config.js";

const White = () => {

  const [selectedOption, setSelectedOption] = useState("");
  const [PlanType, setPlanType] = useState("");
  const [planValue, setPlanValue] = useState("");
  const [action, setAction] = useState(false);
  const [files, setFiles] = useState(null);
  const [BatchList, setBatchList] = useState("");
  const [EnteredAddress, setEnteredAddress] = useState(null);
  const [TransactionSpinner, setTransactionSpinner] = useState(false);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  console.log(address, chainId, isConnected);

  useEffect(() => {
    const savedAction = localStorage.getItem("selectedAction");
    if (savedAction) {
      setAction(savedAction === "single");
    }
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );

    if (selectedOption) {
      setPlanType(selectedOption.value);
      setPlanValue(selectedOption.licenseValue);
    }
  };

  const handleWalletAddress = (e) => {
    console.log(e.target.value);
    setEnteredAddress(e.target.value);
  };

  const handleSingle = () => {
    setAction(true);
    localStorage.setItem("selectedAction", "single");
  };

  const handleBatch = () => {
    setAction(false);
    localStorage.setItem("selectedAction", "batch");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setFiles(e.dataTransfer.files);
  };

  useEffect(() => {
    const savedFiles = localStorage.getItem("selectedFiles");
    if (savedFiles) {
      setFiles(JSON.parse(savedFiles));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedFiles", JSON.stringify(files));
  }, [files]);

  const options = [
    { value: "license1", name: "License 1 ($1500)", licenseValue: 1 },
    { value: "license2", name: "License 2 ($3000)", licenseValue: 2 },
    { value: "license3", name: "License 3 ($10000)", licenseValue: 3 },
    { value: "license4", name: "License 4 ($20000)", licenseValue: 4 },
    { value: "license5", name: "License 5 ($50000)", licenseValue: 5 },
  ];

  const addUser = async () => {
    try {
      setTransactionSpinner(true);
      const Enteredaddress = EnteredAddress;

      if (!PlanType) {
        toast.error("Please Choose License Type");
        setTransactionSpinner(false);
        return false;
      }
      if (!address) {
        toast.error("Please Connect Your Wallet");
        setTransactionSpinner(false);
        return false;
      }
      if (!Enteredaddress) {
        toast.error("Please Enter Wallet Address");
        setTransactionSpinner(false);
        return false;
      }
      try {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          polygon_flashloan_address,
          polygon_flashloan_abi,
          signer
        );

        let checkAdmin = await checkAdminRole();

        if (checkAdmin === false) {
          toast.error("You Are not a Admin User to Whitelist");
          setTransactionSpinner(false);
          return false;
        }
        const checkWhite = await checkUserRole(Enteredaddress);
        console.log(checkWhite, "checkwhite");

        if (checkWhite === false) {
          console.log("New User");
          console.log(Enteredaddress, planValue);

          const AddWhiteList = await contract.whitelist(Enteredaddress, planValue,
            {
              gasPrice: ethers.utils.parseUnits("100", "gwei"),
              gasLimit: 2100000,
            }
          );

          const result = await AddWhiteList.wait();
          console.log(result, "result");

        } else {
          console.log("Old User");
          const AddWhiteList = await contract.whitelist(Enteredaddress, planValue,
            {
              gasPrice: ethers.utils.parseUnits("100", "gwei"),
              gasLimit: 2100000,
            }
          );

          const result = await AddWhiteList.wait();
          console.log(result, "Update whitelist result");
        }

        const whiteListUser = await axios.get(`${NODE_URL}:3006/apiv4/WhiteListUser?walletAddress=${Enteredaddress}&plan=${PlanType}&chainId=${chainId}`);
        console.log("New transaction added with key:", whiteListUser);

        if (whiteListUser.data.status === "success") {
          toast.success("WhiteList Plan for User updated successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error("Failed...Please try agin later");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        setTransactionSpinner(false);
      } catch (err) {
        console.log(err, "error");
        toast.error("Failed...Please try agin later");
        setTransactionSpinner(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed...Please try agin later");
      setTransactionSpinner(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const checkAdminRole = async () => {
    console.log(address === ADMIN_ADDRESS, address, ADMIN_ADDRESS)
    if (address === ADMIN_ADDRESS) {
      return true;
    } else {
      return false;
    }
  }

  const checkUserRole = async (Enteredaddress) => {
    try {
      let checkUser = await axios.get(`${NODE_URL}:3006/apiv4/checkWhiteList?walletAddress=${Enteredaddress}`)
      if (checkUser.data.status === 'success') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getList = async (e) => {
    try {
      e.preventDefault();
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        const maxSize = 10 * 1024 * 1024;
        if (file.size > maxSize) {
          toast.error("File size exceeds 10MB.");
          return;
        }
        const allowedExtensions = [".xls", ".xlsx", ".csv"];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (!allowedExtensions.includes(`.${fileExtension}`)) {
          toast.error(
            "Invalid file extension. Allowed extensions: xls, xlsx, csv"
          );
          return;
        }
        console.log("File is valid:", file);
        toast.success("File Uploaded");
        setBatchList(file);
      } else {
        toast.error("No file selected.");
        return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const BatchWhitelist = async () => {
    try {
      setTransactionSpinner(true);

      $("#payBtn").prop("disabled", true).css("cursor", "not-allowed");
      if (!BatchList) {
        toast.error("List Not Uploaded..Please Upload");
        $("#payBtn").prop("disabled", false).css("cursor", "pointer");
        setTransactionSpinner(false);

        return;
      }

      const provider = new ethers.providers.Web3Provider(walletProvider);
      const signer = provider.getSigner();

      const hasRoleContract = new ethers.Contract(
        polygon_flashloan_address,
        polygon_flashloan_abi,
        signer
      );
      const checkAdmin = await hasRoleContract.hasRole(
        "0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775",
        address
      );
      if (!checkAdmin) {
        setTransactionSpinner(false);

        toast.error("You Are not a Admin User to Whitelist");
        setTransactionSpinner(false);
        return false;
      }
      const formData = new FormData();
      formData.append("List", BatchList);
      const response = await axios.post(
        "http://localhost:3006/apiv4/BatchWhitelist",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      let newBatch = [];
      let newBatchLicense = [];
      let EditBatch = [];
      let EditBatchLicense = [];
      let CombinedEditBatch = [];
      let CombinedNewBatch = [];
      const newBatchSet = new Set();

      const contract = new ethers.Contract(
        polygon_flashloan_address,
        polygon_flashloan_abi,
        signer
      );
      if (response.data.status === "success") {
        const listArray = response.data.data;
        if (listArray.length > 200) {
          $("#payBtn").prop("disabled", false).css("cursor", "pointer");
          toast.error("Max Upload Size is 200 Users Only");
          setTransactionSpinner(false);

          return false;
        }
        toast.loading("Please Wait While Transaction is in Progress", {
          duration: 1500,
        });
        for (const listItem of listArray) {
          console.log(listItem);
          if (!listItem.USERADDRESS || !listItem.LICENSETYPE) {
            toast.error("Details Missing in Uploaded File");
            $("#payBtn").prop("disabled", false).css("cursor", "pointer");
            setTransactionSpinner(false);

            return false;
          }
          const isUserWhitelisted = await checkUserRole(listItem.USERADDRESS);
          console.log(isUserWhitelisted, "isUserWhitelisted");

          if (isUserWhitelisted == 0) {
            if (!newBatchSet.has(listItem.USERADDRESS)) {
              newBatchSet.add(listItem.USERADDRESS);
              newBatch.push(listItem.USERADDRESS);
              newBatchLicense.push(
                listItem.LICENSETYPE == "A"
                  ? 1
                  : listItem.LICENSETYPE == "B"
                    ? 2
                    : listItem.LICENSETYPE == "C"
                      ? 3
                      : listItem.LICENSETYPE == "D"
                        ? 4
                        : 5
              );
            }
          } else {
            EditBatch.push(listItem.USERADDRESS);
            EditBatchLicense.push(
              listItem.LICENSETYPE == "A"
                ? 1
                : listItem.LICENSETYPE == "B"
                  ? 2
                  : listItem.LICENSETYPE == "C"
                    ? 3
                    : listItem.LICENSETYPE == "D"
                      ? 4
                      : 5
            );
          }
        }

        for (let i = 0; i < EditBatch.length; i++) {
          CombinedEditBatch.push({
            userAddress: EditBatch[i],
            plan: "license" + EditBatchLicense[i],
          });
        }

        for (let i = 0; i < newBatch.length; i++) {
          CombinedNewBatch.push({
            userAddress: newBatch[i],
            plan: "license" + newBatchLicense[i],
          });
        }

        console.log(CombinedEditBatch, CombinedNewBatch, "new and Edit");

        console.log(newBatchLicense, EditBatchLicense, "new and Edit");
        let NewBatchSavedStatus;
        if (newBatch.length > 0 && newBatchLicense.length > 0) {
          const gas = await contract.estimateGas.batchWhitelist(
            newBatch,
            newBatchLicense,
            { gasLimit: 500000000000 }
          );
          const newWhitelist = await contract.batchWhitelist(
            newBatch,
            newBatchLicense,
            { gasLimit: parseInt(gas) }
          );
          const checked = await newWhitelist.wait();
          if (checked.status == 1 && checked.transactionHash) {
            const whiteListUser = await axios.post(
              `http://localhost:3006/apiv4/WhiteListUsersBatch`,
              {
                user: CombinedNewBatch,
                chainId: chainId,
              }
            );
            console.log("New transaction added with key:", whiteListUser.data);
            if (whiteListUser.data.status === "success") {
              NewBatchSavedStatus = 1;
            }
          } else {
            toast.error("Transaction Failed...Try again Later");
            setTransactionSpinner(false);

            setTimeout(() => {
              window.location.reload()
            }, 700);
          }
        }
        let EditBatchSavedStatus;
        if (EditBatch.length > 0 && EditBatchLicense.length > 0) {
          const upgradeGas =
            await contract.estimateGas.batchUpgradeUserTradeLimit(
              EditBatch,
              EditBatchLicense,
              { gasLimit: 500000000000 }
            );
          const ReWhitelist = await contract.batchUpgradeUserTradeLimit(
            EditBatch,
            EditBatchLicense,
            { gasLimit: parseInt(upgradeGas) }
          );
          const checkedRe = await ReWhitelist.wait();
          console.log(checkedRe);
          if (checkedRe.status == 1 && checkedRe.transactionHash) {
            const whiteListUser = await axios.post(
              `http://localhost:3006/apiv4/WhiteListUsersBatch`,
              {
                user: CombinedEditBatch,
                chainId: chainId,
              }
            );
            console.log("New transaction added with key:", whiteListUser.data);
            if (whiteListUser.data.status == "success") {
              EditBatchSavedStatus = 1;
            }
          } else {
            toast.error("Transaction Failed...Try again Later");
            setTransactionSpinner(false);

            setTimeout(() => {
              window.location.reload()
            }, 700);
          }
        }
        console.log(NewBatchSavedStatus, EditBatchSavedStatus, "status");
        if (NewBatchSavedStatus == 1 || EditBatchSavedStatus == 1) {
          toast.success("Whitelisting Users Updated Successfully");
          setTransactionSpinner(false);

          setTimeout(() => {
            window.location.reload()
          }, 700);
        } else {
          toast.error("Transaction Failed...Try again Later");
          setTransactionSpinner(false);

          setTimeout(() => {
            window.location.reload()
          }, 700);
        }
      } else {
        toast.error("Something went wrong..Transaction Failed");
        setTransactionSpinner(false);

        setTimeout(() => {
          //window.location.reload();
        }, 700);
      }
    } catch (err) {
      toast.error("Something went wrong..Transaction Failed");
      setTransactionSpinner(false);

      setTimeout(() => {
        //window.location.reload();
      }, 700);
      console.error(err);
    }
  };

  return (
    <div className=" text-white font-poppins">
      <div
        className="bg-cover bg-center bg-no-repeat bg-black min-h-screen"
        style={{ backgroundImage: `url(assets/bg.jpeg)` }}
      >
        <div className="">
          <AdminHeader />
          <Toaster toastOptions={{ position: "top-center", }} />
          <div className="md:absolute md:bottom-0 md:right-0 md:w-[25rem] md:h-[25rem]  md:filter md:blur-[7rem]  md:bg-[#E43875A3]/15 md:rounded-full"></div>
          <div className="md:absolute md:top-20 md:-left-20 md:w-[25rem] md:h-[28rem]  md:filter md:blur-3xl  md:bg-[#E43875A3]/15 md:rounded-full"></div>

          <div className="container mx-auto block h-full pb-20">
            <div className="block w-full h-full mx-auto">
              {/* <div className="md:pt-5 pt-10">
                <ul className="flex flex-row flex-wrap w-full md:text-sm text-xs  justify-center items-center">
                  <li className="transition-all duration-300 ease-in-out">
                    <button
                      className={`font-bold py-1 px-4 mx-4 rounded-lg ${action
                        ? "bg-[#E4387536]/20 border-[2px] border-[#E4387536] bg-opacity-0 border-opacity-40 hover:border-opacity-100"
                        : "border-[2px] border-transparent hover:border-[#E4387536] border-opacity-20"
                        }`}
                      onClick={handleSingle}
                    >
                      Single
                    </button>
                  </li>
                  <li className="transition-all duration-300 ease-in-out">
                    <button
                      className={`font-bold py-1 px-4 mx-4 rounded-lg ${action
                        ? "border-[2px] border-transparent hover:border-[#E4387536] border-opacity-20"
                        : "bg-[#E4387536]/20 border-[2px] border-[#E4387536] bg-opacity-0 border-opacity-40 hover:border-opacity-100"
                        }`}
                      onClick={handleBatch}
                    >
                      Batch
                    </button>
                  </li>
                </ul>
              </div>
              {action ? ( */}
              <div className="mt-10 pt-7">
                <div className="p-[2px] rounded-2xl text-white md:mx-auto mx-5 bg-[#E4387536] sm:w-[25rem]">
                  <div className="h-full p-2 rounded-2xl block md:px-10 px-3 py-7">
                    <div className="flex w-full justify-center items-center">
                      <span className="object-contain object-center">
                        <img
                          src="assets/whitelist_head.png"
                          alt="login"
                          className="w-[4rem] height-[4rem]"
                        />
                      </span>
                    </div>
                    <h4 className="w-full text-center font-bold pt-4 text-sm mb-4">
                      Enter the walletAddress to Whitelist
                    </h4>
                    <div className="flex flex-col w-full text-left">
                      <label
                        htmlFor="walt-address"
                        className="my-2 text-white text-xs"
                      >
                        Wallet Address
                      </label>
                      <input
                        type="text"
                        name="walt-link"
                        id="walt-address"
                        className="mb-4 bg-[#FFFFFFD1] px-3 text-xs border-0 outline-0 rounded-md text-black py-2"
                        onChange={handleWalletAddress}
                      />
                    </div>
                    <div className="flex flex-col w-full text-left">
                      <p className="text-xs">Choose Plan</p>
                      <select
                        className="bg-[#e4387582] text-white focus:outline-none text-xs border-solid mt-2 py-2 px-3 rounded-md w-full font-semibold"
                        onChange={handleSelectChange}
                        value={selectedOption?.value}
                      >
                        <option
                          className="bg-[#E4387536] text-black text-xs flex justify-center items-center font-semibold"
                          value=""
                        >
                          Select Plan
                        </option>
                        {options.map((option, index) => (
                          <option
                            key={index}
                            value={option.value}
                            className="bg-white text-black flex justify-center items-center"
                          >
                            {option.name}{" "}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mt-12 flex justify-center items-center">
                      <button
                        type="button"
                        className="bg-[#E43875A3] text-white font-bold text-center md:px-16 px-7 py-2 w-full md:mx-10 rounded-lg md:text-xs text-[10px] leading-1"
                        onClick={addUser}
                      >
                        Whitelist This User
                      </button>
                    </div>
                    <p className="mt-4 text-white md:text-xs text-[9px] w-full text-center">
                      By clicking Whitelist, You agree to Whitelist User
                    </p>
                  </div>
                </div>
              </div>
              {/* ) : (
                <div className=" pt-7">
                  <div className="p-[2px] rounded-2xl bg-[#E4387536] text-white md:mx-auto mx-5 sm:w-[25rem]">
                    <div className="h-full p-2 rounded-2xl block md:px-10 px-3 py-7">
                      <div className="flex w-full justify-center items-center">
                        <span className="object-contain object-center">
                          <img
                            src="assets/whitelist_head.png"
                            alt="login"
                            className="w-[4rem] height-[4rem]"
                          />
                        </span>
                      </div>
                      <h4 className="w-full text-center font-bold pt-4 text-sm mb-4">
                        Batch Whitelisting
                      </h4>

                      {!files && (
                        <label
                          htmlFor="file-upload"
                          className="flex flex-col justify-center items-center border border-dashed p-5 border-white/25 mt-5 rounded-xl "
                          role="button"
                          onDragOver={handleDragOver}
                          onDrop={handleDrop}
                        >
                          <h1 className="mt-3 text-xs">
                            Drag and drop files to upload
                          </h1>
                          <h1 className="md:my-10 my-5 text-xs">OR</h1>
                          <span className=" text-xs">Browse</span>
                          <input
                            id="file-upload"
                            type="file"
                            className="hidden"
                            onChange={(e) => getList(e)}
                          />
                        </label>
                      )}

                      {files && (
                        <div className="text-center">
                          <ul className="mt-10">
                            {Array.from(files).map((file, index) => (
                              <li key={index} className="text-xs">
                                {file.name}
                              </li>
                            ))}
                          </ul>

                          <div className="">
                            <button
                              onClick={() => setFiles(null)}
                              className="text-xs"
                            >
                              clear{" "}
                            </button>
                          </div>

                          <p className="mt-14 text-white text-xs w-full text-center">
                            By clicking Batch Whitelist, You agree to Whitelist
                            Batch of Users
                          </p>
                        </div>
                      )}

                      <button
                        onClick={BatchWhitelist}
                        className="bg-[#E43875A3] mt-5 w-full text-white font-bold text-center py-2 rounded-lg md:text-[0.75rem] text-[10px] leading-1"
                      >
                        Batch Whitelist
                      </button>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      {TransactionSpinner ? <TranSpinners /> : ""}
    </div>
  );
};

export default White;

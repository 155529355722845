import React, { useState, useEffect } from "react";
import "../App.css";
import Header from "./AdminHeader.js";
import axios from "axios";
import Chart from "react-apexcharts";
import { ImageComponent } from "../Img";
import DataSpinners from "../FetchDatas";
import { NODE_URL } from "../config.js";

const PlatformStats = () => {
  const [FetchingDatasSpinner, setFetchingDatasSpinner] = useState(false);
  const [PlatformTrades, setPlatformTrades] = useState(0);
  const [PlatformVolume, setPlatformVolume] = useState(0);
  const [PlatformProfit, setPlatformProfit] = useState(0);
  const [PlatformTransactions, setPlatformTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [graph, setGraph] = useState([]);

  const dateformat = async (date) => {
    const originalDate = new Date(date);
    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1;
    const year = originalDate.getUTCFullYear() % 100;
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const getPlatformTransactions = async () => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getTransactions`);
      const res = response.data.data;

      const transactions = await Promise.all(
        res.map(async (trans) => {
          const formattedDate = await dateformat(trans.createdAt);
          return {
            trxHash: trans.trxHash,
            date: formattedDate,
            token: trans.pair1,
            profit: Number(trans.profitPercentage),
            status: trans.status,
          };
        })
      );
      setPlatformTransactions(transactions);
      setFetchingDatasSpinner(false);
    } catch (error) {
      console.error("Error while fetching platform transactions: ", error);
      setFetchingDatasSpinner(false);
    }
  };

  const getPlatformStats = async () => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersCount`);
      const res = response.data;
      setPlatformTrades(res.transactionCount);
      setPlatformProfit(res.totalProfitUSD);
      setPlatformVolume(res.borrowAmount);
      setFetchingDatasSpinner(false);
    } catch (error) {
      console.error("Error while fetching Platform stats: ", error);
      setFetchingDatasSpinner(false);
    }
  };

  useEffect(() => {
    getPlatformTransactions();
    getPlatformStats();
    getDynamicData().then((transformedItem) => {
      setGraph(transformedItem);
    });
  }, []);

  const itemsPerPage = 10;
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = PlatformTransactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalPages = Math.ceil(PlatformTrades / itemsPerPage);

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  const getPreviousDays = () => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date().getDay();
    const previousDays = [];

    for (let i = today; i >= 0; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }

    for (let i = daysOfWeek.length - 1; i > today; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }
    return previousDays;
  };

  const getDynamicData = async () => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getTransactionsCountForPlatform`);
      const data = response.data;
      setFetchingDatasSpinner(false);
      return data;
    } catch (error) {
      console.error("Error fetching weekly data:", error);
      setFetchingDatasSpinner(false);
      return [];
    }
  };

  const state = {
    options: {
      colors: ["#F98736"],
      stroke: { width: 2, curve: "smooth" },
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: getPreviousDays(),
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        style: {
          background: "#9381FF",
        },
        theme: "dark",
        y: {
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
      },
    },

    series: [
      {
        name: "Transactions",
        data: graph,
      },
    ],
  };

  return (
    <div className=" text-white font-poppins ">
      <div
        className="w-full bg-black h-full"
        style={{ backgroundImage: `url(assets/ bg2.png)` }}>
        <Header />
        <div className="z-0 md:absolute md:bottom-0 md:right-0 md:w-[25rem] md:h-[25rem]  md:filter md:blur-[7rem]  md:bg-[#E43875]/10 md:rounded-full"></div>
        <div className="z-0 md:absolute md:top-20 md:-left-20 md:w-[25rem] md:h-[28rem]  md:filter md:blur-3xl  md:bg-[#E43875]/10 md:rounded-full"></div>
        <div className="md:px-5 px-7 mt-10 md:mt-5 pb-20 z-10">
          <div className="lg:flex lg:mx-10 justify-center space-y-5 lg:space-y-0 lg:space-x-10">
            <div className="w-full sm:w-6/12 lg:w-4/12  p-[2px] rounded-2xl  bg-[#e4387526]  text-white ">
              <div className="h-[8rem] py-3  p-2 rounded-2xl block ">
                <div className="flex justify-around h-full">
                  <div className="flex items-center w-[30%]">
                    <div className="w-[100%]">
                      <img src="./assets/Path 2.png" alt="graph" className="" />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div>
                      <h5 className="text-white font-bold lg:m-2 text-xs">
                        Total Trade
                      </h5>
                      <p className="text-white font-bold my-2 sm:text-lg xl:text-2xl">
                        {PlatformTrades ? PlatformTrades : 0}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span className="block p-2 rounded-full bg-[#00000040]">
                      <img
                        src="./assets/candle1.png"
                        alt="money"
                        className="w-7 h-7 object-cover"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-6/12 lg:w-4/12 p-[2px] rounded-2xl  bg-[#e4387526] text-white ">
              <div className="h-[8rem] py-3   p-2 rounded-2xl block ">
                <div className="flex justify-around h-full">
                  <div className="flex items-center w-[30%]">
                    <img src="./assets/Path 4.png" alt="graph" />
                  </div>
                  <div className="flex items-center">
                    <div>
                      <h5 className="text-white  font-bold lg:m-2 text-xs">
                        Profits Gained
                      </h5>
                      <p className="text-white  font-bold mt-2 sm:text-lg xl:text-2xl">
                        $ {PlatformProfit > 0 ? PlatformProfit : 0}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span className="block p-2 rounded-full bg-[#00000040]">
                      <img
                        src="./assets/wallet-money 1.png"
                        alt="money"
                        className="w-8 h-7 object-cover"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-6/12 lg:w-4/12 p-[2px] rounded-2xl  bg-[#e4387526] text-white">
              <div className="h-[8rem] py-3  p-2 rounded-2xl block ">
                <div className="flex justify-around h-full">
                  <div className="flex items-center w-[30%]">
                    <img src="./assets/graph2.png" alt="graph" className="" />
                  </div>
                  <div className="flex items-center">
                    <div>
                      <h5 className="text-white font-bold lg:m-2 text-xs">
                        Borrowed Amounts
                      </h5>
                      <p className="text-white font-bold mt-2 sm:text-lg xl:text-2xl">
                        $ {PlatformVolume > 0 ? PlatformVolume : 0}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span className="block p-2 rounded-full bg-[#00000040]">
                      <img
                        src="./assets/Icon Illustrations.png"
                        alt="money"
                        className="w-8 h-8 object-cover"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-full block mt-5  ">
            <div className=" md:mx-10 mx-0">
              <div className="p-[2px] rounded-2xl border border-[#E43875]/65">
                <div className=" md:p-7 p-4 text-black rounded-2xl">
                  <div className="flex w-full text-black items-center justify-between">
                    <h4 className="text-white text-sm font-bold">Overview</h4>
                  </div>
                  <div className="h-80 sm:h-96 md:h-128 lg:h-144 xl:h-[15rem] p-3">
                    <Chart
                      options={state.options}
                      series={state.series}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-full block mt-5">
            <div className="md:mx-10 ">
              <div className="w-full p-[2px] rounded-2xl bg-[#E4387526] text-white overflow-x-scroll lg:overflow-hidden">
                <div className="h-full py-3  p-2  rounded-2xl block overflow-x-scroll lg:overflow-hidden">
                  <table className="border-none w-full h-auto overflow-x-scroll lg:overflow-hidden">
                    <thead className="py-4">
                      <tr>
                        <th className="mx-2 text-white font-bold text-lg lg:text-2xl flex items-center gap-2">
                          <img
                            src="assets/Sparkle.svg"
                            className="w-5 h-5"
                            alt="taborr logo"
                          />
                          <p className="md:text-lg text-sm font-Artemus">
                            Transaction Hash
                          </p>
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Date
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Token
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Result
                        </th>
                        <th className="mx-2 text-white px-10 font-bold text-sm">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-xs">
                      {currentTransactions && currentTransactions.length > 0 ? (
                        currentTransactions.map((trans) => (
                          <tr key={trans.trxHash}>
                            <td className="font-bold px-10 text-sm py-3">
                              <a
                                href={`https://polygonscan.com/tx/${trans.trxHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-[#1EEF32] hover:underline"
                              >
                                {trans.trxHash}
                              </a>
                            </td>
                            <td className="font-bold px-10 text-sm">
                              {trans.date}
                            </td>
                            <td className="font-bold px-10 text-sm py-3 flex items-center justify-center">
                              <ImageComponent symbol={trans.token} /> &nbsp;
                              <p>{trans.token}</p>
                            </td>
                            <td className="font-bold px-10 text-sm">
                              <span style={{ color: trans.profit > 0 ? "#1EEF32" : "red", }}>
                                {trans.profit.toFixed(3)}%
                              </span>
                            </td>
                            <td
                              className="font-bold px-4 lg:px-10 text-sm py-2"
                              style={{ color: trans.status === "success" ? "#1EEF32" : "red", }}>
                              <p className="text-center text-xs">
                                {trans.status === "success"
                                  ? "Profitable Trade"
                                  : "Loss Blocked"}
                              </p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center py-8">
                            No transactions available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="flex justify-between mt-4 px-10 pt-3 text-xs">
                    <button
                      className="mr-20 hover:bg-[#E43875] rounded-lg text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875]"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous Page
                    </button>
                    <div className="flex items-center">
                      <p>{`Page ${currentPage} of ${totalPages}`}</p>
                    </div>
                    <button
                      className="ml-20 hover:bg-[#E43875] rounded-lg text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875]"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next Page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {FetchingDatasSpinner ? <DataSpinners /> : ""}
    </div>
  );
};
export default PlatformStats;

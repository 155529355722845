import React, { useState, useEffect } from "react";
import Header from "./Header";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import TranSpinners from "../TransSpinner";
import toast, { Toaster } from "react-hot-toast";
import PolygonPairs from "../Polygon_Network_Token_Pairs.js";
import { NODE_URL } from "../config.js";

export default function Pair() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [dummyAddress, setDummyAddress] = useState("");
  const [balance, setBalance] = useState(null);
  const [TransactionSpinner, setTransactionSpinner] = useState(false);
  const [pairs, setPairs] = useState([]);
  const [amount, setAmount] = useState([]);
  const [TrxMessage, setTrxMessage] = useState("");
  const [TrxHash, setTrxHash] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const fromValue = queryParams.get("from");
  const value = queryParams.get("amount");

  const fetchAIpairs = async (address) => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getAIPairs?walletAddress=${address}`);
      const data = response.data;
      setAmount(data.amount);
      if (data && data.pairs && data.pairs.length !== 0) {
        setPairs(data.pairs);
      }
    } catch (error) {
      console.error("Error while fetching: ", error);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      fetchAIpairs(address);
    }

    const fetchDataInterval = setInterval(() => {
      if (address !== undefined) {
        fetchAIpairs(address);
      }
    }, 1000);

    return () => {
      clearInterval(fetchDataInterval);
    };
  }, [address]);

  useEffect(() => {
    let idleTimer;

    const handlePageRefresh = async () => {
      try {
        await axios.post(`${NODE_URL}:3006/apiv4/resetPairs?walletAddress=${address}`);
      } catch (error) {
        console.error("Error resetting pairs:", error);
      }
    };

    const resetIdleTimer = () => {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        navigate("/Ai");
        handlePageRefresh();
      }, 60000);
    };

    resetIdleTimer();

    return () => {
      clearTimeout(idleTimer);
    };
  }, [navigate]);

  const handleCheckAddress = async (address) => {
    try {
      const response = await axios.get(`${NODE_URL}:3010/apiv10/getDummyAddress?address=${address}`);
      setDummyAddress(response.data.users);
    } catch (error) {
      console.error("Error checking address", error);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      handleCheckAddress(address);
    }
  }, [address, isConnected]);

  useEffect(() => {
    const fetchNativeBalance = async () => {
      try {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const balance = await provider.getBalance(dummyAddress);
        const currentBalance =
          balance.toString() / ethers.constants.WeiPerEther;
        setBalance(currentBalance);
      } catch (error) {
        console.error("Unable to get balance:", error);
        setBalance(null);
      }
    };
    if (dummyAddress !== "") {
      fetchNativeBalance();
    }
  }, [dummyAddress, chainId]);

  const handleTrade = async (pair) => {
    try {
      if (balance <= 0) {
        toast.error("Please check balance");
        setTimeout(() => {
          navigate("/wallet");
        }, 3000);
        return;
      }

      if (pair) {
        setTransactionSpinner(true);
        let pairs = PolygonPairs.tokenList;
        let dex = [];
        let from_address, to_address, from_decimal, to_decimal, from_symbol, to_symbol;

        let Token;
        if (pair.from_symbols === "USDC.e") {
          Token = "USDC";
        } else if (pair.from_symbols === "WBTC") {
          Token = "BTC";
        } else if (pair.from_symbols === "WMATIC") {
          Token = "MATIC";
        } else if (pair.from_symbols === "WETH") {
          Token = "ETH";
        } else {
          Token = pair.from_symbols;
        }
        const Pricedata = await axios.get(
          `https://min-api.cryptocompare.com/data/price?api_key=afb9c2ae7b1584cd4fdf5185d22982633f6ac3d5f3cdf109debfe8e307d2b940&fsym=${Token}&tsyms=USD`
        );
        const amount = value / Pricedata.data.USD;

        for (let i = 0; i < pairs.length; i++) {
          if (pairs[i].pair === `${pair.from_symbols}/${pair.to_symbol}`) {
            from_address = pairs[i].from_contract;
            to_address = pairs[i].to_contract;
            from_decimal = pairs[i].from_decimal;
            to_decimal = pairs[i].to_decimal;
            from_symbol = pairs[i].from;
            to_symbol = pairs[i].to;
            break;
          }
        }

        const dexMap = {
          "0x": 0,
          "One Inch": 1,
          "Open Ocean": 2,
          "Para Swap": 3,
        };

        dex[0] = dexMap[pair.from_dex];
        dex[1] = dexMap[pair.to_dex];

        const flashLoan = await axios.post(
          `${NODE_URL}:3010/apiv10/autodata`,
          {
            tokenIn: from_address,
            tokenOut: to_address,
            amount: amount.toFixed(5),
            from_decimal: from_decimal,
            to_decimal: to_decimal,
            dex: dex,
            from_symbol: from_symbol,
            to_symbol: to_symbol,
            address: address,
            buyCallData: pair.BuyCallData,
            SellCallData: pair.SellCallData,
            SellPrice: pair.sell_price,
            Profit_Amount: pair.Profit_Amount,
            Profit_Percentage:pair.Profit_Percentage
          }
        );

        if (flashLoan.data.code === "INSUFFICIENT_FUNDS") {
          setTrxMessage("Insufficient fund in the Wallet Adderss");
          console.log(flashLoan.data.code);
          setTransactionSpinner(false);
          toast.error("Insufficient fund in the Wallet Adderss");
        }
        if (flashLoan.data.argument === "profitValue") {
          setTrxMessage("Due to unfavorable market conditions, the trade has failed. Please try again");
          console.log(flashLoan.data.code);
          setTransactionSpinner(false);
          toast.error("Error during Transaction");
        }
        if (flashLoan.data.status === "failed") {
          setTrxMessage("Due to unfavorable market conditions, the trade has failed. Please try again");
          setTrxHash(flashLoan.data.hash);
          console.log(flashLoan.data);
          setTransactionSpinner(false);
          toast.error("Error during Transaction");
        }
        if (flashLoan.data.status === "success") {
          setTrxMessage("Transaction Successfull");
          setTrxHash(flashLoan.data.hash);
          console.log(flashLoan.data);
          setTransactionSpinner(false);
          toast.success("Transaction Successfull");
        }
        if (flashLoan.data === "failed") {
          setTrxMessage("Due to unfavorable market conditions, the trade has failed. Please try again");
          console.log(flashLoan.data);
          setTransactionSpinner(false);
        }
        setShowPopup(true);
        setTransactionSpinner(false);
      } else {
        console.warn("No pair selected.");
      }
    } catch (error) {
      setTransactionSpinner(false);
      console.error("catch Error trading :", error);
      setShowPopup(true);
      setTrxMessage("Something went Wrong. Please try again");
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const RedirectButton = () => {
    navigate("/ai");
  };

  const handleViewTransaction = () => {
    if (TrxHash) {
      window.open(
        `https://polygonscan.com/tx/${TrxHash}`,
        "_blank",
        "noopener,noreferrer"
      );
      navigate("/ai");
    }
  };

  return (
    <div className="text-white font-poppins overflow-hidden">
      <div
        className="h-full pb-20 2xl:pb-32 min-h-screen"
        style={{ backgroundImage: `url(assets/bg.jpeg)` }}
      >
        <Header />
        <Toaster toastOptions={{ position: "top-center", }} />
        <div>
          <img
            src="./assets/poppers.png"
            className="w-[10rem] md:w-[20rem] 2xl:w-[24rem] absolute"
            alt=""
          ></img>
          <img
            src="./assets/poppers.png"
            className="w-[10rem] md:w-[20rem] 2xl:w-[24rem] scale-x-[-1] absolute right-0"
            alt=""
          ></img>
        </div>
        <div className="md:absolute md:top-14 md:-left-48 md:w-[30rem] md:h-[30rem] md:filter md:blur-3xl  md:bg-[#E4387536] md:rounded-full"></div>
        <div className="">
          <div className="md:px-10 px-5 2xl:mt-12 md:mt-0 mt-10 pb-20">
            <div className="md:absolute md:-top-16 md:-right-10 md:w-[26rem] md:h-[26rem] md:filter md:blur-2xl   md:bg-[#E4387536] md:rounded-full "></div>
          </div>
          <p className="text-center font-bold text-[#979797] text-2xl md:text-3xl 2xl:[48px] tracking-widest">
            PAIRS
          </p>
          <div className="flex justify-center gap-8 flex-wrap mt-10">
            {pairs.length === 0 ? (
              <p>Fetching pairs...</p>
            ) : (
              pairs.map((pair, index) => (
                <button
                  key={index}
                  className="lg:w-[30%] md:w-[45%] sm:w-[50%] w-full sm:mx-0 mx-4 bg-[#E4387536] pt-5 px-5 pb-10 2xl:p-7 border-2 2xl:border-[7.5px] border-[#E4387536] rounded-2xl mb-8 md:mb-0 focus:outline-none focus:ring-2 focus:ring-[#E4387536] focus:ring-opacity-50 hover:border hover:border-1 hover:border-[#E4387536]/35 hover:shadow-md hover:shadow-[#E4387536]/35"
                >
                  <div className="bg-[#E4387536] w-full text-center rounded-t-md 2xl:rounded-t-xl border-b border-[#E4387536]">
                    <p className="pt-2 2xl:pt-4 pb-1 2xl:pb-2 flex justify-center font-bold 2xl:text-xl">
                      Pairs<p className="flex flex-col ml-4"></p>
                    </p>
                  </div>
                  <div className="flex text-sm 2xl:text-lg bg-[#E4387536] rounded-b-md 2xl:rounded-b-xl">
                    <div className="w-[50%] pt-2 2xl:pt-4 pb-3 2xl:pb-5 flex justify-center">
                      <p className="mt-1">{pair.from_symbols}</p>
                    </div>
                    <p className="flex flex-col ml-4 mt-3">
                      <i className="fa-solid fa-arrow-right-long"></i>
                      <i className="fa-solid fa-arrow-left-long -translate-x-2 -translate-y-2"></i>
                    </p>
                    <div className="w-[50%] pt-2 2xl:pt-4 pb-3 2xl:pb-5 flex justify-center">
                      <p className="mt-1">{pair.to_symbol}</p>
                    </div>
                  </div>
                  <div className="pt-2 2xl:pt-4 text-sm px-3 pb-3 2xl:pb-5 flex justify-between">
                    <p className="mt-1">
                      Buy Price: {parseFloat(pair.buy_price).toFixed(5)}
                    </p>
                    <p className="mt-1"> {pair.from_dex}</p>
                  </div>
                  <div className="pt-2 2xl:pt-4 text-sm pb-3 px-3 2xl:pb-5 flex justify-between">
                    <p className="mt-1">
                      Sell Price : {parseFloat(pair.sell_price).toFixed(5)}
                    </p>
                    <p className="mt-1"> {pair.to_dex}</p>
                  </div>
                  <div className="pt-2 2xl:pt-4 text-sm pb-3 px-3 2xl:pb-5 flex justify-between">
                    <p className="mt-1">
                      Profit Amount :{" "}
                      <span className="text-green-500">
                        {parseFloat(pair.Profit_Amount).toFixed(5)}
                      </span>
                    </p>
                  </div>
                  <div className="pt-2 2xl:pt-4 text-sm pb-3 px-3 2xl:pb-5 flex justify-between">
                    <p className="mt-1">
                      Profit Percentage :{" "}
                      <span className="text-green-500">
                        {pair.Profit_Percentage} %
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center mt-5">
                    <button
                      className="bg-blue-500 hover:bg-transparent border-transparent border hover:border-blue-500 text-white font-bold py-2 px-4 rounded"
                      onClick={() => handleTrade(pair)}
                    >
                      Trade
                    </button>
                  </div>
                </button>
              ))
            )}
          </div>
        </div>

        {showPopup && (
          <div className="z-50 w-full modal-overlay fixed inset-0 flex flex-col lg:flex-row md:items-center md:justify-center pt-[40%] md:pt-0 bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="bg-gradient-to-br from-white via-gray-700 to-[#DD7C0B] w-[95%] md:w-auto mx-auto rounded-xl p-[2px] ">
              <div className=" bg-gradient-to-br from-[#484037] to-black sm:p-10 p-7 rounded-xl relative">
                <div className="flex flex-col pt-1">
                  <p className="text-2l text-white font-bold text-center mt-4">
                    {TrxMessage ? TrxMessage : "Something went Wrong"}
                  </p>
                </div>
                <div className=" flex justify-center mt-7 gap-5 font-semibold text-sm">
                  {TrxHash !== null ? (
                    <button
                      className="bg-[#E43875]/60 px-3 md:px-7 py-1 rounded-lg"
                      onClick={handleViewTransaction}
                    >
                      View Transaction
                    </button>
                  ) : (
                    ""
                  )}

                  <button
                    className="bg-[#E43875]/60 px-3 md:px-7 py-3 rounded-lg"
                    onClick={RedirectButton}
                  >
                    Continue Trading
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {TransactionSpinner ? <TranSpinners /> : ""}
    </div>
  );
}

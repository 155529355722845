import React, { useState, useEffect } from "react";
import "../App.css";
import Header from "./Header";
import axios from "axios";
import Chart from "react-apexcharts";
import { ImageComponent } from "../Img";
import { useWeb3ModalAccount, useWeb3ModalProvider, } from "@web3modal/ethers5/react";
import { NODE_URL } from "../config.js";

const UserProfile = () => {
  const { address } = useWeb3ModalAccount();

  const [TotalTrades, setTotalTrades] = useState("");
  const [profitAmount, setProfitAmount] = useState("");
  const [borrowedAmount, setBorrowedAmount] = useState("");
  const [transactions, setTransactions] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [graph, setGraph] = useState([]);

  const dateformat = async (date) => {
    const originalDate = new Date(date);
    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1;
    const year = originalDate.getUTCFullYear() % 100;
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year.toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersTransactions?walletAddress=${address}`);
      const res = response.data.data;
      const transactions = await Promise.all(
        res.map(async (trans) => {
          const formattedDate = await dateformat(trans.createdAt);
          return {
            trxHash: trans.trxHash,
            date: formattedDate,
            token: trans.pair1,
            profit: Number(trans.profitPercentage),
            status: trans.status,
          };
        })
      );
      setTransactions(transactions);
    } catch (error) {
      console.error("Error while fetching: ", error);
    }
  };

  const fetchUserTransactions = async () => {
    try {
      const response = await axios.get(
        `${NODE_URL}:3006/apiv4/getUserTransactions?walletAddress=${address}`
      );
      setTotalTrades(response.data.total);
      setProfitAmount(response.data.profitAmount);
      setBorrowedAmount(response.data.borrowedAmount);
    } catch (error) {
      console.error("Error while fetching user stats: ", error);
    }
  };

  useEffect(() => {
    if (address !== undefined) {
      fetchUserTransactions();
      fetchData();
    }
    getDynamicData();

    if (address !== undefined) {
      getDynamicData(address).then((transformedItem) => {
        setGraph(transformedItem);
      });
    }
  }, [address]);

  const itemsPerPage = 10;
  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalPages = Math.ceil(TotalTrades / itemsPerPage);

  const handleNextPage = async () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
    }
  };

  const getPreviousDays = () => {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const today = new Date().getDay();
    const previousDays = [];

    for (let i = today; i >= 0; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }

    for (let i = daysOfWeek.length - 1; i > today; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }
    return previousDays;
  };

  const getDynamicData = async () => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getTransactionsCountForUser?walletAddress=${address}`);
      const data = response.data;
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const state = {
    options: {
      colors: ["#DD7C0B"],
      stroke: { width: 2, curve: "smooth" },
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: getPreviousDays(),
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        style: {
          background: "#9381FF",
        },
        theme: "dark",
        y: {
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
      },
    },
    series: [
      {
        name: "Transaction ",
        data: graph,
      },
    ],
  };

  return (
    <div className=" text-white font-poppins ">
      <div
        className="w-full bg-black h-full"
        style={{ backgroundImage: `url(assets/ bg2.png)` }}
      >
        <Header />
        <div className="z-0 md:absolute md:bottom-0 md:right-0 md:w-[25rem] md:h-[25rem]  md:filter md:blur-[7rem]  md:bg-[#E43875]/10 md:rounded-full"></div>
        <div className="z-0 md:absolute md:top-20 md:-left-20 md:w-[25rem] md:h-[28rem]  md:filter md:blur-3xl  md:bg-[#E43875]/10 md:rounded-full"></div>
        <div className="md:px-5 px-7 mt-10 md:mt-5 pb-20 z-10">
          <div className="lg:flex lg:mx-10 justify-center space-y-5 lg:space-y-0 lg:space-x-10">
            <div className="w-full sm:w-6/12 lg:w-4/12  p-[2px] rounded-2xl  bg-[#E438750F] text-white ">
              <div className="h-full py-3  p-2 rounded-2xl block ">
                <div className="flex justify-around h-full">
                  <div className="flex items-center w-[30%]">
                    <div className="w-[100%]">
                      <img src="./assets/Path 2.png" alt="graph" />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div>
                      <h5 className="text-white font-bold lg:m-2 text-xs">
                        Total Trade
                      </h5>
                      <p className="text-white font-bold my-2 sm:text-lg xl:text-2xl">
                        {TotalTrades}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span className="block rounded-full ">
                      <img
                        src="./assets/candle 1.png"
                        alt="candle"
                        className="w-7 h-7 object-cover"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-6/12 lg:w-4/12 p-[2px] rounded-2xl  bg-[#E438750F] text-white ">
              <div className="h-[8rem] py-3   p-2 rounded-2xl block ">
                <div className="flex justify-around h-full">
                  <div className="flex items-center w-[30%]">
                    <img src="./assets/Path 4.png" alt="graph" />
                  </div>
                  <div className="flex items-center">
                    <div>
                      <h5 className="text-white  font-bold lg:m-2 text-xs">
                        Profits Gained
                      </h5>
                      <p className="text-white  font-bold mt-2 sm:text-lg xl:text-2xl">
                        $ {profitAmount > 0 ? profitAmount : 0}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span className="block rounded-full">
                      <img
                        src="./assets/wallet-money 1.png"
                        alt="money"
                        className="w-8 h-7 object-cover"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-6/12 lg:w-4/12 p-[2px] rounded-2xl  bg-[#E438750F] text-white">
              <div className="h-[8rem] py-3  p-2 rounded-2xl block ">
                <div className="flex justify-around h-full">
                  <div className="flex items-center w-[30%]">
                    <img src="./assets/graph2.png" alt="graph" className="" />
                  </div>
                  <div className="flex items-center">
                    <div>
                      <h5 className="text-white font-bold lg:m-2 text-xs">
                        Borrowed Amounts
                      </h5>
                      <p className="text-white font-bold mt-2 sm:text-lg xl:text-2xl">
                        $ {borrowedAmount > 0 ? borrowedAmount : 0}
                      </p>
                    </div>
                  </div>
                  <div>
                    <span className="block p-2 rounded-full bg-[#00000040]">
                      <img
                        src="./assets/Icon Illustrations.png"
                        alt="money"
                        className="w-8 h-8 object-cover"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-full block mt-5  ">
            <div className=" md:mx-10 mx-0">
              <div className="p-[2px] rounded-2xl  border border-[#E43875A6]">
                <div className=" md:p-7 p-4 text-black rounded-2xl ">
                  <div className="flex w-full text-black items-center justify-between">
                    <h4 className="text-white text-sm font-bold">Overview</h4>
                  </div>
                  <div className="h-80 sm:h-96 md:h-128 lg:h-144 xl:h-[15rem] p-3">
                    <Chart
                      options={state.options}
                      series={state.series}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full h-full block mt-5">
            <div className="md:mx-10 ">
              <div className="w-full p-[2px] rounded-2xl  bg-[#E4387526] text-white overflow-x-scroll lg:overflow-hidden">
                <div className="h-full py-3  p-2  rounded-2xl block overflow-x-scroll lg:overflow-hidden">
                  <table className="border-none w-full h-auto overflow-x-scroll lg:overflow-hidden">
                    <thead className="py-4">
                      <tr>
                        <th className="text-white font-bold text-lg lg:text-2xl flex items-center gap-2 px-4 py-2">
                          <img
                            src="./assets/Sparkle.svg"
                            className="w-5 h-5"
                            alt="taborr logo"
                          />
                          <p className="md:text-lg text-sm font-Artemus">
                            Transaction Hash
                          </p>
                        </th>
                        <th className="text-white font-bold text-sm px-4 py-2">
                          Date
                        </th>
                        <th className="text-white font-bold text-sm px-4 py-2">
                          Token
                        </th>
                        <th className="text-white font-bold text-sm px-4 py-2">
                          Profit
                        </th>
                        <th className="text-white font-bold text-sm px-4 py-2">
                          Result
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-xs">
                      {currentTransactions && currentTransactions.length > 0 ? (
                        currentTransactions.map((trans) => (
                          <tr key={trans.trxHash}>
                            <td className="font-bold px-4 py-3 text-sm ">
                              <a
                                href={`https://polygonscan.com/tx/${trans.trxHash}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:text-[#1EEF32] hover:underline"
                              >
                                {trans.trxHash}
                              </a>
                            </td>
                            <td className="font-bold px-4 py-2 text-sm">
                              {trans.date}
                            </td>
                            <td className="font-bold px-4 py-2 text-sm flex items-center">
                              <ImageComponent symbol={trans.token} /> &nbsp;
                              <p>{trans.token ? trans.token : "USDC.e"}</p>
                            </td>
                            <td className="font-bold px-4 py-2 text-sm">
                              <span
                                className="block"
                                style={{
                                  color: trans.profit < 0 ? "red" : "#1EEF32",
                                }}
                              >
                                {trans.profit.toFixed(3)}%
                              </span>
                            </td>
                            <td
                              className="font-bold px-4 py-2 text-sm text-center"
                              style={{
                                color:
                                  trans.status === "success"
                                    ? "#1EEF32"
                                    : "red",
                              }}
                            >
                              <p className="text-center text-xs">
                                {trans.status === "success"
                                  ? "Profitable Trade"
                                  : "Loss Blocked"}
                              </p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center py-4">
                            No transactions available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="flex justify-between mt-4 px-10 pt-3 text-xs">
                    <button
                      className="mr-20"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous Page
                    </button>
                    <div className="flex items-center">
                      <p>{`Page ${currentPage} of ${totalPages}`}</p>
                    </div>
                    <button
                      className="ml-20"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next Page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

const tokenList = [
  {
    "pair": "WETH/USDM",
    "from": "WETH",
    "to": "USDM",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/USDC",
    "from": "WETH",
    "to": "USDC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/THE",
    "from": "WETH",
    "to": "THE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/VRSW",
    "from": "WETH",
    "to": "VRSW",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/PYM",
    "from": "WETH",
    "to": "PYM",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SDEX",
    "from": "WETH",
    "to": "SDEX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x6899face15c14348e1759371049ab64a3a06bfa6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/RETRO",
    "from": "WETH",
    "to": "RETRO",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/QUICK(NEW)",
    "from": "WETH",
    "to": "QUICK(NEW)",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SFL",
    "from": "WETH",
    "to": "SFL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/AXLUSDC",
    "from": "WETH",
    "to": "AXLUSDC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/USD",
    "from": "WETH",
    "to": "USD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/EURS",
    "from": "WETH",
    "to": "EURS",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/ALI",
    "from": "WETH",
    "to": "ALI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/TETU",
    "from": "WETH",
    "to": "TETU",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/BOB",
    "from": "WETH",
    "to": "BOB",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MVX",
    "from": "WETH",
    "to": "MVX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/GOVI",
    "from": "WETH",
    "to": "GOVI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MMF",
    "from": "WETH",
    "to": "MMF",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/JPYC",
    "from": "WETH",
    "to": "JPYC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/BUSD",
    "from": "WETH",
    "to": "BUSD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MATIC",
    "from": "WETH",
    "to": "MATIC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x0000000000000000000000000000000000001010",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/WMATIC",
    "from": "WETH",
    "to": "WMATIC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/WBTC",
    "from": "WETH",
    "to": "WBTC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/USDC.e",
    "from": "WETH",
    "to": "USDC.e",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/stMATIC",
    "from": "WETH",
    "to": "stMATIC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/GDDY",
    "from": "WETH",
    "to": "GDDY",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/XY",
    "from": "WETH",
    "to": "XY",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MULTI",
    "from": "WETH",
    "to": "MULTI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/CHAMP",
    "from": "WETH",
    "to": "CHAMP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MASK",
    "from": "WETH",
    "to": "MASK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/QUICK",
    "from": "WETH",
    "to": "QUICK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/GHST",
    "from": "WETH",
    "to": "GHST",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/WNT",
    "from": "WETH",
    "to": "WNT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DFYN",
    "from": "WETH",
    "to": "DFYN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/PSP",
    "from": "WETH",
    "to": "PSP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x42d61D766B85431666B39B89C43011f24451bFf6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/ANKR",
    "from": "WETH",
    "to": "ANKR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DFX",
    "from": "WETH",
    "to": "DFX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MUST",
    "from": "WETH",
    "to": "MUST",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SYN",
    "from": "WETH",
    "to": "SYN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/KNC",
    "from": "WETH",
    "to": "KNC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/BAL",
    "from": "WETH",
    "to": "BAL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SUSHI",
    "from": "WETH",
    "to": "SUSHI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DYST",
    "from": "WETH",
    "to": "DYST",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/WOO",
    "from": "WETH",
    "to": "WOO",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MESH",
    "from": "WETH",
    "to": "MESH",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/VOXEL",
    "from": "WETH",
    "to": "VOXEL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/ankrMATIC",
    "from": "WETH",
    "to": "ankrMATIC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DAI",
    "from": "WETH",
    "to": "DAI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SAND",
    "from": "WETH",
    "to": "SAND",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SPHERE",
    "from": "WETH",
    "to": "SPHERE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/deUSDC",
    "from": "WETH",
    "to": "deUSDC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/PYR",
    "from": "WETH",
    "to": "PYR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/FRAX",
    "from": "WETH",
    "to": "FRAX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/FXS",
    "from": "WETH",
    "to": "FXS",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MAAAVE",
    "from": "WETH",
    "to": "MAAAVE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/AMAAVE",
    "from": "WETH",
    "to": "AMAAVE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/STG",
    "from": "WETH",
    "to": "STG",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/ICE",
    "from": "WETH",
    "to": "ICE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/GNS",
    "from": "WETH",
    "to": "GNS",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe5417af564e4bfda1c483642db72007871397896",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/RNDR",
    "from": "WETH",
    "to": "RNDR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x61299774020dA444Af134c82fa83E3810b309991",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/PLA",
    "from": "WETH",
    "to": "PLA",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maUSDC",
    "from": "WETH",
    "to": "maUSDC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/AAVE",
    "from": "WETH",
    "to": "AAVE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/USDT",
    "from": "WETH",
    "to": "USDT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/ADDY",
    "from": "WETH",
    "to": "ADDY",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/AGA",
    "from": "WETH",
    "to": "AGA",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "WETH/AGAr",
    "from": "WETH",
    "to": "AGAr",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/CC10",
    "from": "WETH",
    "to": "CC10",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/CEL",
    "from": "WETH",
    "to": "CEL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "WETH/DEFI5",
    "from": "WETH",
    "to": "DEFI5",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DEGEN",
    "from": "WETH",
    "to": "DEGEN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DINO",
    "from": "WETH",
    "to": "DINO",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/FISH",
    "from": "WETH",
    "to": "FISH",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/GAJ",
    "from": "WETH",
    "to": "GAJ",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/Krill",
    "from": "WETH",
    "to": "Krill",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/LINK",
    "from": "WETH",
    "to": "LINK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/PolyDoge",
    "from": "WETH",
    "to": "PolyDoge",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/PolyMoon",
    "from": "WETH",
    "to": "PolyMoon",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "WETH/SX",
    "from": "WETH",
    "to": "SX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/TEL",
    "from": "WETH",
    "to": "TEL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "WETH/UBT",
    "from": "WETH",
    "to": "UBT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x7FBc10850caE055B27039aF31bD258430e714c62",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/UNI",
    "from": "WETH",
    "to": "UNI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MAI",
    "from": "WETH",
    "to": "MAI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/pSWAMP",
    "from": "WETH",
    "to": "pSWAMP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/polyBUNNY",
    "from": "WETH",
    "to": "polyBUNNY",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/AUMI",
    "from": "WETH",
    "to": "AUMI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/1ART",
    "from": "WETH",
    "to": "1ART",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jEUR",
    "from": "WETH",
    "to": "jEUR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jGBP",
    "from": "WETH",
    "to": "jGBP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x767058f11800fba6a682e73a6e79ec5eb74fac8c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jCHF",
    "from": "WETH",
    "to": "jCHF",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jAUD",
    "from": "WETH",
    "to": "jAUD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xcb7f1ef7246d1497b985f7fc45a1a31f04346133",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jCAD",
    "from": "WETH",
    "to": "jCAD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jCNY",
    "from": "WETH",
    "to": "jCNY",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x84526c812d8f6c4fd6c1a5b68713aff50733e772",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jCOP",
    "from": "WETH",
    "to": "jCOP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe6d222caaed5f5dd73a9713ac91c95782e80acbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jJPY",
    "from": "WETH",
    "to": "jJPY",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jKRW",
    "from": "WETH",
    "to": "jKRW",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xa22f6bc96f13bcc84df36109c973d3c0505a067e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jMXN",
    "from": "WETH",
    "to": "jMXN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xbd1fe73e1f12bd2bc237de9b626f056f21f86427",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jNGN",
    "from": "WETH",
    "to": "jNGN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x182c76e977161f703bb8f111047df6c43cfacc56",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jNZD",
    "from": "WETH",
    "to": "jNZD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x6b526daf03b4c47af2bcc5860b12151823ff70e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jPHP",
    "from": "WETH",
    "to": "jPHP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x486880fb16408b47f928f472f57bec55ac6089d1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jPLN",
    "from": "WETH",
    "to": "jPLN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x08e6d1f0c4877ef2993ad733fc6f1d022d0e9dbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jBRL",
    "from": "WETH",
    "to": "jBRL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xf2f77fe7b8e66571e0fca7104c4d670bf1c8d722",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jSEK",
    "from": "WETH",
    "to": "jSEK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x197e5d6ccff265ac3e303a34db360ee1429f5d1a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/jSGD",
    "from": "WETH",
    "to": "jSGD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/BIFI",
    "from": "WETH",
    "to": "BIFI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MEME",
    "from": "WETH",
    "to": "MEME",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/SUPER",
    "from": "WETH",
    "to": "SUPER",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xa1428174f516f527fafdd146b883bb4428682737",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MANA",
    "from": "WETH",
    "to": "MANA",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/mUSD",
    "from": "WETH",
    "to": "mUSD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe840b73e5287865eec17d250bfb1536704b43b21",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/YFI",
    "from": "WETH",
    "to": "YFI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xda537104d6a5edd53c6fbba9a898708e465260b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/QUICK(old)",
    "from": "WETH",
    "to": "QUICK(old)",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/renBTC",
    "from": "WETH",
    "to": "renBTC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DfynWMATIC",
    "from": "WETH",
    "to": "DfynWMATIC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/HAWK",
    "from": "WETH",
    "to": "HAWK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x69cbc7449ee102eb792f1656744bf1a7c1bacb7e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/BPT",
    "from": "WETH",
    "to": "BPT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x6863bd30c9e313b264657b107352ba246f8af8e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/amAAVE",
    "from": "WETH",
    "to": "amAAVE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/amDAI",
    "from": "WETH",
    "to": "amDAI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/amUSDC",
    "from": "WETH",
    "to": "amUSDC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/amUSDT",
    "from": "WETH",
    "to": "amUSDT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/amWBTC",
    "from": "WETH",
    "to": "amWBTC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/amWETH",
    "from": "WETH",
    "to": "amWETH",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/amWMATIC",
    "from": "WETH",
    "to": "amWMATIC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/COMP",
    "from": "WETH",
    "to": "COMP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/BTU",
    "from": "WETH",
    "to": "BTU",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/$DG",
    "from": "WETH",
    "to": "$DG",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/0xBTC",
    "from": "WETH",
    "to": "0xBTC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x71b821aa52a49f32eed535fca6eb5aa130085978",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/ARIA20",
    "from": "WETH",
    "to": "ARIA20",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x46f48fbdedaa6f5500993bede9539ef85f4bee8e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/AZUKI",
    "from": "WETH",
    "to": "AZUKI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x7cdc0421469398e0f3aa8890693d86c840ac8931",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/CFI",
    "from": "WETH",
    "to": "CFI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/CTSI",
    "from": "WETH",
    "to": "CTSI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2727ab1c2d22170abc9b595177b2d5c6e1ab7b7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DB",
    "from": "WETH",
    "to": "DB",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x0e59d50add2d90f5111aca875bae0a72d95b4762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DMT",
    "from": "WETH",
    "to": "DMT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DSLA",
    "from": "WETH",
    "to": "DSLA",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/EASY",
    "from": "WETH",
    "to": "EASY",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/ELET",
    "from": "WETH",
    "to": "ELET",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x07738eb4ce8932ca961c815cb12c9d4ab5bd0da4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/ETH",
    "from": "WETH",
    "to": "ETH",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/GAME",
    "from": "WETH",
    "to": "GAME",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8d1566569d5b695d44a9a234540f68d393cdc40d",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/HEX",
    "from": "WETH",
    "to": "HEX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/HH",
    "from": "WETH",
    "to": "HH",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x521cddc0cba84f14c69c1e99249f781aa73ee0bc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/IGG",
    "from": "WETH",
    "to": "IGG",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/JULIEN",
    "from": "WETH",
    "to": "JULIEN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x268ad27c28601d28b79c792c14e95bd2b7a030f8",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "WETH/LEND",
    "from": "WETH",
    "to": "LEND",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/MONA",
    "from": "WETH",
    "to": "MONA",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/NDR",
    "from": "WETH",
    "to": "NDR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xfb65ef42f7c8a70ff73f627db6e9ba2aff1f20fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/OM",
    "from": "WETH",
    "to": "OM",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/PICKLE",
    "from": "WETH",
    "to": "PICKLE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2b88ad57897a8b496595925f43048301c37615da",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/PPDEX",
    "from": "WETH",
    "to": "PPDEX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/RBAL",
    "from": "WETH",
    "to": "RBAL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SDT",
    "from": "WETH",
    "to": "SDT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SENT",
    "from": "WETH",
    "to": "SENT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x48e3883233461c2ef4cb3fcf419d6db07fb86cea",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/SWAP",
    "from": "WETH",
    "to": "SWAP",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x3809dcdd5dde24b37abe64a5a339784c3323c44f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/SWG",
    "from": "WETH",
    "to": "SWG",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/VISION",
    "from": "WETH",
    "to": "VISION",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/WISE",
    "from": "WETH",
    "to": "WISE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/WRX",
    "from": "WETH",
    "to": "WRX",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/ZUT",
    "from": "WETH",
    "to": "ZUT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe86e8beb7340659dddce61727e500e3a5ad75a90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/ZUZ",
    "from": "WETH",
    "to": "ZUZ",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x232eab56c4fb3f84c6fb0a50c087c74b7b43c6ad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/bBADGER",
    "from": "WETH",
    "to": "bBADGER",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x2628d301b161db70e3dbbac88d9d900ca426ff02",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/bDIGG",
    "from": "WETH",
    "to": "bDIGG",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xfdde616084427f0a231d0664a985e1f820e34693",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/eDAI",
    "from": "WETH",
    "to": "eDAI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/eUSDC",
    "from": "WETH",
    "to": "eUSDC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/eUSDT",
    "from": "WETH",
    "to": "eUSDT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/iFARM",
    "from": "WETH",
    "to": "iFARM",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/mDEF",
    "from": "WETH",
    "to": "mDEF",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x82b6205002ecd05e97642d38d61e2cfeac0e18ce",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "WETH/mOCEAN",
    "from": "WETH",
    "to": "mOCEAN",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/mRBAL",
    "from": "WETH",
    "to": "mRBAL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x66768ad00746ac4d68ded9f64886d55d5243f5ec",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maAAVE",
    "from": "WETH",
    "to": "maAAVE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maDAI",
    "from": "WETH",
    "to": "maDAI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe0b22e0037b130a9f56bbb537684e6fa18192341",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maLINK",
    "from": "WETH",
    "to": "maLINK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x98ea609569bd25119707451ef982b90e3eb719cd",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maTUSD",
    "from": "WETH",
    "to": "maTUSD",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maUNI",
    "from": "WETH",
    "to": "maUNI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8c8bdbe9cee455732525086264a4bf9cf821c498",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maUSDT",
    "from": "WETH",
    "to": "maUSDT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xdae5f1590db13e3b40423b5b5c5fbf175515910b",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/maWETH",
    "from": "WETH",
    "to": "maWETH",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x20d3922b4a1a8560e1ac99fba4fade0c849e2142",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/maYFI",
    "from": "WETH",
    "to": "maYFI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/sd3Crv",
    "from": "WETH",
    "to": "sd3Crv",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x87f0bfee4435ce2b8643b221d0c1cad21f5328b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/sdcrvRenWSBTC",
    "from": "WETH",
    "to": "sdcrvRenWSBTC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe212f92e5af85268b33d2aa587b51f49c3b945be",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/sdeursCRV",
    "from": "WETH",
    "to": "sdeursCRV",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xfbdb45075fb73ca4cc8b83fd6fdb4f9b696b1ba1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/xMARK",
    "from": "WETH",
    "to": "xMARK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xf153eff70dc0bf3b085134928daeea248d9b30d0",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "WETH/xSDT",
    "from": "WETH",
    "to": "xSDT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xd921f8318cfd786bab1ea7492673f053c518ac04",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/DPI",
    "from": "WETH",
    "to": "DPI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/agEUR",
    "from": "WETH",
    "to": "agEUR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolAAVE",
    "from": "WETH",
    "to": "aPolAAVE",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xf329e36c7bf6e5e86ce2150875a84ce77f477375",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolDAI",
    "from": "WETH",
    "to": "aPolDAI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolUSDC",
    "from": "WETH",
    "to": "aPolUSDC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/aPolUSDT",
    "from": "WETH",
    "to": "aPolUSDT",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x6ab707aca953edaefbc4fd23ba73294241490620",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WETH/aPolWBTC",
    "from": "WETH",
    "to": "aPolWBTC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x078f358208685046a11c85e8ad32895ded33a249",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WETH/aPolWETH",
    "from": "WETH",
    "to": "aPolWETH",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolWMATIC",
    "from": "WETH",
    "to": "aPolWMATIC",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x6d80113e533a2c0fe82eabd35f1875dcea89ea97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolLINK",
    "from": "WETH",
    "to": "aPolLINK",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x191c10aa4af7c30e871e70c95db0e4eb77237530",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolEURS",
    "from": "WETH",
    "to": "aPolEURS",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x38d693ce1df5aadf7bc62595a37d667ad57922e5",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "WETH/aPolGHST",
    "from": "WETH",
    "to": "aPolGHST",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8eb270e296023e9d92081fdf967ddd7878724424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolBAL",
    "from": "WETH",
    "to": "aPolBAL",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8ffdf2de812095b1d19cb146e4c004587c0a0692",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolCRV",
    "from": "WETH",
    "to": "aPolCRV",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x513c7e3a9c69ca3e22550ef58ac1c0088e918fff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolDPI",
    "from": "WETH",
    "to": "aPolDPI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x724dc807b04555b71ed48a6896b6f41593b8c637",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolSUSHI",
    "from": "WETH",
    "to": "aPolSUSHI",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0xc45a479877e1e9dfe9fcd4056c699575a1045daa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolJEUR",
    "from": "WETH",
    "to": "aPolJEUR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x6533afac2e7bccb20dca161449a13a32d391fb00",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WETH/aPolAGEUR",
    "from": "WETH",
    "to": "aPolAGEUR",
    "from_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "to_contract": "0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "USDT/USDM",
    "from": "USDT",
    "to": "USDM",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/USDC",
    "from": "USDT",
    "to": "USDC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/THE",
    "from": "USDT",
    "to": "THE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/VRSW",
    "from": "USDT",
    "to": "VRSW",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/PYM",
    "from": "USDT",
    "to": "PYM",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SDEX",
    "from": "USDT",
    "to": "SDEX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x6899face15c14348e1759371049ab64a3a06bfa6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/RETRO",
    "from": "USDT",
    "to": "RETRO",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/QUICK(NEW)",
    "from": "USDT",
    "to": "QUICK(NEW)",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SFL",
    "from": "USDT",
    "to": "SFL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/AXLUSDC",
    "from": "USDT",
    "to": "AXLUSDC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/USD",
    "from": "USDT",
    "to": "USD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/EURS",
    "from": "USDT",
    "to": "EURS",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ALI",
    "from": "USDT",
    "to": "ALI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/TETU",
    "from": "USDT",
    "to": "TETU",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/BOB",
    "from": "USDT",
    "to": "BOB",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MVX",
    "from": "USDT",
    "to": "MVX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/GOVI",
    "from": "USDT",
    "to": "GOVI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MMF",
    "from": "USDT",
    "to": "MMF",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/JPYC",
    "from": "USDT",
    "to": "JPYC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/BUSD",
    "from": "USDT",
    "to": "BUSD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MATIC",
    "from": "USDT",
    "to": "MATIC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x0000000000000000000000000000000000001010",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/WMATIC",
    "from": "USDT",
    "to": "WMATIC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/WETH",
    "from": "USDT",
    "to": "WETH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/WBTC",
    "from": "USDT",
    "to": "WBTC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/USDC.e",
    "from": "USDT",
    "to": "USDC.e",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/stMATIC",
    "from": "USDT",
    "to": "stMATIC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/GDDY",
    "from": "USDT",
    "to": "GDDY",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/XY",
    "from": "USDT",
    "to": "XY",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MULTI",
    "from": "USDT",
    "to": "MULTI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/CHAMP",
    "from": "USDT",
    "to": "CHAMP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MASK",
    "from": "USDT",
    "to": "MASK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/QUICK",
    "from": "USDT",
    "to": "QUICK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/GHST",
    "from": "USDT",
    "to": "GHST",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/WNT",
    "from": "USDT",
    "to": "WNT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DFYN",
    "from": "USDT",
    "to": "DFYN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/PSP",
    "from": "USDT",
    "to": "PSP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x42d61D766B85431666B39B89C43011f24451bFf6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ANKR",
    "from": "USDT",
    "to": "ANKR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DFX",
    "from": "USDT",
    "to": "DFX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MUST",
    "from": "USDT",
    "to": "MUST",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SYN",
    "from": "USDT",
    "to": "SYN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/KNC",
    "from": "USDT",
    "to": "KNC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/BAL",
    "from": "USDT",
    "to": "BAL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SUSHI",
    "from": "USDT",
    "to": "SUSHI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DYST",
    "from": "USDT",
    "to": "DYST",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/WOO",
    "from": "USDT",
    "to": "WOO",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MESH",
    "from": "USDT",
    "to": "MESH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/VOXEL",
    "from": "USDT",
    "to": "VOXEL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ankrMATIC",
    "from": "USDT",
    "to": "ankrMATIC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DAI",
    "from": "USDT",
    "to": "DAI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SAND",
    "from": "USDT",
    "to": "SAND",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SPHERE",
    "from": "USDT",
    "to": "SPHERE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/deUSDC",
    "from": "USDT",
    "to": "deUSDC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/PYR",
    "from": "USDT",
    "to": "PYR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/FRAX",
    "from": "USDT",
    "to": "FRAX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/FXS",
    "from": "USDT",
    "to": "FXS",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MAAAVE",
    "from": "USDT",
    "to": "MAAAVE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/AMAAVE",
    "from": "USDT",
    "to": "AMAAVE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/STG",
    "from": "USDT",
    "to": "STG",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ICE",
    "from": "USDT",
    "to": "ICE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/GNS",
    "from": "USDT",
    "to": "GNS",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe5417af564e4bfda1c483642db72007871397896",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/RNDR",
    "from": "USDT",
    "to": "RNDR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x61299774020dA444Af134c82fa83E3810b309991",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/PLA",
    "from": "USDT",
    "to": "PLA",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maUSDC",
    "from": "USDT",
    "to": "maUSDC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/AAVE",
    "from": "USDT",
    "to": "AAVE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ADDY",
    "from": "USDT",
    "to": "ADDY",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/AGA",
    "from": "USDT",
    "to": "AGA",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
    "from_decimal": 6,
    "to_decimal": 4
  },
  {
    "pair": "USDT/AGAr",
    "from": "USDT",
    "to": "AGAr",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/CC10",
    "from": "USDT",
    "to": "CC10",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/CEL",
    "from": "USDT",
    "to": "CEL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
    "from_decimal": 6,
    "to_decimal": 4
  },
  {
    "pair": "USDT/DEFI5",
    "from": "USDT",
    "to": "DEFI5",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DEGEN",
    "from": "USDT",
    "to": "DEGEN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DINO",
    "from": "USDT",
    "to": "DINO",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/FISH",
    "from": "USDT",
    "to": "FISH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/GAJ",
    "from": "USDT",
    "to": "GAJ",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/Krill",
    "from": "USDT",
    "to": "Krill",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/LINK",
    "from": "USDT",
    "to": "LINK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/PolyDoge",
    "from": "USDT",
    "to": "PolyDoge",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/PolyMoon",
    "from": "USDT",
    "to": "PolyMoon",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
    "from_decimal": 6,
    "to_decimal": 9
  },
  {
    "pair": "USDT/SX",
    "from": "USDT",
    "to": "SX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/TEL",
    "from": "USDT",
    "to": "TEL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
    "from_decimal": 6,
    "to_decimal": 2
  },
  {
    "pair": "USDT/UBT",
    "from": "USDT",
    "to": "UBT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x7FBc10850caE055B27039aF31bD258430e714c62",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/UNI",
    "from": "USDT",
    "to": "UNI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MAI",
    "from": "USDT",
    "to": "MAI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/pSWAMP",
    "from": "USDT",
    "to": "pSWAMP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/polyBUNNY",
    "from": "USDT",
    "to": "polyBUNNY",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/AUMI",
    "from": "USDT",
    "to": "AUMI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/1ART",
    "from": "USDT",
    "to": "1ART",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jEUR",
    "from": "USDT",
    "to": "jEUR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jGBP",
    "from": "USDT",
    "to": "jGBP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x767058f11800fba6a682e73a6e79ec5eb74fac8c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jCHF",
    "from": "USDT",
    "to": "jCHF",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jAUD",
    "from": "USDT",
    "to": "jAUD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xcb7f1ef7246d1497b985f7fc45a1a31f04346133",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jCAD",
    "from": "USDT",
    "to": "jCAD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jCNY",
    "from": "USDT",
    "to": "jCNY",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x84526c812d8f6c4fd6c1a5b68713aff50733e772",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jCOP",
    "from": "USDT",
    "to": "jCOP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe6d222caaed5f5dd73a9713ac91c95782e80acbf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jJPY",
    "from": "USDT",
    "to": "jJPY",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jKRW",
    "from": "USDT",
    "to": "jKRW",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xa22f6bc96f13bcc84df36109c973d3c0505a067e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jMXN",
    "from": "USDT",
    "to": "jMXN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xbd1fe73e1f12bd2bc237de9b626f056f21f86427",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jNGN",
    "from": "USDT",
    "to": "jNGN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x182c76e977161f703bb8f111047df6c43cfacc56",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jNZD",
    "from": "USDT",
    "to": "jNZD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x6b526daf03b4c47af2bcc5860b12151823ff70e0",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jPHP",
    "from": "USDT",
    "to": "jPHP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x486880fb16408b47f928f472f57bec55ac6089d1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jPLN",
    "from": "USDT",
    "to": "jPLN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x08e6d1f0c4877ef2993ad733fc6f1d022d0e9dbf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jBRL",
    "from": "USDT",
    "to": "jBRL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xf2f77fe7b8e66571e0fca7104c4d670bf1c8d722",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jSEK",
    "from": "USDT",
    "to": "jSEK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x197e5d6ccff265ac3e303a34db360ee1429f5d1a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/jSGD",
    "from": "USDT",
    "to": "jSGD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/BIFI",
    "from": "USDT",
    "to": "BIFI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MEME",
    "from": "USDT",
    "to": "MEME",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/SUPER",
    "from": "USDT",
    "to": "SUPER",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xa1428174f516f527fafdd146b883bb4428682737",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MANA",
    "from": "USDT",
    "to": "MANA",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/mUSD",
    "from": "USDT",
    "to": "mUSD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe840b73e5287865eec17d250bfb1536704b43b21",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/YFI",
    "from": "USDT",
    "to": "YFI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xda537104d6a5edd53c6fbba9a898708e465260b6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/QUICK(old)",
    "from": "USDT",
    "to": "QUICK(old)",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/renBTC",
    "from": "USDT",
    "to": "renBTC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DfynWMATIC",
    "from": "USDT",
    "to": "DfynWMATIC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/HAWK",
    "from": "USDT",
    "to": "HAWK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x69cbc7449ee102eb792f1656744bf1a7c1bacb7e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/BPT",
    "from": "USDT",
    "to": "BPT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x6863bd30c9e313b264657b107352ba246f8af8e0",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/amAAVE",
    "from": "USDT",
    "to": "amAAVE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/amDAI",
    "from": "USDT",
    "to": "amDAI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/amUSDC",
    "from": "USDT",
    "to": "amUSDC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/amUSDT",
    "from": "USDT",
    "to": "amUSDT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/amWBTC",
    "from": "USDT",
    "to": "amWBTC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/amWETH",
    "from": "USDT",
    "to": "amWETH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/amWMATIC",
    "from": "USDT",
    "to": "amWMATIC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/COMP",
    "from": "USDT",
    "to": "COMP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/BTU",
    "from": "USDT",
    "to": "BTU",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/$DG",
    "from": "USDT",
    "to": "$DG",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/0xBTC",
    "from": "USDT",
    "to": "0xBTC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x71b821aa52a49f32eed535fca6eb5aa130085978",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/ARIA20",
    "from": "USDT",
    "to": "ARIA20",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x46f48fbdedaa6f5500993bede9539ef85f4bee8e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/AZUKI",
    "from": "USDT",
    "to": "AZUKI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x7cdc0421469398e0f3aa8890693d86c840ac8931",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/CFI",
    "from": "USDT",
    "to": "CFI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/CTSI",
    "from": "USDT",
    "to": "CTSI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2727ab1c2d22170abc9b595177b2d5c6e1ab7b7b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DB",
    "from": "USDT",
    "to": "DB",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x0e59d50add2d90f5111aca875bae0a72d95b4762",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DMT",
    "from": "USDT",
    "to": "DMT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DSLA",
    "from": "USDT",
    "to": "DSLA",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/EASY",
    "from": "USDT",
    "to": "EASY",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ELET",
    "from": "USDT",
    "to": "ELET",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x07738eb4ce8932ca961c815cb12c9d4ab5bd0da4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ETH",
    "from": "USDT",
    "to": "ETH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/GAME",
    "from": "USDT",
    "to": "GAME",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8d1566569d5b695d44a9a234540f68d393cdc40d",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/HEX",
    "from": "USDT",
    "to": "HEX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/HH",
    "from": "USDT",
    "to": "HH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x521cddc0cba84f14c69c1e99249f781aa73ee0bc",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/IGG",
    "from": "USDT",
    "to": "IGG",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/JULIEN",
    "from": "USDT",
    "to": "JULIEN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x268ad27c28601d28b79c792c14e95bd2b7a030f8",
    "from_decimal": 6,
    "to_decimal": 4
  },
  {
    "pair": "USDT/LEND",
    "from": "USDT",
    "to": "LEND",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/MONA",
    "from": "USDT",
    "to": "MONA",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/NDR",
    "from": "USDT",
    "to": "NDR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xfb65ef42f7c8a70ff73f627db6e9ba2aff1f20fa",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/OM",
    "from": "USDT",
    "to": "OM",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/PICKLE",
    "from": "USDT",
    "to": "PICKLE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2b88ad57897a8b496595925f43048301c37615da",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/PPDEX",
    "from": "USDT",
    "to": "PPDEX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/RBAL",
    "from": "USDT",
    "to": "RBAL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SDT",
    "from": "USDT",
    "to": "SDT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SENT",
    "from": "USDT",
    "to": "SENT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x48e3883233461c2ef4cb3fcf419d6db07fb86cea",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/SWAP",
    "from": "USDT",
    "to": "SWAP",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x3809dcdd5dde24b37abe64a5a339784c3323c44f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/SWG",
    "from": "USDT",
    "to": "SWG",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/VISION",
    "from": "USDT",
    "to": "VISION",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/WISE",
    "from": "USDT",
    "to": "WISE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/WRX",
    "from": "USDT",
    "to": "WRX",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/ZUT",
    "from": "USDT",
    "to": "ZUT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe86e8beb7340659dddce61727e500e3a5ad75a90",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/ZUZ",
    "from": "USDT",
    "to": "ZUZ",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x232eab56c4fb3f84c6fb0a50c087c74b7b43c6ad",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/bBADGER",
    "from": "USDT",
    "to": "bBADGER",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x2628d301b161db70e3dbbac88d9d900ca426ff02",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/bDIGG",
    "from": "USDT",
    "to": "bDIGG",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xfdde616084427f0a231d0664a985e1f820e34693",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/eDAI",
    "from": "USDT",
    "to": "eDAI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/eUSDC",
    "from": "USDT",
    "to": "eUSDC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/eUSDT",
    "from": "USDT",
    "to": "eUSDT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/iFARM",
    "from": "USDT",
    "to": "iFARM",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/mDEF",
    "from": "USDT",
    "to": "mDEF",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x82b6205002ecd05e97642d38d61e2cfeac0e18ce",
    "from_decimal": 6,
    "to_decimal": 9
  },
  {
    "pair": "USDT/mOCEAN",
    "from": "USDT",
    "to": "mOCEAN",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/mRBAL",
    "from": "USDT",
    "to": "mRBAL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x66768ad00746ac4d68ded9f64886d55d5243f5ec",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maAAVE",
    "from": "USDT",
    "to": "maAAVE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maDAI",
    "from": "USDT",
    "to": "maDAI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe0b22e0037b130a9f56bbb537684e6fa18192341",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maLINK",
    "from": "USDT",
    "to": "maLINK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x98ea609569bd25119707451ef982b90e3eb719cd",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maTUSD",
    "from": "USDT",
    "to": "maTUSD",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maUNI",
    "from": "USDT",
    "to": "maUNI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8c8bdbe9cee455732525086264a4bf9cf821c498",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maUSDT",
    "from": "USDT",
    "to": "maUSDT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xdae5f1590db13e3b40423b5b5c5fbf175515910b",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/maWETH",
    "from": "USDT",
    "to": "maWETH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x20d3922b4a1a8560e1ac99fba4fade0c849e2142",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/maYFI",
    "from": "USDT",
    "to": "maYFI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/sd3Crv",
    "from": "USDT",
    "to": "sd3Crv",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x87f0bfee4435ce2b8643b221d0c1cad21f5328b4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/sdcrvRenWSBTC",
    "from": "USDT",
    "to": "sdcrvRenWSBTC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe212f92e5af85268b33d2aa587b51f49c3b945be",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/sdeursCRV",
    "from": "USDT",
    "to": "sdeursCRV",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xfbdb45075fb73ca4cc8b83fd6fdb4f9b696b1ba1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/xMARK",
    "from": "USDT",
    "to": "xMARK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xf153eff70dc0bf3b085134928daeea248d9b30d0",
    "from_decimal": 6,
    "to_decimal": 9
  },
  {
    "pair": "USDT/xSDT",
    "from": "USDT",
    "to": "xSDT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xd921f8318cfd786bab1ea7492673f053c518ac04",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/DPI",
    "from": "USDT",
    "to": "DPI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/agEUR",
    "from": "USDT",
    "to": "agEUR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolAAVE",
    "from": "USDT",
    "to": "aPolAAVE",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xf329e36c7bf6e5e86ce2150875a84ce77f477375",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolDAI",
    "from": "USDT",
    "to": "aPolDAI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolUSDC",
    "from": "USDT",
    "to": "aPolUSDC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/aPolUSDT",
    "from": "USDT",
    "to": "aPolUSDT",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x6ab707aca953edaefbc4fd23ba73294241490620",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDT/aPolWBTC",
    "from": "USDT",
    "to": "aPolWBTC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x078f358208685046a11c85e8ad32895ded33a249",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDT/aPolWETH",
    "from": "USDT",
    "to": "aPolWETH",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolWMATIC",
    "from": "USDT",
    "to": "aPolWMATIC",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x6d80113e533a2c0fe82eabd35f1875dcea89ea97",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolLINK",
    "from": "USDT",
    "to": "aPolLINK",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x191c10aa4af7c30e871e70c95db0e4eb77237530",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolEURS",
    "from": "USDT",
    "to": "aPolEURS",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x38d693ce1df5aadf7bc62595a37d667ad57922e5",
    "from_decimal": 6,
    "to_decimal": 2
  },
  {
    "pair": "USDT/aPolGHST",
    "from": "USDT",
    "to": "aPolGHST",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8eb270e296023e9d92081fdf967ddd7878724424",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolBAL",
    "from": "USDT",
    "to": "aPolBAL",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8ffdf2de812095b1d19cb146e4c004587c0a0692",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolCRV",
    "from": "USDT",
    "to": "aPolCRV",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x513c7e3a9c69ca3e22550ef58ac1c0088e918fff",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolDPI",
    "from": "USDT",
    "to": "aPolDPI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x724dc807b04555b71ed48a6896b6f41593b8c637",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolSUSHI",
    "from": "USDT",
    "to": "aPolSUSHI",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0xc45a479877e1e9dfe9fcd4056c699575a1045daa",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolJEUR",
    "from": "USDT",
    "to": "aPolJEUR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x6533afac2e7bccb20dca161449a13a32d391fb00",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDT/aPolAGEUR",
    "from": "USDT",
    "to": "aPolAGEUR",
    "from_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "to_contract": "0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "LINK/USDM",
    "from": "LINK",
    "to": "USDM",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/USDC",
    "from": "LINK",
    "to": "USDC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/THE",
    "from": "LINK",
    "to": "THE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/VRSW",
    "from": "LINK",
    "to": "VRSW",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/PYM",
    "from": "LINK",
    "to": "PYM",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SDEX",
    "from": "LINK",
    "to": "SDEX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x6899face15c14348e1759371049ab64a3a06bfa6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/RETRO",
    "from": "LINK",
    "to": "RETRO",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/QUICK(NEW)",
    "from": "LINK",
    "to": "QUICK(NEW)",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SFL",
    "from": "LINK",
    "to": "SFL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/AXLUSDC",
    "from": "LINK",
    "to": "AXLUSDC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/USD",
    "from": "LINK",
    "to": "USD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/EURS",
    "from": "LINK",
    "to": "EURS",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/ALI",
    "from": "LINK",
    "to": "ALI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/TETU",
    "from": "LINK",
    "to": "TETU",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/BOB",
    "from": "LINK",
    "to": "BOB",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MVX",
    "from": "LINK",
    "to": "MVX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/GOVI",
    "from": "LINK",
    "to": "GOVI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MMF",
    "from": "LINK",
    "to": "MMF",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/JPYC",
    "from": "LINK",
    "to": "JPYC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/BUSD",
    "from": "LINK",
    "to": "BUSD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MATIC",
    "from": "LINK",
    "to": "MATIC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x0000000000000000000000000000000000001010",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/WMATIC",
    "from": "LINK",
    "to": "WMATIC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/WETH",
    "from": "LINK",
    "to": "WETH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/WBTC",
    "from": "LINK",
    "to": "WBTC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/USDC.e",
    "from": "LINK",
    "to": "USDC.e",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/stMATIC",
    "from": "LINK",
    "to": "stMATIC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/GDDY",
    "from": "LINK",
    "to": "GDDY",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/XY",
    "from": "LINK",
    "to": "XY",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MULTI",
    "from": "LINK",
    "to": "MULTI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/CHAMP",
    "from": "LINK",
    "to": "CHAMP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MASK",
    "from": "LINK",
    "to": "MASK",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/QUICK",
    "from": "LINK",
    "to": "QUICK",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/GHST",
    "from": "LINK",
    "to": "GHST",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/WNT",
    "from": "LINK",
    "to": "WNT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DFYN",
    "from": "LINK",
    "to": "DFYN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/PSP",
    "from": "LINK",
    "to": "PSP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x42d61D766B85431666B39B89C43011f24451bFf6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/ANKR",
    "from": "LINK",
    "to": "ANKR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DFX",
    "from": "LINK",
    "to": "DFX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MUST",
    "from": "LINK",
    "to": "MUST",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SYN",
    "from": "LINK",
    "to": "SYN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/KNC",
    "from": "LINK",
    "to": "KNC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/BAL",
    "from": "LINK",
    "to": "BAL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SUSHI",
    "from": "LINK",
    "to": "SUSHI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DYST",
    "from": "LINK",
    "to": "DYST",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/WOO",
    "from": "LINK",
    "to": "WOO",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MESH",
    "from": "LINK",
    "to": "MESH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/VOXEL",
    "from": "LINK",
    "to": "VOXEL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/ankrMATIC",
    "from": "LINK",
    "to": "ankrMATIC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DAI",
    "from": "LINK",
    "to": "DAI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SAND",
    "from": "LINK",
    "to": "SAND",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SPHERE",
    "from": "LINK",
    "to": "SPHERE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/deUSDC",
    "from": "LINK",
    "to": "deUSDC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/PYR",
    "from": "LINK",
    "to": "PYR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/FRAX",
    "from": "LINK",
    "to": "FRAX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/FXS",
    "from": "LINK",
    "to": "FXS",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MAAAVE",
    "from": "LINK",
    "to": "MAAAVE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/AMAAVE",
    "from": "LINK",
    "to": "AMAAVE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/STG",
    "from": "LINK",
    "to": "STG",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/ICE",
    "from": "LINK",
    "to": "ICE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/GNS",
    "from": "LINK",
    "to": "GNS",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe5417af564e4bfda1c483642db72007871397896",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/RNDR",
    "from": "LINK",
    "to": "RNDR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x61299774020dA444Af134c82fa83E3810b309991",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/PLA",
    "from": "LINK",
    "to": "PLA",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maUSDC",
    "from": "LINK",
    "to": "maUSDC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/AAVE",
    "from": "LINK",
    "to": "AAVE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/USDT",
    "from": "LINK",
    "to": "USDT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/ADDY",
    "from": "LINK",
    "to": "ADDY",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/AGA",
    "from": "LINK",
    "to": "AGA",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "LINK/AGAr",
    "from": "LINK",
    "to": "AGAr",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/CC10",
    "from": "LINK",
    "to": "CC10",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/CEL",
    "from": "LINK",
    "to": "CEL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "LINK/DEFI5",
    "from": "LINK",
    "to": "DEFI5",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DEGEN",
    "from": "LINK",
    "to": "DEGEN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DINO",
    "from": "LINK",
    "to": "DINO",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/FISH",
    "from": "LINK",
    "to": "FISH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/GAJ",
    "from": "LINK",
    "to": "GAJ",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/Krill",
    "from": "LINK",
    "to": "Krill",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/PolyDoge",
    "from": "LINK",
    "to": "PolyDoge",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/PolyMoon",
    "from": "LINK",
    "to": "PolyMoon",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "LINK/SX",
    "from": "LINK",
    "to": "SX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/TEL",
    "from": "LINK",
    "to": "TEL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "LINK/UBT",
    "from": "LINK",
    "to": "UBT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x7FBc10850caE055B27039aF31bD258430e714c62",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/UNI",
    "from": "LINK",
    "to": "UNI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MAI",
    "from": "LINK",
    "to": "MAI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/pSWAMP",
    "from": "LINK",
    "to": "pSWAMP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/polyBUNNY",
    "from": "LINK",
    "to": "polyBUNNY",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/AUMI",
    "from": "LINK",
    "to": "AUMI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/1ART",
    "from": "LINK",
    "to": "1ART",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jEUR",
    "from": "LINK",
    "to": "jEUR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jGBP",
    "from": "LINK",
    "to": "jGBP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x767058f11800fba6a682e73a6e79ec5eb74fac8c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jCHF",
    "from": "LINK",
    "to": "jCHF",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jAUD",
    "from": "LINK",
    "to": "jAUD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xcb7f1ef7246d1497b985f7fc45a1a31f04346133",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jCAD",
    "from": "LINK",
    "to": "jCAD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jCNY",
    "from": "LINK",
    "to": "jCNY",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x84526c812d8f6c4fd6c1a5b68713aff50733e772",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jCOP",
    "from": "LINK",
    "to": "jCOP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe6d222caaed5f5dd73a9713ac91c95782e80acbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jJPY",
    "from": "LINK",
    "to": "jJPY",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jKRW",
    "from": "LINK",
    "to": "jKRW",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xa22f6bc96f13bcc84df36109c973d3c0505a067e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jMXN",
    "from": "LINK",
    "to": "jMXN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xbd1fe73e1f12bd2bc237de9b626f056f21f86427",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jNGN",
    "from": "LINK",
    "to": "jNGN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x182c76e977161f703bb8f111047df6c43cfacc56",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jNZD",
    "from": "LINK",
    "to": "jNZD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x6b526daf03b4c47af2bcc5860b12151823ff70e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jPHP",
    "from": "LINK",
    "to": "jPHP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x486880fb16408b47f928f472f57bec55ac6089d1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jPLN",
    "from": "LINK",
    "to": "jPLN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x08e6d1f0c4877ef2993ad733fc6f1d022d0e9dbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jBRL",
    "from": "LINK",
    "to": "jBRL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xf2f77fe7b8e66571e0fca7104c4d670bf1c8d722",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jSEK",
    "from": "LINK",
    "to": "jSEK",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x197e5d6ccff265ac3e303a34db360ee1429f5d1a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/jSGD",
    "from": "LINK",
    "to": "jSGD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/BIFI",
    "from": "LINK",
    "to": "BIFI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MEME",
    "from": "LINK",
    "to": "MEME",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/SUPER",
    "from": "LINK",
    "to": "SUPER",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xa1428174f516f527fafdd146b883bb4428682737",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MANA",
    "from": "LINK",
    "to": "MANA",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/mUSD",
    "from": "LINK",
    "to": "mUSD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe840b73e5287865eec17d250bfb1536704b43b21",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/YFI",
    "from": "LINK",
    "to": "YFI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xda537104d6a5edd53c6fbba9a898708e465260b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/QUICK(old)",
    "from": "LINK",
    "to": "QUICK(old)",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/renBTC",
    "from": "LINK",
    "to": "renBTC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DfynWMATIC",
    "from": "LINK",
    "to": "DfynWMATIC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/HAWK",
    "from": "LINK",
    "to": "HAWK",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x69cbc7449ee102eb792f1656744bf1a7c1bacb7e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/BPT",
    "from": "LINK",
    "to": "BPT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x6863bd30c9e313b264657b107352ba246f8af8e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/amAAVE",
    "from": "LINK",
    "to": "amAAVE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/amDAI",
    "from": "LINK",
    "to": "amDAI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/amUSDC",
    "from": "LINK",
    "to": "amUSDC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/amUSDT",
    "from": "LINK",
    "to": "amUSDT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/amWBTC",
    "from": "LINK",
    "to": "amWBTC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/amWETH",
    "from": "LINK",
    "to": "amWETH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/amWMATIC",
    "from": "LINK",
    "to": "amWMATIC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/COMP",
    "from": "LINK",
    "to": "COMP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/BTU",
    "from": "LINK",
    "to": "BTU",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/$DG",
    "from": "LINK",
    "to": "$DG",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/0xBTC",
    "from": "LINK",
    "to": "0xBTC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x71b821aa52a49f32eed535fca6eb5aa130085978",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/ARIA20",
    "from": "LINK",
    "to": "ARIA20",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x46f48fbdedaa6f5500993bede9539ef85f4bee8e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/AZUKI",
    "from": "LINK",
    "to": "AZUKI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x7cdc0421469398e0f3aa8890693d86c840ac8931",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/CFI",
    "from": "LINK",
    "to": "CFI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/CTSI",
    "from": "LINK",
    "to": "CTSI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2727ab1c2d22170abc9b595177b2d5c6e1ab7b7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DB",
    "from": "LINK",
    "to": "DB",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x0e59d50add2d90f5111aca875bae0a72d95b4762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DMT",
    "from": "LINK",
    "to": "DMT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DSLA",
    "from": "LINK",
    "to": "DSLA",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/EASY",
    "from": "LINK",
    "to": "EASY",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/ELET",
    "from": "LINK",
    "to": "ELET",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x07738eb4ce8932ca961c815cb12c9d4ab5bd0da4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/ETH",
    "from": "LINK",
    "to": "ETH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/GAME",
    "from": "LINK",
    "to": "GAME",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8d1566569d5b695d44a9a234540f68d393cdc40d",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/HEX",
    "from": "LINK",
    "to": "HEX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/HH",
    "from": "LINK",
    "to": "HH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x521cddc0cba84f14c69c1e99249f781aa73ee0bc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/IGG",
    "from": "LINK",
    "to": "IGG",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/JULIEN",
    "from": "LINK",
    "to": "JULIEN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x268ad27c28601d28b79c792c14e95bd2b7a030f8",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "LINK/LEND",
    "from": "LINK",
    "to": "LEND",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/MONA",
    "from": "LINK",
    "to": "MONA",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/NDR",
    "from": "LINK",
    "to": "NDR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xfb65ef42f7c8a70ff73f627db6e9ba2aff1f20fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/OM",
    "from": "LINK",
    "to": "OM",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/PICKLE",
    "from": "LINK",
    "to": "PICKLE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2b88ad57897a8b496595925f43048301c37615da",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/PPDEX",
    "from": "LINK",
    "to": "PPDEX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/RBAL",
    "from": "LINK",
    "to": "RBAL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SDT",
    "from": "LINK",
    "to": "SDT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SENT",
    "from": "LINK",
    "to": "SENT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x48e3883233461c2ef4cb3fcf419d6db07fb86cea",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/SWAP",
    "from": "LINK",
    "to": "SWAP",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x3809dcdd5dde24b37abe64a5a339784c3323c44f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/SWG",
    "from": "LINK",
    "to": "SWG",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/VISION",
    "from": "LINK",
    "to": "VISION",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/WISE",
    "from": "LINK",
    "to": "WISE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/WRX",
    "from": "LINK",
    "to": "WRX",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/ZUT",
    "from": "LINK",
    "to": "ZUT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe86e8beb7340659dddce61727e500e3a5ad75a90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/ZUZ",
    "from": "LINK",
    "to": "ZUZ",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x232eab56c4fb3f84c6fb0a50c087c74b7b43c6ad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/bBADGER",
    "from": "LINK",
    "to": "bBADGER",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x2628d301b161db70e3dbbac88d9d900ca426ff02",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/bDIGG",
    "from": "LINK",
    "to": "bDIGG",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xfdde616084427f0a231d0664a985e1f820e34693",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/eDAI",
    "from": "LINK",
    "to": "eDAI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/eUSDC",
    "from": "LINK",
    "to": "eUSDC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/eUSDT",
    "from": "LINK",
    "to": "eUSDT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/iFARM",
    "from": "LINK",
    "to": "iFARM",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/mDEF",
    "from": "LINK",
    "to": "mDEF",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x82b6205002ecd05e97642d38d61e2cfeac0e18ce",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "LINK/mOCEAN",
    "from": "LINK",
    "to": "mOCEAN",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/mRBAL",
    "from": "LINK",
    "to": "mRBAL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x66768ad00746ac4d68ded9f64886d55d5243f5ec",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maAAVE",
    "from": "LINK",
    "to": "maAAVE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maDAI",
    "from": "LINK",
    "to": "maDAI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe0b22e0037b130a9f56bbb537684e6fa18192341",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maLINK",
    "from": "LINK",
    "to": "maLINK",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x98ea609569bd25119707451ef982b90e3eb719cd",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maTUSD",
    "from": "LINK",
    "to": "maTUSD",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maUNI",
    "from": "LINK",
    "to": "maUNI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8c8bdbe9cee455732525086264a4bf9cf821c498",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maUSDT",
    "from": "LINK",
    "to": "maUSDT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xdae5f1590db13e3b40423b5b5c5fbf175515910b",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/maWETH",
    "from": "LINK",
    "to": "maWETH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x20d3922b4a1a8560e1ac99fba4fade0c849e2142",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/maYFI",
    "from": "LINK",
    "to": "maYFI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/sd3Crv",
    "from": "LINK",
    "to": "sd3Crv",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x87f0bfee4435ce2b8643b221d0c1cad21f5328b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/sdcrvRenWSBTC",
    "from": "LINK",
    "to": "sdcrvRenWSBTC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe212f92e5af85268b33d2aa587b51f49c3b945be",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/sdeursCRV",
    "from": "LINK",
    "to": "sdeursCRV",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xfbdb45075fb73ca4cc8b83fd6fdb4f9b696b1ba1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/xMARK",
    "from": "LINK",
    "to": "xMARK",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xf153eff70dc0bf3b085134928daeea248d9b30d0",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "LINK/xSDT",
    "from": "LINK",
    "to": "xSDT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xd921f8318cfd786bab1ea7492673f053c518ac04",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/DPI",
    "from": "LINK",
    "to": "DPI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/agEUR",
    "from": "LINK",
    "to": "agEUR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolAAVE",
    "from": "LINK",
    "to": "aPolAAVE",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xf329e36c7bf6e5e86ce2150875a84ce77f477375",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolDAI",
    "from": "LINK",
    "to": "aPolDAI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolUSDC",
    "from": "LINK",
    "to": "aPolUSDC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/aPolUSDT",
    "from": "LINK",
    "to": "aPolUSDT",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x6ab707aca953edaefbc4fd23ba73294241490620",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "LINK/aPolWBTC",
    "from": "LINK",
    "to": "aPolWBTC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x078f358208685046a11c85e8ad32895ded33a249",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "LINK/aPolWETH",
    "from": "LINK",
    "to": "aPolWETH",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolWMATIC",
    "from": "LINK",
    "to": "aPolWMATIC",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x6d80113e533a2c0fe82eabd35f1875dcea89ea97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolLINK",
    "from": "LINK",
    "to": "aPolLINK",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x191c10aa4af7c30e871e70c95db0e4eb77237530",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolEURS",
    "from": "LINK",
    "to": "aPolEURS",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x38d693ce1df5aadf7bc62595a37d667ad57922e5",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "LINK/aPolGHST",
    "from": "LINK",
    "to": "aPolGHST",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8eb270e296023e9d92081fdf967ddd7878724424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolBAL",
    "from": "LINK",
    "to": "aPolBAL",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8ffdf2de812095b1d19cb146e4c004587c0a0692",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolCRV",
    "from": "LINK",
    "to": "aPolCRV",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x513c7e3a9c69ca3e22550ef58ac1c0088e918fff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolDPI",
    "from": "LINK",
    "to": "aPolDPI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x724dc807b04555b71ed48a6896b6f41593b8c637",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolSUSHI",
    "from": "LINK",
    "to": "aPolSUSHI",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0xc45a479877e1e9dfe9fcd4056c699575a1045daa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolJEUR",
    "from": "LINK",
    "to": "aPolJEUR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x6533afac2e7bccb20dca161449a13a32d391fb00",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "LINK/aPolAGEUR",
    "from": "LINK",
    "to": "aPolAGEUR",
    "from_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "to_contract": "0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/USDM",
    "from": "WMATIC",
    "to": "USDM",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/USDC",
    "from": "WMATIC",
    "to": "USDC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/THE",
    "from": "WMATIC",
    "to": "THE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/VRSW",
    "from": "WMATIC",
    "to": "VRSW",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/PYM",
    "from": "WMATIC",
    "to": "PYM",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SDEX",
    "from": "WMATIC",
    "to": "SDEX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x6899face15c14348e1759371049ab64a3a06bfa6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/RETRO",
    "from": "WMATIC",
    "to": "RETRO",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/QUICK(NEW)",
    "from": "WMATIC",
    "to": "QUICK(NEW)",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SFL",
    "from": "WMATIC",
    "to": "SFL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/AXLUSDC",
    "from": "WMATIC",
    "to": "AXLUSDC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/USD",
    "from": "WMATIC",
    "to": "USD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/EURS",
    "from": "WMATIC",
    "to": "EURS",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/ALI",
    "from": "WMATIC",
    "to": "ALI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/TETU",
    "from": "WMATIC",
    "to": "TETU",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/BOB",
    "from": "WMATIC",
    "to": "BOB",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MVX",
    "from": "WMATIC",
    "to": "MVX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/GOVI",
    "from": "WMATIC",
    "to": "GOVI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MMF",
    "from": "WMATIC",
    "to": "MMF",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/JPYC",
    "from": "WMATIC",
    "to": "JPYC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/BUSD",
    "from": "WMATIC",
    "to": "BUSD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MATIC",
    "from": "WMATIC",
    "to": "MATIC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x0000000000000000000000000000000000001010",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/WETH",
    "from": "WMATIC",
    "to": "WETH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/WBTC",
    "from": "WMATIC",
    "to": "WBTC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/USDC.e",
    "from": "WMATIC",
    "to": "USDC.e",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/stMATIC",
    "from": "WMATIC",
    "to": "stMATIC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/GDDY",
    "from": "WMATIC",
    "to": "GDDY",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/XY",
    "from": "WMATIC",
    "to": "XY",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MULTI",
    "from": "WMATIC",
    "to": "MULTI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/CHAMP",
    "from": "WMATIC",
    "to": "CHAMP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MASK",
    "from": "WMATIC",
    "to": "MASK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/QUICK",
    "from": "WMATIC",
    "to": "QUICK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/GHST",
    "from": "WMATIC",
    "to": "GHST",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/WNT",
    "from": "WMATIC",
    "to": "WNT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DFYN",
    "from": "WMATIC",
    "to": "DFYN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/PSP",
    "from": "WMATIC",
    "to": "PSP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x42d61D766B85431666B39B89C43011f24451bFf6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/ANKR",
    "from": "WMATIC",
    "to": "ANKR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DFX",
    "from": "WMATIC",
    "to": "DFX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MUST",
    "from": "WMATIC",
    "to": "MUST",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SYN",
    "from": "WMATIC",
    "to": "SYN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/KNC",
    "from": "WMATIC",
    "to": "KNC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/BAL",
    "from": "WMATIC",
    "to": "BAL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SUSHI",
    "from": "WMATIC",
    "to": "SUSHI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DYST",
    "from": "WMATIC",
    "to": "DYST",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/WOO",
    "from": "WMATIC",
    "to": "WOO",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MESH",
    "from": "WMATIC",
    "to": "MESH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/VOXEL",
    "from": "WMATIC",
    "to": "VOXEL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/ankrMATIC",
    "from": "WMATIC",
    "to": "ankrMATIC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DAI",
    "from": "WMATIC",
    "to": "DAI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SAND",
    "from": "WMATIC",
    "to": "SAND",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SPHERE",
    "from": "WMATIC",
    "to": "SPHERE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/deUSDC",
    "from": "WMATIC",
    "to": "deUSDC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/PYR",
    "from": "WMATIC",
    "to": "PYR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/FRAX",
    "from": "WMATIC",
    "to": "FRAX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/FXS",
    "from": "WMATIC",
    "to": "FXS",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MAAAVE",
    "from": "WMATIC",
    "to": "MAAAVE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/AMAAVE",
    "from": "WMATIC",
    "to": "AMAAVE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/STG",
    "from": "WMATIC",
    "to": "STG",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/ICE",
    "from": "WMATIC",
    "to": "ICE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/GNS",
    "from": "WMATIC",
    "to": "GNS",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe5417af564e4bfda1c483642db72007871397896",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/RNDR",
    "from": "WMATIC",
    "to": "RNDR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x61299774020dA444Af134c82fa83E3810b309991",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/PLA",
    "from": "WMATIC",
    "to": "PLA",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maUSDC",
    "from": "WMATIC",
    "to": "maUSDC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/AAVE",
    "from": "WMATIC",
    "to": "AAVE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/USDT",
    "from": "WMATIC",
    "to": "USDT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/ADDY",
    "from": "WMATIC",
    "to": "ADDY",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/AGA",
    "from": "WMATIC",
    "to": "AGA",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "WMATIC/AGAr",
    "from": "WMATIC",
    "to": "AGAr",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/CC10",
    "from": "WMATIC",
    "to": "CC10",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/CEL",
    "from": "WMATIC",
    "to": "CEL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "WMATIC/DEFI5",
    "from": "WMATIC",
    "to": "DEFI5",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DEGEN",
    "from": "WMATIC",
    "to": "DEGEN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DINO",
    "from": "WMATIC",
    "to": "DINO",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/FISH",
    "from": "WMATIC",
    "to": "FISH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/GAJ",
    "from": "WMATIC",
    "to": "GAJ",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/Krill",
    "from": "WMATIC",
    "to": "Krill",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/LINK",
    "from": "WMATIC",
    "to": "LINK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/PolyDoge",
    "from": "WMATIC",
    "to": "PolyDoge",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/PolyMoon",
    "from": "WMATIC",
    "to": "PolyMoon",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "WMATIC/SX",
    "from": "WMATIC",
    "to": "SX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/TEL",
    "from": "WMATIC",
    "to": "TEL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "WMATIC/UBT",
    "from": "WMATIC",
    "to": "UBT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x7FBc10850caE055B27039aF31bD258430e714c62",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/UNI",
    "from": "WMATIC",
    "to": "UNI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MAI",
    "from": "WMATIC",
    "to": "MAI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/pSWAMP",
    "from": "WMATIC",
    "to": "pSWAMP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/polyBUNNY",
    "from": "WMATIC",
    "to": "polyBUNNY",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/AUMI",
    "from": "WMATIC",
    "to": "AUMI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/1ART",
    "from": "WMATIC",
    "to": "1ART",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jEUR",
    "from": "WMATIC",
    "to": "jEUR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jGBP",
    "from": "WMATIC",
    "to": "jGBP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x767058f11800fba6a682e73a6e79ec5eb74fac8c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jCHF",
    "from": "WMATIC",
    "to": "jCHF",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jAUD",
    "from": "WMATIC",
    "to": "jAUD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xcb7f1ef7246d1497b985f7fc45a1a31f04346133",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jCAD",
    "from": "WMATIC",
    "to": "jCAD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jCNY",
    "from": "WMATIC",
    "to": "jCNY",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x84526c812d8f6c4fd6c1a5b68713aff50733e772",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jCOP",
    "from": "WMATIC",
    "to": "jCOP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe6d222caaed5f5dd73a9713ac91c95782e80acbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jJPY",
    "from": "WMATIC",
    "to": "jJPY",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jKRW",
    "from": "WMATIC",
    "to": "jKRW",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xa22f6bc96f13bcc84df36109c973d3c0505a067e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jMXN",
    "from": "WMATIC",
    "to": "jMXN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xbd1fe73e1f12bd2bc237de9b626f056f21f86427",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jNGN",
    "from": "WMATIC",
    "to": "jNGN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x182c76e977161f703bb8f111047df6c43cfacc56",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jNZD",
    "from": "WMATIC",
    "to": "jNZD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x6b526daf03b4c47af2bcc5860b12151823ff70e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jPHP",
    "from": "WMATIC",
    "to": "jPHP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x486880fb16408b47f928f472f57bec55ac6089d1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jPLN",
    "from": "WMATIC",
    "to": "jPLN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x08e6d1f0c4877ef2993ad733fc6f1d022d0e9dbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jBRL",
    "from": "WMATIC",
    "to": "jBRL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xf2f77fe7b8e66571e0fca7104c4d670bf1c8d722",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jSEK",
    "from": "WMATIC",
    "to": "jSEK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x197e5d6ccff265ac3e303a34db360ee1429f5d1a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/jSGD",
    "from": "WMATIC",
    "to": "jSGD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/BIFI",
    "from": "WMATIC",
    "to": "BIFI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MEME",
    "from": "WMATIC",
    "to": "MEME",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/SUPER",
    "from": "WMATIC",
    "to": "SUPER",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xa1428174f516f527fafdd146b883bb4428682737",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MANA",
    "from": "WMATIC",
    "to": "MANA",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/mUSD",
    "from": "WMATIC",
    "to": "mUSD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe840b73e5287865eec17d250bfb1536704b43b21",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/YFI",
    "from": "WMATIC",
    "to": "YFI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xda537104d6a5edd53c6fbba9a898708e465260b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/QUICK(old)",
    "from": "WMATIC",
    "to": "QUICK(old)",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/renBTC",
    "from": "WMATIC",
    "to": "renBTC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DfynWMATIC",
    "from": "WMATIC",
    "to": "DfynWMATIC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/HAWK",
    "from": "WMATIC",
    "to": "HAWK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x69cbc7449ee102eb792f1656744bf1a7c1bacb7e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/BPT",
    "from": "WMATIC",
    "to": "BPT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x6863bd30c9e313b264657b107352ba246f8af8e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/amAAVE",
    "from": "WMATIC",
    "to": "amAAVE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/amDAI",
    "from": "WMATIC",
    "to": "amDAI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/amUSDC",
    "from": "WMATIC",
    "to": "amUSDC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/amUSDT",
    "from": "WMATIC",
    "to": "amUSDT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/amWBTC",
    "from": "WMATIC",
    "to": "amWBTC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/amWETH",
    "from": "WMATIC",
    "to": "amWETH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/amWMATIC",
    "from": "WMATIC",
    "to": "amWMATIC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/COMP",
    "from": "WMATIC",
    "to": "COMP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/BTU",
    "from": "WMATIC",
    "to": "BTU",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/$DG",
    "from": "WMATIC",
    "to": "$DG",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/0xBTC",
    "from": "WMATIC",
    "to": "0xBTC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x71b821aa52a49f32eed535fca6eb5aa130085978",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/ARIA20",
    "from": "WMATIC",
    "to": "ARIA20",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x46f48fbdedaa6f5500993bede9539ef85f4bee8e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/AZUKI",
    "from": "WMATIC",
    "to": "AZUKI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x7cdc0421469398e0f3aa8890693d86c840ac8931",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/CFI",
    "from": "WMATIC",
    "to": "CFI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/CTSI",
    "from": "WMATIC",
    "to": "CTSI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2727ab1c2d22170abc9b595177b2d5c6e1ab7b7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DB",
    "from": "WMATIC",
    "to": "DB",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x0e59d50add2d90f5111aca875bae0a72d95b4762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DMT",
    "from": "WMATIC",
    "to": "DMT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DSLA",
    "from": "WMATIC",
    "to": "DSLA",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/EASY",
    "from": "WMATIC",
    "to": "EASY",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/ELET",
    "from": "WMATIC",
    "to": "ELET",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x07738eb4ce8932ca961c815cb12c9d4ab5bd0da4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/ETH",
    "from": "WMATIC",
    "to": "ETH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/GAME",
    "from": "WMATIC",
    "to": "GAME",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8d1566569d5b695d44a9a234540f68d393cdc40d",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/HEX",
    "from": "WMATIC",
    "to": "HEX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/HH",
    "from": "WMATIC",
    "to": "HH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x521cddc0cba84f14c69c1e99249f781aa73ee0bc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/IGG",
    "from": "WMATIC",
    "to": "IGG",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/JULIEN",
    "from": "WMATIC",
    "to": "JULIEN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x268ad27c28601d28b79c792c14e95bd2b7a030f8",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "WMATIC/LEND",
    "from": "WMATIC",
    "to": "LEND",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/MONA",
    "from": "WMATIC",
    "to": "MONA",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/NDR",
    "from": "WMATIC",
    "to": "NDR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xfb65ef42f7c8a70ff73f627db6e9ba2aff1f20fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/OM",
    "from": "WMATIC",
    "to": "OM",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/PICKLE",
    "from": "WMATIC",
    "to": "PICKLE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2b88ad57897a8b496595925f43048301c37615da",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/PPDEX",
    "from": "WMATIC",
    "to": "PPDEX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/RBAL",
    "from": "WMATIC",
    "to": "RBAL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SDT",
    "from": "WMATIC",
    "to": "SDT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SENT",
    "from": "WMATIC",
    "to": "SENT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x48e3883233461c2ef4cb3fcf419d6db07fb86cea",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/SWAP",
    "from": "WMATIC",
    "to": "SWAP",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x3809dcdd5dde24b37abe64a5a339784c3323c44f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/SWG",
    "from": "WMATIC",
    "to": "SWG",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/VISION",
    "from": "WMATIC",
    "to": "VISION",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/WISE",
    "from": "WMATIC",
    "to": "WISE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/WRX",
    "from": "WMATIC",
    "to": "WRX",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/ZUT",
    "from": "WMATIC",
    "to": "ZUT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe86e8beb7340659dddce61727e500e3a5ad75a90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/ZUZ",
    "from": "WMATIC",
    "to": "ZUZ",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x232eab56c4fb3f84c6fb0a50c087c74b7b43c6ad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/bBADGER",
    "from": "WMATIC",
    "to": "bBADGER",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x2628d301b161db70e3dbbac88d9d900ca426ff02",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/bDIGG",
    "from": "WMATIC",
    "to": "bDIGG",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xfdde616084427f0a231d0664a985e1f820e34693",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/eDAI",
    "from": "WMATIC",
    "to": "eDAI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/eUSDC",
    "from": "WMATIC",
    "to": "eUSDC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/eUSDT",
    "from": "WMATIC",
    "to": "eUSDT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/iFARM",
    "from": "WMATIC",
    "to": "iFARM",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/mDEF",
    "from": "WMATIC",
    "to": "mDEF",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x82b6205002ecd05e97642d38d61e2cfeac0e18ce",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "WMATIC/mOCEAN",
    "from": "WMATIC",
    "to": "mOCEAN",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/mRBAL",
    "from": "WMATIC",
    "to": "mRBAL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x66768ad00746ac4d68ded9f64886d55d5243f5ec",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maAAVE",
    "from": "WMATIC",
    "to": "maAAVE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maDAI",
    "from": "WMATIC",
    "to": "maDAI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe0b22e0037b130a9f56bbb537684e6fa18192341",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maLINK",
    "from": "WMATIC",
    "to": "maLINK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x98ea609569bd25119707451ef982b90e3eb719cd",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maTUSD",
    "from": "WMATIC",
    "to": "maTUSD",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maUNI",
    "from": "WMATIC",
    "to": "maUNI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8c8bdbe9cee455732525086264a4bf9cf821c498",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maUSDT",
    "from": "WMATIC",
    "to": "maUSDT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xdae5f1590db13e3b40423b5b5c5fbf175515910b",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/maWETH",
    "from": "WMATIC",
    "to": "maWETH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x20d3922b4a1a8560e1ac99fba4fade0c849e2142",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/maYFI",
    "from": "WMATIC",
    "to": "maYFI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/sd3Crv",
    "from": "WMATIC",
    "to": "sd3Crv",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x87f0bfee4435ce2b8643b221d0c1cad21f5328b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/sdcrvRenWSBTC",
    "from": "WMATIC",
    "to": "sdcrvRenWSBTC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe212f92e5af85268b33d2aa587b51f49c3b945be",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/sdeursCRV",
    "from": "WMATIC",
    "to": "sdeursCRV",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xfbdb45075fb73ca4cc8b83fd6fdb4f9b696b1ba1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/xMARK",
    "from": "WMATIC",
    "to": "xMARK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xf153eff70dc0bf3b085134928daeea248d9b30d0",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "WMATIC/xSDT",
    "from": "WMATIC",
    "to": "xSDT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xd921f8318cfd786bab1ea7492673f053c518ac04",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/DPI",
    "from": "WMATIC",
    "to": "DPI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/agEUR",
    "from": "WMATIC",
    "to": "agEUR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolAAVE",
    "from": "WMATIC",
    "to": "aPolAAVE",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xf329e36c7bf6e5e86ce2150875a84ce77f477375",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolDAI",
    "from": "WMATIC",
    "to": "aPolDAI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolUSDC",
    "from": "WMATIC",
    "to": "aPolUSDC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/aPolUSDT",
    "from": "WMATIC",
    "to": "aPolUSDT",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x6ab707aca953edaefbc4fd23ba73294241490620",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "WMATIC/aPolWBTC",
    "from": "WMATIC",
    "to": "aPolWBTC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x078f358208685046a11c85e8ad32895ded33a249",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "WMATIC/aPolWETH",
    "from": "WMATIC",
    "to": "aPolWETH",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolWMATIC",
    "from": "WMATIC",
    "to": "aPolWMATIC",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x6d80113e533a2c0fe82eabd35f1875dcea89ea97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolLINK",
    "from": "WMATIC",
    "to": "aPolLINK",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x191c10aa4af7c30e871e70c95db0e4eb77237530",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolEURS",
    "from": "WMATIC",
    "to": "aPolEURS",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x38d693ce1df5aadf7bc62595a37d667ad57922e5",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "WMATIC/aPolGHST",
    "from": "WMATIC",
    "to": "aPolGHST",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8eb270e296023e9d92081fdf967ddd7878724424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolBAL",
    "from": "WMATIC",
    "to": "aPolBAL",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8ffdf2de812095b1d19cb146e4c004587c0a0692",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolCRV",
    "from": "WMATIC",
    "to": "aPolCRV",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x513c7e3a9c69ca3e22550ef58ac1c0088e918fff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolDPI",
    "from": "WMATIC",
    "to": "aPolDPI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x724dc807b04555b71ed48a6896b6f41593b8c637",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolSUSHI",
    "from": "WMATIC",
    "to": "aPolSUSHI",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0xc45a479877e1e9dfe9fcd4056c699575a1045daa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolJEUR",
    "from": "WMATIC",
    "to": "aPolJEUR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x6533afac2e7bccb20dca161449a13a32d391fb00",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WMATIC/aPolAGEUR",
    "from": "WMATIC",
    "to": "aPolAGEUR",
    "from_contract": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
    "to_contract": "0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/USDM",
    "from": "DAI",
    "to": "USDM",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/USDC",
    "from": "DAI",
    "to": "USDC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/THE",
    "from": "DAI",
    "to": "THE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/VRSW",
    "from": "DAI",
    "to": "VRSW",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/PYM",
    "from": "DAI",
    "to": "PYM",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SDEX",
    "from": "DAI",
    "to": "SDEX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x6899face15c14348e1759371049ab64a3a06bfa6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/RETRO",
    "from": "DAI",
    "to": "RETRO",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/QUICK(NEW)",
    "from": "DAI",
    "to": "QUICK(NEW)",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SFL",
    "from": "DAI",
    "to": "SFL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/AXLUSDC",
    "from": "DAI",
    "to": "AXLUSDC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/USD",
    "from": "DAI",
    "to": "USD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/EURS",
    "from": "DAI",
    "to": "EURS",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/ALI",
    "from": "DAI",
    "to": "ALI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/TETU",
    "from": "DAI",
    "to": "TETU",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/BOB",
    "from": "DAI",
    "to": "BOB",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MVX",
    "from": "DAI",
    "to": "MVX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/GOVI",
    "from": "DAI",
    "to": "GOVI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MMF",
    "from": "DAI",
    "to": "MMF",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/JPYC",
    "from": "DAI",
    "to": "JPYC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/BUSD",
    "from": "DAI",
    "to": "BUSD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MATIC",
    "from": "DAI",
    "to": "MATIC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x0000000000000000000000000000000000001010",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/WMATIC",
    "from": "DAI",
    "to": "WMATIC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/WETH",
    "from": "DAI",
    "to": "WETH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/WBTC",
    "from": "DAI",
    "to": "WBTC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/USDC.e",
    "from": "DAI",
    "to": "USDC.e",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/stMATIC",
    "from": "DAI",
    "to": "stMATIC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/GDDY",
    "from": "DAI",
    "to": "GDDY",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/XY",
    "from": "DAI",
    "to": "XY",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MULTI",
    "from": "DAI",
    "to": "MULTI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/CHAMP",
    "from": "DAI",
    "to": "CHAMP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MASK",
    "from": "DAI",
    "to": "MASK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/QUICK",
    "from": "DAI",
    "to": "QUICK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/GHST",
    "from": "DAI",
    "to": "GHST",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/WNT",
    "from": "DAI",
    "to": "WNT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DFYN",
    "from": "DAI",
    "to": "DFYN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/PSP",
    "from": "DAI",
    "to": "PSP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x42d61D766B85431666B39B89C43011f24451bFf6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/ANKR",
    "from": "DAI",
    "to": "ANKR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DFX",
    "from": "DAI",
    "to": "DFX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MUST",
    "from": "DAI",
    "to": "MUST",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SYN",
    "from": "DAI",
    "to": "SYN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/KNC",
    "from": "DAI",
    "to": "KNC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/BAL",
    "from": "DAI",
    "to": "BAL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SUSHI",
    "from": "DAI",
    "to": "SUSHI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DYST",
    "from": "DAI",
    "to": "DYST",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/WOO",
    "from": "DAI",
    "to": "WOO",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MESH",
    "from": "DAI",
    "to": "MESH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/VOXEL",
    "from": "DAI",
    "to": "VOXEL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/ankrMATIC",
    "from": "DAI",
    "to": "ankrMATIC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SAND",
    "from": "DAI",
    "to": "SAND",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SPHERE",
    "from": "DAI",
    "to": "SPHERE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/deUSDC",
    "from": "DAI",
    "to": "deUSDC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/PYR",
    "from": "DAI",
    "to": "PYR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/FRAX",
    "from": "DAI",
    "to": "FRAX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/FXS",
    "from": "DAI",
    "to": "FXS",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MAAAVE",
    "from": "DAI",
    "to": "MAAAVE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/AMAAVE",
    "from": "DAI",
    "to": "AMAAVE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/STG",
    "from": "DAI",
    "to": "STG",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/ICE",
    "from": "DAI",
    "to": "ICE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/GNS",
    "from": "DAI",
    "to": "GNS",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe5417af564e4bfda1c483642db72007871397896",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/RNDR",
    "from": "DAI",
    "to": "RNDR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x61299774020dA444Af134c82fa83E3810b309991",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/PLA",
    "from": "DAI",
    "to": "PLA",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maUSDC",
    "from": "DAI",
    "to": "maUSDC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/AAVE",
    "from": "DAI",
    "to": "AAVE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/USDT",
    "from": "DAI",
    "to": "USDT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/ADDY",
    "from": "DAI",
    "to": "ADDY",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/AGA",
    "from": "DAI",
    "to": "AGA",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "DAI/AGAr",
    "from": "DAI",
    "to": "AGAr",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/CC10",
    "from": "DAI",
    "to": "CC10",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/CEL",
    "from": "DAI",
    "to": "CEL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "DAI/DEFI5",
    "from": "DAI",
    "to": "DEFI5",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DEGEN",
    "from": "DAI",
    "to": "DEGEN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DINO",
    "from": "DAI",
    "to": "DINO",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/FISH",
    "from": "DAI",
    "to": "FISH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/GAJ",
    "from": "DAI",
    "to": "GAJ",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/Krill",
    "from": "DAI",
    "to": "Krill",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/LINK",
    "from": "DAI",
    "to": "LINK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/PolyDoge",
    "from": "DAI",
    "to": "PolyDoge",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/PolyMoon",
    "from": "DAI",
    "to": "PolyMoon",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "DAI/SX",
    "from": "DAI",
    "to": "SX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/TEL",
    "from": "DAI",
    "to": "TEL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "DAI/UBT",
    "from": "DAI",
    "to": "UBT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x7FBc10850caE055B27039aF31bD258430e714c62",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/UNI",
    "from": "DAI",
    "to": "UNI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MAI",
    "from": "DAI",
    "to": "MAI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/pSWAMP",
    "from": "DAI",
    "to": "pSWAMP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/polyBUNNY",
    "from": "DAI",
    "to": "polyBUNNY",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/AUMI",
    "from": "DAI",
    "to": "AUMI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/1ART",
    "from": "DAI",
    "to": "1ART",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jEUR",
    "from": "DAI",
    "to": "jEUR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jGBP",
    "from": "DAI",
    "to": "jGBP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x767058f11800fba6a682e73a6e79ec5eb74fac8c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jCHF",
    "from": "DAI",
    "to": "jCHF",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jAUD",
    "from": "DAI",
    "to": "jAUD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xcb7f1ef7246d1497b985f7fc45a1a31f04346133",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jCAD",
    "from": "DAI",
    "to": "jCAD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jCNY",
    "from": "DAI",
    "to": "jCNY",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x84526c812d8f6c4fd6c1a5b68713aff50733e772",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jCOP",
    "from": "DAI",
    "to": "jCOP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe6d222caaed5f5dd73a9713ac91c95782e80acbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jJPY",
    "from": "DAI",
    "to": "jJPY",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jKRW",
    "from": "DAI",
    "to": "jKRW",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xa22f6bc96f13bcc84df36109c973d3c0505a067e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jMXN",
    "from": "DAI",
    "to": "jMXN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xbd1fe73e1f12bd2bc237de9b626f056f21f86427",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jNGN",
    "from": "DAI",
    "to": "jNGN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x182c76e977161f703bb8f111047df6c43cfacc56",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jNZD",
    "from": "DAI",
    "to": "jNZD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x6b526daf03b4c47af2bcc5860b12151823ff70e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jPHP",
    "from": "DAI",
    "to": "jPHP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x486880fb16408b47f928f472f57bec55ac6089d1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jPLN",
    "from": "DAI",
    "to": "jPLN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x08e6d1f0c4877ef2993ad733fc6f1d022d0e9dbf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jBRL",
    "from": "DAI",
    "to": "jBRL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xf2f77fe7b8e66571e0fca7104c4d670bf1c8d722",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jSEK",
    "from": "DAI",
    "to": "jSEK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x197e5d6ccff265ac3e303a34db360ee1429f5d1a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/jSGD",
    "from": "DAI",
    "to": "jSGD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/BIFI",
    "from": "DAI",
    "to": "BIFI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MEME",
    "from": "DAI",
    "to": "MEME",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/SUPER",
    "from": "DAI",
    "to": "SUPER",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xa1428174f516f527fafdd146b883bb4428682737",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MANA",
    "from": "DAI",
    "to": "MANA",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/mUSD",
    "from": "DAI",
    "to": "mUSD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe840b73e5287865eec17d250bfb1536704b43b21",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/YFI",
    "from": "DAI",
    "to": "YFI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xda537104d6a5edd53c6fbba9a898708e465260b6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/QUICK(old)",
    "from": "DAI",
    "to": "QUICK(old)",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/renBTC",
    "from": "DAI",
    "to": "renBTC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DfynWMATIC",
    "from": "DAI",
    "to": "DfynWMATIC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/HAWK",
    "from": "DAI",
    "to": "HAWK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x69cbc7449ee102eb792f1656744bf1a7c1bacb7e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/BPT",
    "from": "DAI",
    "to": "BPT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x6863bd30c9e313b264657b107352ba246f8af8e0",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/amAAVE",
    "from": "DAI",
    "to": "amAAVE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/amDAI",
    "from": "DAI",
    "to": "amDAI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/amUSDC",
    "from": "DAI",
    "to": "amUSDC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/amUSDT",
    "from": "DAI",
    "to": "amUSDT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/amWBTC",
    "from": "DAI",
    "to": "amWBTC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/amWETH",
    "from": "DAI",
    "to": "amWETH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/amWMATIC",
    "from": "DAI",
    "to": "amWMATIC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/COMP",
    "from": "DAI",
    "to": "COMP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/BTU",
    "from": "DAI",
    "to": "BTU",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/$DG",
    "from": "DAI",
    "to": "$DG",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/0xBTC",
    "from": "DAI",
    "to": "0xBTC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x71b821aa52a49f32eed535fca6eb5aa130085978",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/ARIA20",
    "from": "DAI",
    "to": "ARIA20",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x46f48fbdedaa6f5500993bede9539ef85f4bee8e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/AZUKI",
    "from": "DAI",
    "to": "AZUKI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x7cdc0421469398e0f3aa8890693d86c840ac8931",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/CFI",
    "from": "DAI",
    "to": "CFI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/CTSI",
    "from": "DAI",
    "to": "CTSI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2727ab1c2d22170abc9b595177b2d5c6e1ab7b7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DB",
    "from": "DAI",
    "to": "DB",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x0e59d50add2d90f5111aca875bae0a72d95b4762",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DMT",
    "from": "DAI",
    "to": "DMT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DSLA",
    "from": "DAI",
    "to": "DSLA",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/EASY",
    "from": "DAI",
    "to": "EASY",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/ELET",
    "from": "DAI",
    "to": "ELET",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x07738eb4ce8932ca961c815cb12c9d4ab5bd0da4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/ETH",
    "from": "DAI",
    "to": "ETH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/GAME",
    "from": "DAI",
    "to": "GAME",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8d1566569d5b695d44a9a234540f68d393cdc40d",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/HEX",
    "from": "DAI",
    "to": "HEX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/HH",
    "from": "DAI",
    "to": "HH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x521cddc0cba84f14c69c1e99249f781aa73ee0bc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/IGG",
    "from": "DAI",
    "to": "IGG",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/JULIEN",
    "from": "DAI",
    "to": "JULIEN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x268ad27c28601d28b79c792c14e95bd2b7a030f8",
    "from_decimal": 18,
    "to_decimal": 4
  },
  {
    "pair": "DAI/LEND",
    "from": "DAI",
    "to": "LEND",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/MONA",
    "from": "DAI",
    "to": "MONA",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/NDR",
    "from": "DAI",
    "to": "NDR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xfb65ef42f7c8a70ff73f627db6e9ba2aff1f20fa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/OM",
    "from": "DAI",
    "to": "OM",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/PICKLE",
    "from": "DAI",
    "to": "PICKLE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2b88ad57897a8b496595925f43048301c37615da",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/PPDEX",
    "from": "DAI",
    "to": "PPDEX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/RBAL",
    "from": "DAI",
    "to": "RBAL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SDT",
    "from": "DAI",
    "to": "SDT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SENT",
    "from": "DAI",
    "to": "SENT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x48e3883233461c2ef4cb3fcf419d6db07fb86cea",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/SWAP",
    "from": "DAI",
    "to": "SWAP",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x3809dcdd5dde24b37abe64a5a339784c3323c44f",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/SWG",
    "from": "DAI",
    "to": "SWG",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/VISION",
    "from": "DAI",
    "to": "VISION",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/WISE",
    "from": "DAI",
    "to": "WISE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/WRX",
    "from": "DAI",
    "to": "WRX",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/ZUT",
    "from": "DAI",
    "to": "ZUT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe86e8beb7340659dddce61727e500e3a5ad75a90",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/ZUZ",
    "from": "DAI",
    "to": "ZUZ",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x232eab56c4fb3f84c6fb0a50c087c74b7b43c6ad",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/bBADGER",
    "from": "DAI",
    "to": "bBADGER",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x2628d301b161db70e3dbbac88d9d900ca426ff02",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/bDIGG",
    "from": "DAI",
    "to": "bDIGG",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xfdde616084427f0a231d0664a985e1f820e34693",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/eDAI",
    "from": "DAI",
    "to": "eDAI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/eUSDC",
    "from": "DAI",
    "to": "eUSDC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/eUSDT",
    "from": "DAI",
    "to": "eUSDT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/iFARM",
    "from": "DAI",
    "to": "iFARM",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/mDEF",
    "from": "DAI",
    "to": "mDEF",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x82b6205002ecd05e97642d38d61e2cfeac0e18ce",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "DAI/mOCEAN",
    "from": "DAI",
    "to": "mOCEAN",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/mRBAL",
    "from": "DAI",
    "to": "mRBAL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x66768ad00746ac4d68ded9f64886d55d5243f5ec",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maAAVE",
    "from": "DAI",
    "to": "maAAVE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maDAI",
    "from": "DAI",
    "to": "maDAI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe0b22e0037b130a9f56bbb537684e6fa18192341",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maLINK",
    "from": "DAI",
    "to": "maLINK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x98ea609569bd25119707451ef982b90e3eb719cd",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maTUSD",
    "from": "DAI",
    "to": "maTUSD",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maUNI",
    "from": "DAI",
    "to": "maUNI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8c8bdbe9cee455732525086264a4bf9cf821c498",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maUSDT",
    "from": "DAI",
    "to": "maUSDT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xdae5f1590db13e3b40423b5b5c5fbf175515910b",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/maWETH",
    "from": "DAI",
    "to": "maWETH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x20d3922b4a1a8560e1ac99fba4fade0c849e2142",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/maYFI",
    "from": "DAI",
    "to": "maYFI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/sd3Crv",
    "from": "DAI",
    "to": "sd3Crv",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x87f0bfee4435ce2b8643b221d0c1cad21f5328b4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/sdcrvRenWSBTC",
    "from": "DAI",
    "to": "sdcrvRenWSBTC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe212f92e5af85268b33d2aa587b51f49c3b945be",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/sdeursCRV",
    "from": "DAI",
    "to": "sdeursCRV",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xfbdb45075fb73ca4cc8b83fd6fdb4f9b696b1ba1",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/xMARK",
    "from": "DAI",
    "to": "xMARK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xf153eff70dc0bf3b085134928daeea248d9b30d0",
    "from_decimal": 18,
    "to_decimal": 9
  },
  {
    "pair": "DAI/xSDT",
    "from": "DAI",
    "to": "xSDT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xd921f8318cfd786bab1ea7492673f053c518ac04",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/DPI",
    "from": "DAI",
    "to": "DPI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/agEUR",
    "from": "DAI",
    "to": "agEUR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolAAVE",
    "from": "DAI",
    "to": "aPolAAVE",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xf329e36c7bf6e5e86ce2150875a84ce77f477375",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolDAI",
    "from": "DAI",
    "to": "aPolDAI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolUSDC",
    "from": "DAI",
    "to": "aPolUSDC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/aPolUSDT",
    "from": "DAI",
    "to": "aPolUSDT",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x6ab707aca953edaefbc4fd23ba73294241490620",
    "from_decimal": 18,
    "to_decimal": 6
  },
  {
    "pair": "DAI/aPolWBTC",
    "from": "DAI",
    "to": "aPolWBTC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x078f358208685046a11c85e8ad32895ded33a249",
    "from_decimal": 18,
    "to_decimal": 8
  },
  {
    "pair": "DAI/aPolWETH",
    "from": "DAI",
    "to": "aPolWETH",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolWMATIC",
    "from": "DAI",
    "to": "aPolWMATIC",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x6d80113e533a2c0fe82eabd35f1875dcea89ea97",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolLINK",
    "from": "DAI",
    "to": "aPolLINK",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x191c10aa4af7c30e871e70c95db0e4eb77237530",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolEURS",
    "from": "DAI",
    "to": "aPolEURS",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x38d693ce1df5aadf7bc62595a37d667ad57922e5",
    "from_decimal": 18,
    "to_decimal": 2
  },
  {
    "pair": "DAI/aPolGHST",
    "from": "DAI",
    "to": "aPolGHST",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8eb270e296023e9d92081fdf967ddd7878724424",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolBAL",
    "from": "DAI",
    "to": "aPolBAL",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8ffdf2de812095b1d19cb146e4c004587c0a0692",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolCRV",
    "from": "DAI",
    "to": "aPolCRV",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x513c7e3a9c69ca3e22550ef58ac1c0088e918fff",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolDPI",
    "from": "DAI",
    "to": "aPolDPI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x724dc807b04555b71ed48a6896b6f41593b8c637",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolSUSHI",
    "from": "DAI",
    "to": "aPolSUSHI",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0xc45a479877e1e9dfe9fcd4056c699575a1045daa",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolJEUR",
    "from": "DAI",
    "to": "aPolJEUR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x6533afac2e7bccb20dca161449a13a32d391fb00",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "DAI/aPolAGEUR",
    "from": "DAI",
    "to": "aPolAGEUR",
    "from_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "to_contract": "0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77",
    "from_decimal": 18,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/USDM",
    "from": "WBTC",
    "to": "USDM",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/USDC",
    "from": "WBTC",
    "to": "USDC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/THE",
    "from": "WBTC",
    "to": "THE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/VRSW",
    "from": "WBTC",
    "to": "VRSW",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/PYM",
    "from": "WBTC",
    "to": "PYM",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SDEX",
    "from": "WBTC",
    "to": "SDEX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x6899face15c14348e1759371049ab64a3a06bfa6",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/RETRO",
    "from": "WBTC",
    "to": "RETRO",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/QUICK(NEW)",
    "from": "WBTC",
    "to": "QUICK(NEW)",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SFL",
    "from": "WBTC",
    "to": "SFL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/AXLUSDC",
    "from": "WBTC",
    "to": "AXLUSDC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/USD",
    "from": "WBTC",
    "to": "USD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/EURS",
    "from": "WBTC",
    "to": "EURS",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/ALI",
    "from": "WBTC",
    "to": "ALI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/TETU",
    "from": "WBTC",
    "to": "TETU",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/BOB",
    "from": "WBTC",
    "to": "BOB",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MVX",
    "from": "WBTC",
    "to": "MVX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/GOVI",
    "from": "WBTC",
    "to": "GOVI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MMF",
    "from": "WBTC",
    "to": "MMF",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/JPYC",
    "from": "WBTC",
    "to": "JPYC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/BUSD",
    "from": "WBTC",
    "to": "BUSD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MATIC",
    "from": "WBTC",
    "to": "MATIC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x0000000000000000000000000000000000001010",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/WMATIC",
    "from": "WBTC",
    "to": "WMATIC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/WETH",
    "from": "WBTC",
    "to": "WETH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/USDC.e",
    "from": "WBTC",
    "to": "USDC.e",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/stMATIC",
    "from": "WBTC",
    "to": "stMATIC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/GDDY",
    "from": "WBTC",
    "to": "GDDY",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/XY",
    "from": "WBTC",
    "to": "XY",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MULTI",
    "from": "WBTC",
    "to": "MULTI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/CHAMP",
    "from": "WBTC",
    "to": "CHAMP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MASK",
    "from": "WBTC",
    "to": "MASK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/QUICK",
    "from": "WBTC",
    "to": "QUICK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/GHST",
    "from": "WBTC",
    "to": "GHST",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/WNT",
    "from": "WBTC",
    "to": "WNT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DFYN",
    "from": "WBTC",
    "to": "DFYN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/PSP",
    "from": "WBTC",
    "to": "PSP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x42d61D766B85431666B39B89C43011f24451bFf6",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/ANKR",
    "from": "WBTC",
    "to": "ANKR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DFX",
    "from": "WBTC",
    "to": "DFX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MUST",
    "from": "WBTC",
    "to": "MUST",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SYN",
    "from": "WBTC",
    "to": "SYN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/KNC",
    "from": "WBTC",
    "to": "KNC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/BAL",
    "from": "WBTC",
    "to": "BAL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SUSHI",
    "from": "WBTC",
    "to": "SUSHI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DYST",
    "from": "WBTC",
    "to": "DYST",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/WOO",
    "from": "WBTC",
    "to": "WOO",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MESH",
    "from": "WBTC",
    "to": "MESH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/VOXEL",
    "from": "WBTC",
    "to": "VOXEL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/ankrMATIC",
    "from": "WBTC",
    "to": "ankrMATIC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DAI",
    "from": "WBTC",
    "to": "DAI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SAND",
    "from": "WBTC",
    "to": "SAND",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SPHERE",
    "from": "WBTC",
    "to": "SPHERE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/deUSDC",
    "from": "WBTC",
    "to": "deUSDC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/PYR",
    "from": "WBTC",
    "to": "PYR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/FRAX",
    "from": "WBTC",
    "to": "FRAX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/FXS",
    "from": "WBTC",
    "to": "FXS",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MAAAVE",
    "from": "WBTC",
    "to": "MAAAVE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/AMAAVE",
    "from": "WBTC",
    "to": "AMAAVE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/STG",
    "from": "WBTC",
    "to": "STG",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/ICE",
    "from": "WBTC",
    "to": "ICE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/GNS",
    "from": "WBTC",
    "to": "GNS",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe5417af564e4bfda1c483642db72007871397896",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/RNDR",
    "from": "WBTC",
    "to": "RNDR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x61299774020dA444Af134c82fa83E3810b309991",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/PLA",
    "from": "WBTC",
    "to": "PLA",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maUSDC",
    "from": "WBTC",
    "to": "maUSDC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/AAVE",
    "from": "WBTC",
    "to": "AAVE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/USDT",
    "from": "WBTC",
    "to": "USDT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/ADDY",
    "from": "WBTC",
    "to": "ADDY",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/AGA",
    "from": "WBTC",
    "to": "AGA",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
    "from_decimal": 8,
    "to_decimal": 4
  },
  {
    "pair": "WBTC/AGAr",
    "from": "WBTC",
    "to": "AGAr",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/CC10",
    "from": "WBTC",
    "to": "CC10",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/CEL",
    "from": "WBTC",
    "to": "CEL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
    "from_decimal": 8,
    "to_decimal": 4
  },
  {
    "pair": "WBTC/DEFI5",
    "from": "WBTC",
    "to": "DEFI5",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DEGEN",
    "from": "WBTC",
    "to": "DEGEN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DINO",
    "from": "WBTC",
    "to": "DINO",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/FISH",
    "from": "WBTC",
    "to": "FISH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/GAJ",
    "from": "WBTC",
    "to": "GAJ",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/Krill",
    "from": "WBTC",
    "to": "Krill",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/LINK",
    "from": "WBTC",
    "to": "LINK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/PolyDoge",
    "from": "WBTC",
    "to": "PolyDoge",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/PolyMoon",
    "from": "WBTC",
    "to": "PolyMoon",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
    "from_decimal": 8,
    "to_decimal": 9
  },
  {
    "pair": "WBTC/SX",
    "from": "WBTC",
    "to": "SX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/TEL",
    "from": "WBTC",
    "to": "TEL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
    "from_decimal": 8,
    "to_decimal": 2
  },
  {
    "pair": "WBTC/UBT",
    "from": "WBTC",
    "to": "UBT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x7FBc10850caE055B27039aF31bD258430e714c62",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/UNI",
    "from": "WBTC",
    "to": "UNI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MAI",
    "from": "WBTC",
    "to": "MAI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/pSWAMP",
    "from": "WBTC",
    "to": "pSWAMP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/polyBUNNY",
    "from": "WBTC",
    "to": "polyBUNNY",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/AUMI",
    "from": "WBTC",
    "to": "AUMI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/1ART",
    "from": "WBTC",
    "to": "1ART",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jEUR",
    "from": "WBTC",
    "to": "jEUR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jGBP",
    "from": "WBTC",
    "to": "jGBP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x767058f11800fba6a682e73a6e79ec5eb74fac8c",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jCHF",
    "from": "WBTC",
    "to": "jCHF",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jAUD",
    "from": "WBTC",
    "to": "jAUD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xcb7f1ef7246d1497b985f7fc45a1a31f04346133",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jCAD",
    "from": "WBTC",
    "to": "jCAD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jCNY",
    "from": "WBTC",
    "to": "jCNY",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x84526c812d8f6c4fd6c1a5b68713aff50733e772",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jCOP",
    "from": "WBTC",
    "to": "jCOP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe6d222caaed5f5dd73a9713ac91c95782e80acbf",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jJPY",
    "from": "WBTC",
    "to": "jJPY",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jKRW",
    "from": "WBTC",
    "to": "jKRW",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xa22f6bc96f13bcc84df36109c973d3c0505a067e",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jMXN",
    "from": "WBTC",
    "to": "jMXN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xbd1fe73e1f12bd2bc237de9b626f056f21f86427",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jNGN",
    "from": "WBTC",
    "to": "jNGN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x182c76e977161f703bb8f111047df6c43cfacc56",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jNZD",
    "from": "WBTC",
    "to": "jNZD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x6b526daf03b4c47af2bcc5860b12151823ff70e0",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jPHP",
    "from": "WBTC",
    "to": "jPHP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x486880fb16408b47f928f472f57bec55ac6089d1",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jPLN",
    "from": "WBTC",
    "to": "jPLN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x08e6d1f0c4877ef2993ad733fc6f1d022d0e9dbf",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jBRL",
    "from": "WBTC",
    "to": "jBRL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xf2f77fe7b8e66571e0fca7104c4d670bf1c8d722",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jSEK",
    "from": "WBTC",
    "to": "jSEK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x197e5d6ccff265ac3e303a34db360ee1429f5d1a",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/jSGD",
    "from": "WBTC",
    "to": "jSGD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/BIFI",
    "from": "WBTC",
    "to": "BIFI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MEME",
    "from": "WBTC",
    "to": "MEME",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/SUPER",
    "from": "WBTC",
    "to": "SUPER",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xa1428174f516f527fafdd146b883bb4428682737",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MANA",
    "from": "WBTC",
    "to": "MANA",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/mUSD",
    "from": "WBTC",
    "to": "mUSD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe840b73e5287865eec17d250bfb1536704b43b21",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/YFI",
    "from": "WBTC",
    "to": "YFI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xda537104d6a5edd53c6fbba9a898708e465260b6",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/QUICK(old)",
    "from": "WBTC",
    "to": "QUICK(old)",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/renBTC",
    "from": "WBTC",
    "to": "renBTC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DfynWMATIC",
    "from": "WBTC",
    "to": "DfynWMATIC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/HAWK",
    "from": "WBTC",
    "to": "HAWK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x69cbc7449ee102eb792f1656744bf1a7c1bacb7e",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/BPT",
    "from": "WBTC",
    "to": "BPT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x6863bd30c9e313b264657b107352ba246f8af8e0",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/amAAVE",
    "from": "WBTC",
    "to": "amAAVE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/amDAI",
    "from": "WBTC",
    "to": "amDAI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/amUSDC",
    "from": "WBTC",
    "to": "amUSDC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/amUSDT",
    "from": "WBTC",
    "to": "amUSDT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/amWBTC",
    "from": "WBTC",
    "to": "amWBTC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/amWETH",
    "from": "WBTC",
    "to": "amWETH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/amWMATIC",
    "from": "WBTC",
    "to": "amWMATIC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/COMP",
    "from": "WBTC",
    "to": "COMP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/BTU",
    "from": "WBTC",
    "to": "BTU",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/$DG",
    "from": "WBTC",
    "to": "$DG",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/0xBTC",
    "from": "WBTC",
    "to": "0xBTC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x71b821aa52a49f32eed535fca6eb5aa130085978",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/ARIA20",
    "from": "WBTC",
    "to": "ARIA20",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x46f48fbdedaa6f5500993bede9539ef85f4bee8e",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/AZUKI",
    "from": "WBTC",
    "to": "AZUKI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x7cdc0421469398e0f3aa8890693d86c840ac8931",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/CFI",
    "from": "WBTC",
    "to": "CFI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/CTSI",
    "from": "WBTC",
    "to": "CTSI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2727ab1c2d22170abc9b595177b2d5c6e1ab7b7b",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DB",
    "from": "WBTC",
    "to": "DB",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x0e59d50add2d90f5111aca875bae0a72d95b4762",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DMT",
    "from": "WBTC",
    "to": "DMT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DSLA",
    "from": "WBTC",
    "to": "DSLA",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/EASY",
    "from": "WBTC",
    "to": "EASY",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/ELET",
    "from": "WBTC",
    "to": "ELET",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x07738eb4ce8932ca961c815cb12c9d4ab5bd0da4",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/ETH",
    "from": "WBTC",
    "to": "ETH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/GAME",
    "from": "WBTC",
    "to": "GAME",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8d1566569d5b695d44a9a234540f68d393cdc40d",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/HEX",
    "from": "WBTC",
    "to": "HEX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/HH",
    "from": "WBTC",
    "to": "HH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x521cddc0cba84f14c69c1e99249f781aa73ee0bc",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/IGG",
    "from": "WBTC",
    "to": "IGG",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/JULIEN",
    "from": "WBTC",
    "to": "JULIEN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x268ad27c28601d28b79c792c14e95bd2b7a030f8",
    "from_decimal": 8,
    "to_decimal": 4
  },
  {
    "pair": "WBTC/LEND",
    "from": "WBTC",
    "to": "LEND",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/MONA",
    "from": "WBTC",
    "to": "MONA",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/NDR",
    "from": "WBTC",
    "to": "NDR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xfb65ef42f7c8a70ff73f627db6e9ba2aff1f20fa",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/OM",
    "from": "WBTC",
    "to": "OM",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/PICKLE",
    "from": "WBTC",
    "to": "PICKLE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2b88ad57897a8b496595925f43048301c37615da",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/PPDEX",
    "from": "WBTC",
    "to": "PPDEX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/RBAL",
    "from": "WBTC",
    "to": "RBAL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SDT",
    "from": "WBTC",
    "to": "SDT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SENT",
    "from": "WBTC",
    "to": "SENT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x48e3883233461c2ef4cb3fcf419d6db07fb86cea",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/SWAP",
    "from": "WBTC",
    "to": "SWAP",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x3809dcdd5dde24b37abe64a5a339784c3323c44f",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/SWG",
    "from": "WBTC",
    "to": "SWG",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/VISION",
    "from": "WBTC",
    "to": "VISION",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/WISE",
    "from": "WBTC",
    "to": "WISE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/WRX",
    "from": "WBTC",
    "to": "WRX",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/ZUT",
    "from": "WBTC",
    "to": "ZUT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe86e8beb7340659dddce61727e500e3a5ad75a90",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/ZUZ",
    "from": "WBTC",
    "to": "ZUZ",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x232eab56c4fb3f84c6fb0a50c087c74b7b43c6ad",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/bBADGER",
    "from": "WBTC",
    "to": "bBADGER",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x2628d301b161db70e3dbbac88d9d900ca426ff02",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/bDIGG",
    "from": "WBTC",
    "to": "bDIGG",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xfdde616084427f0a231d0664a985e1f820e34693",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/eDAI",
    "from": "WBTC",
    "to": "eDAI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/eUSDC",
    "from": "WBTC",
    "to": "eUSDC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/eUSDT",
    "from": "WBTC",
    "to": "eUSDT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/iFARM",
    "from": "WBTC",
    "to": "iFARM",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/mDEF",
    "from": "WBTC",
    "to": "mDEF",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x82b6205002ecd05e97642d38d61e2cfeac0e18ce",
    "from_decimal": 8,
    "to_decimal": 9
  },
  {
    "pair": "WBTC/mOCEAN",
    "from": "WBTC",
    "to": "mOCEAN",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/mRBAL",
    "from": "WBTC",
    "to": "mRBAL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x66768ad00746ac4d68ded9f64886d55d5243f5ec",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maAAVE",
    "from": "WBTC",
    "to": "maAAVE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maDAI",
    "from": "WBTC",
    "to": "maDAI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe0b22e0037b130a9f56bbb537684e6fa18192341",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maLINK",
    "from": "WBTC",
    "to": "maLINK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x98ea609569bd25119707451ef982b90e3eb719cd",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maTUSD",
    "from": "WBTC",
    "to": "maTUSD",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maUNI",
    "from": "WBTC",
    "to": "maUNI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8c8bdbe9cee455732525086264a4bf9cf821c498",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maUSDT",
    "from": "WBTC",
    "to": "maUSDT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xdae5f1590db13e3b40423b5b5c5fbf175515910b",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/maWETH",
    "from": "WBTC",
    "to": "maWETH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x20d3922b4a1a8560e1ac99fba4fade0c849e2142",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/maYFI",
    "from": "WBTC",
    "to": "maYFI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/sd3Crv",
    "from": "WBTC",
    "to": "sd3Crv",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x87f0bfee4435ce2b8643b221d0c1cad21f5328b4",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/sdcrvRenWSBTC",
    "from": "WBTC",
    "to": "sdcrvRenWSBTC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe212f92e5af85268b33d2aa587b51f49c3b945be",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/sdeursCRV",
    "from": "WBTC",
    "to": "sdeursCRV",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xfbdb45075fb73ca4cc8b83fd6fdb4f9b696b1ba1",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/xMARK",
    "from": "WBTC",
    "to": "xMARK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xf153eff70dc0bf3b085134928daeea248d9b30d0",
    "from_decimal": 8,
    "to_decimal": 9
  },
  {
    "pair": "WBTC/xSDT",
    "from": "WBTC",
    "to": "xSDT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xd921f8318cfd786bab1ea7492673f053c518ac04",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/DPI",
    "from": "WBTC",
    "to": "DPI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/agEUR",
    "from": "WBTC",
    "to": "agEUR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolAAVE",
    "from": "WBTC",
    "to": "aPolAAVE",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xf329e36c7bf6e5e86ce2150875a84ce77f477375",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolDAI",
    "from": "WBTC",
    "to": "aPolDAI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolUSDC",
    "from": "WBTC",
    "to": "aPolUSDC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/aPolUSDT",
    "from": "WBTC",
    "to": "aPolUSDT",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x6ab707aca953edaefbc4fd23ba73294241490620",
    "from_decimal": 8,
    "to_decimal": 6
  },
  {
    "pair": "WBTC/aPolWBTC",
    "from": "WBTC",
    "to": "aPolWBTC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x078f358208685046a11c85e8ad32895ded33a249",
    "from_decimal": 8,
    "to_decimal": 8
  },
  {
    "pair": "WBTC/aPolWETH",
    "from": "WBTC",
    "to": "aPolWETH",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolWMATIC",
    "from": "WBTC",
    "to": "aPolWMATIC",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x6d80113e533a2c0fe82eabd35f1875dcea89ea97",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolLINK",
    "from": "WBTC",
    "to": "aPolLINK",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x191c10aa4af7c30e871e70c95db0e4eb77237530",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolEURS",
    "from": "WBTC",
    "to": "aPolEURS",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x38d693ce1df5aadf7bc62595a37d667ad57922e5",
    "from_decimal": 8,
    "to_decimal": 2
  },
  {
    "pair": "WBTC/aPolGHST",
    "from": "WBTC",
    "to": "aPolGHST",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8eb270e296023e9d92081fdf967ddd7878724424",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolBAL",
    "from": "WBTC",
    "to": "aPolBAL",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8ffdf2de812095b1d19cb146e4c004587c0a0692",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolCRV",
    "from": "WBTC",
    "to": "aPolCRV",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x513c7e3a9c69ca3e22550ef58ac1c0088e918fff",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolDPI",
    "from": "WBTC",
    "to": "aPolDPI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x724dc807b04555b71ed48a6896b6f41593b8c637",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolSUSHI",
    "from": "WBTC",
    "to": "aPolSUSHI",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0xc45a479877e1e9dfe9fcd4056c699575a1045daa",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolJEUR",
    "from": "WBTC",
    "to": "aPolJEUR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x6533afac2e7bccb20dca161449a13a32d391fb00",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "WBTC/aPolAGEUR",
    "from": "WBTC",
    "to": "aPolAGEUR",
    "from_contract": "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "to_contract": "0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77",
    "from_decimal": 8,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/USDM",
    "from": "USDC.e",
    "to": "USDM",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/USDC",
    "from": "USDC.e",
    "to": "USDC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/THE",
    "from": "USDC.e",
    "to": "THE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/VRSW",
    "from": "USDC.e",
    "to": "VRSW",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/PYM",
    "from": "USDC.e",
    "to": "PYM",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SDEX",
    "from": "USDC.e",
    "to": "SDEX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x6899face15c14348e1759371049ab64a3a06bfa6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/RETRO",
    "from": "USDC.e",
    "to": "RETRO",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/QUICK(NEW)",
    "from": "USDC.e",
    "to": "QUICK(NEW)",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SFL",
    "from": "USDC.e",
    "to": "SFL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/AXLUSDC",
    "from": "USDC.e",
    "to": "AXLUSDC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/USD",
    "from": "USDC.e",
    "to": "USD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/EURS",
    "from": "USDC.e",
    "to": "EURS",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe111178a87a3bff0c8d18decba5798827539ae99",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/ALI",
    "from": "USDC.e",
    "to": "ALI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/TETU",
    "from": "USDC.e",
    "to": "TETU",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x255707b70bf90aa112006e1b07b9aea6de021424",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/BOB",
    "from": "USDC.e",
    "to": "BOB",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MVX",
    "from": "USDC.e",
    "to": "MVX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/GOVI",
    "from": "USDC.e",
    "to": "GOVI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MMF",
    "from": "USDC.e",
    "to": "MMF",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/JPYC",
    "from": "USDC.e",
    "to": "JPYC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/BUSD",
    "from": "USDC.e",
    "to": "BUSD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MATIC",
    "from": "USDC.e",
    "to": "MATIC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x0000000000000000000000000000000000001010",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/WMATIC",
    "from": "USDC.e",
    "to": "WMATIC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/WETH",
    "from": "USDC.e",
    "to": "WETH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/WBTC",
    "from": "USDC.e",
    "to": "WBTC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/stMATIC",
    "from": "USDC.e",
    "to": "stMATIC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/GDDY",
    "from": "USDC.e",
    "to": "GDDY",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/XY",
    "from": "USDC.e",
    "to": "XY",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MULTI",
    "from": "USDC.e",
    "to": "MULTI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/CHAMP",
    "from": "USDC.e",
    "to": "CHAMP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MASK",
    "from": "USDC.e",
    "to": "MASK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/QUICK",
    "from": "USDC.e",
    "to": "QUICK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/GHST",
    "from": "USDC.e",
    "to": "GHST",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/WNT",
    "from": "USDC.e",
    "to": "WNT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DFYN",
    "from": "USDC.e",
    "to": "DFYN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/PSP",
    "from": "USDC.e",
    "to": "PSP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x42d61D766B85431666B39B89C43011f24451bFf6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/ANKR",
    "from": "USDC.e",
    "to": "ANKR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DFX",
    "from": "USDC.e",
    "to": "DFX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MUST",
    "from": "USDC.e",
    "to": "MUST",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SYN",
    "from": "USDC.e",
    "to": "SYN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/KNC",
    "from": "USDC.e",
    "to": "KNC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/BAL",
    "from": "USDC.e",
    "to": "BAL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SUSHI",
    "from": "USDC.e",
    "to": "SUSHI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DYST",
    "from": "USDC.e",
    "to": "DYST",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/WOO",
    "from": "USDC.e",
    "to": "WOO",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MESH",
    "from": "USDC.e",
    "to": "MESH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/VOXEL",
    "from": "USDC.e",
    "to": "VOXEL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/ankrMATIC",
    "from": "USDC.e",
    "to": "ankrMATIC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DAI",
    "from": "USDC.e",
    "to": "DAI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SAND",
    "from": "USDC.e",
    "to": "SAND",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xbbba073c31bf03b8acf7c28ef0738decf3695683",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SPHERE",
    "from": "USDC.e",
    "to": "SPHERE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/deUSDC",
    "from": "USDC.e",
    "to": "deUSDC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/PYR",
    "from": "USDC.e",
    "to": "PYR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/FRAX",
    "from": "USDC.e",
    "to": "FRAX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x45c32fa6df82ead1e2ef74d17b76547eddfaff89",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/FXS",
    "from": "USDC.e",
    "to": "FXS",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MAAAVE",
    "from": "USDC.e",
    "to": "MAAAVE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/AMAAVE",
    "from": "USDC.e",
    "to": "AMAAVE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/STG",
    "from": "USDC.e",
    "to": "STG",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/ICE",
    "from": "USDC.e",
    "to": "ICE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/GNS",
    "from": "USDC.e",
    "to": "GNS",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe5417af564e4bfda1c483642db72007871397896",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/RNDR",
    "from": "USDC.e",
    "to": "RNDR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x61299774020dA444Af134c82fa83E3810b309991",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/PLA",
    "from": "USDC.e",
    "to": "PLA",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maUSDC",
    "from": "USDC.e",
    "to": "maUSDC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/AAVE",
    "from": "USDC.e",
    "to": "AAVE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/USDT",
    "from": "USDC.e",
    "to": "USDT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/ADDY",
    "from": "USDC.e",
    "to": "ADDY",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/AGA",
    "from": "USDC.e",
    "to": "AGA",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
    "from_decimal": 6,
    "to_decimal": 4
  },
  {
    "pair": "USDC.e/AGAr",
    "from": "USDC.e",
    "to": "AGAr",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/CC10",
    "from": "USDC.e",
    "to": "CC10",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/CEL",
    "from": "USDC.e",
    "to": "CEL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
    "from_decimal": 6,
    "to_decimal": 4
  },
  {
    "pair": "USDC.e/DEFI5",
    "from": "USDC.e",
    "to": "DEFI5",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DEGEN",
    "from": "USDC.e",
    "to": "DEGEN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DINO",
    "from": "USDC.e",
    "to": "DINO",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xaa9654becca45b5bdfa5ac646c939c62b527d394",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/FISH",
    "from": "USDC.e",
    "to": "FISH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/GAJ",
    "from": "USDC.e",
    "to": "GAJ",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/Krill",
    "from": "USDC.e",
    "to": "Krill",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/LINK",
    "from": "USDC.e",
    "to": "LINK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/PolyDoge",
    "from": "USDC.e",
    "to": "PolyDoge",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/PolyMoon",
    "from": "USDC.e",
    "to": "PolyMoon",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
    "from_decimal": 6,
    "to_decimal": 9
  },
  {
    "pair": "USDC.e/SX",
    "from": "USDC.e",
    "to": "SX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/TEL",
    "from": "USDC.e",
    "to": "TEL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
    "from_decimal": 6,
    "to_decimal": 2
  },
  {
    "pair": "USDC.e/UBT",
    "from": "USDC.e",
    "to": "UBT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x7FBc10850caE055B27039aF31bD258430e714c62",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/UNI",
    "from": "USDC.e",
    "to": "UNI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MAI",
    "from": "USDC.e",
    "to": "MAI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xa3fa99a148fa48d14ed51d610c367c61876997f1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/pSWAMP",
    "from": "USDC.e",
    "to": "pSWAMP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/polyBUNNY",
    "from": "USDC.e",
    "to": "polyBUNNY",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/AUMI",
    "from": "USDC.e",
    "to": "AUMI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/1ART",
    "from": "USDC.e",
    "to": "1ART",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jEUR",
    "from": "USDC.e",
    "to": "jEUR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jGBP",
    "from": "USDC.e",
    "to": "jGBP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x767058f11800fba6a682e73a6e79ec5eb74fac8c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jCHF",
    "from": "USDC.e",
    "to": "jCHF",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xbd1463f02f61676d53fd183c2b19282bff93d099",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jAUD",
    "from": "USDC.e",
    "to": "jAUD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xcb7f1ef7246d1497b985f7fc45a1a31f04346133",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jCAD",
    "from": "USDC.e",
    "to": "jCAD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8ca194a3b22077359b5732de53373d4afc11dee3",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jCNY",
    "from": "USDC.e",
    "to": "jCNY",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x84526c812d8f6c4fd6c1a5b68713aff50733e772",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jCOP",
    "from": "USDC.e",
    "to": "jCOP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe6d222caaed5f5dd73a9713ac91c95782e80acbf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jJPY",
    "from": "USDC.e",
    "to": "jJPY",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8343091f2499fd4b6174a46d067a920a3b851ff9",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jKRW",
    "from": "USDC.e",
    "to": "jKRW",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xa22f6bc96f13bcc84df36109c973d3c0505a067e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jMXN",
    "from": "USDC.e",
    "to": "jMXN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xbd1fe73e1f12bd2bc237de9b626f056f21f86427",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jNGN",
    "from": "USDC.e",
    "to": "jNGN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x182c76e977161f703bb8f111047df6c43cfacc56",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jNZD",
    "from": "USDC.e",
    "to": "jNZD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x6b526daf03b4c47af2bcc5860b12151823ff70e0",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jPHP",
    "from": "USDC.e",
    "to": "jPHP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x486880fb16408b47f928f472f57bec55ac6089d1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jPLN",
    "from": "USDC.e",
    "to": "jPLN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x08e6d1f0c4877ef2993ad733fc6f1d022d0e9dbf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jBRL",
    "from": "USDC.e",
    "to": "jBRL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xf2f77fe7b8e66571e0fca7104c4d670bf1c8d722",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jSEK",
    "from": "USDC.e",
    "to": "jSEK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x197e5d6ccff265ac3e303a34db360ee1429f5d1a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/jSGD",
    "from": "USDC.e",
    "to": "jSGD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/BIFI",
    "from": "USDC.e",
    "to": "BIFI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xfbdd194376de19a88118e84e279b977f165d01b8",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MEME",
    "from": "USDC.e",
    "to": "MEME",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xf2b5a8c37278bcdd50727d5ca879f8e5a4642e2e",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/SUPER",
    "from": "USDC.e",
    "to": "SUPER",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xa1428174f516f527fafdd146b883bb4428682737",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MANA",
    "from": "USDC.e",
    "to": "MANA",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/mUSD",
    "from": "USDC.e",
    "to": "mUSD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe840b73e5287865eec17d250bfb1536704b43b21",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/YFI",
    "from": "USDC.e",
    "to": "YFI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xda537104d6a5edd53c6fbba9a898708e465260b6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/QUICK(old)",
    "from": "USDC.e",
    "to": "QUICK(old)",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x831753dd7087cac61ab5644b308642cc1c33dc13",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/renBTC",
    "from": "USDC.e",
    "to": "renBTC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xdbf31df14b66535af65aac99c32e9ea844e14501",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DfynWMATIC",
    "from": "USDC.e",
    "to": "DfynWMATIC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x4c28f48448720e9000907bc2611f73022fdce1fa",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/HAWK",
    "from": "USDC.e",
    "to": "HAWK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x69cbc7449ee102eb792f1656744bf1a7c1bacb7e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/BPT",
    "from": "USDC.e",
    "to": "BPT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x6863bd30c9e313b264657b107352ba246f8af8e0",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/amAAVE",
    "from": "USDC.e",
    "to": "amAAVE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1d2a0e5ec8e5bbdca5cb219e649b565d8e5c3360",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/amDAI",
    "from": "USDC.e",
    "to": "amDAI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x27f8d03b3a2196956ed754badc28d73be8830a6e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/amUSDC",
    "from": "USDC.e",
    "to": "amUSDC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x1a13f4ca1d028320a707d99520abfefca3998b7f",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/amUSDT",
    "from": "USDC.e",
    "to": "amUSDT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x60d55f02a771d515e077c9c2403a1ef324885cec",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/amWBTC",
    "from": "USDC.e",
    "to": "amWBTC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x5c2ed810328349100a66b82b78a1791b101c9d61",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/amWETH",
    "from": "USDC.e",
    "to": "amWETH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x28424507fefb6f7f8e9d3860f56504e4e5f5f390",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/amWMATIC",
    "from": "USDC.e",
    "to": "amWMATIC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/COMP",
    "from": "USDC.e",
    "to": "COMP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/BTU",
    "from": "USDC.e",
    "to": "BTU",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xfdc26cda2d2440d0e83cd1dee8e8be48405806dc",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/$DG",
    "from": "USDC.e",
    "to": "$DG",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x2a93172c8dccbfbc60a39d56183b7279a2f647b4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/0xBTC",
    "from": "USDC.e",
    "to": "0xBTC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x71b821aa52a49f32eed535fca6eb5aa130085978",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/ARIA20",
    "from": "USDC.e",
    "to": "ARIA20",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x46f48fbdedaa6f5500993bede9539ef85f4bee8e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/AZUKI",
    "from": "USDC.e",
    "to": "AZUKI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x7cdc0421469398e0f3aa8890693d86c840ac8931",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/CFI",
    "from": "USDC.e",
    "to": "CFI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xecf8f2fa183b1c4d2a269bf98a54fce86c812d3e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/CTSI",
    "from": "USDC.e",
    "to": "CTSI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x2727ab1c2d22170abc9b595177b2d5c6e1ab7b7b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DB",
    "from": "USDC.e",
    "to": "DB",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x0e59d50add2d90f5111aca875bae0a72d95b4762",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DMT",
    "from": "USDC.e",
    "to": "DMT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xd28449bb9bb659725accad52947677cce3719fd7",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DSLA",
    "from": "USDC.e",
    "to": "DSLA",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xa0e390e9cea0d0e8cd40048ced9fa9ea10d71639",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/EASY",
    "from": "USDC.e",
    "to": "EASY",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/ELET",
    "from": "USDC.e",
    "to": "ELET",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x07738eb4ce8932ca961c815cb12c9d4ab5bd0da4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/ETH",
    "from": "USDC.e",
    "to": "ETH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/GAME",
    "from": "USDC.e",
    "to": "GAME",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8d1566569d5b695d44a9a234540f68d393cdc40d",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/HEX",
    "from": "USDC.e",
    "to": "HEX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x23d29d30e35c5e8d321e1dc9a8a61bfd846d4c5c",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/HH",
    "from": "USDC.e",
    "to": "HH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x521cddc0cba84f14c69c1e99249f781aa73ee0bc",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/IGG",
    "from": "USDC.e",
    "to": "IGG",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/JULIEN",
    "from": "USDC.e",
    "to": "JULIEN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x268ad27c28601d28b79c792c14e95bd2b7a030f8",
    "from_decimal": 6,
    "to_decimal": 4
  },
  {
    "pair": "USDC.e/LEND",
    "from": "USDC.e",
    "to": "LEND",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x313d009888329c9d1cf4f75ca3f32566335bd604",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/MONA",
    "from": "USDC.e",
    "to": "MONA",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x6968105460f67c3bf751be7c15f92f5286fd0ce5",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/NDR",
    "from": "USDC.e",
    "to": "NDR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xfb65ef42f7c8a70ff73f627db6e9ba2aff1f20fa",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/OM",
    "from": "USDC.e",
    "to": "OM",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xc3ec80343d2bae2f8e680fdadde7c17e71e114ea",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/PICKLE",
    "from": "USDC.e",
    "to": "PICKLE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x2b88ad57897a8b496595925f43048301c37615da",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/PPDEX",
    "from": "USDC.e",
    "to": "PPDEX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/RBAL",
    "from": "USDC.e",
    "to": "RBAL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x03247a4368a280bec8133300cd930a3a61d604f6",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SDT",
    "from": "USDC.e",
    "to": "SDT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x361a5a4993493ce00f61c32d4ecca5512b82ce90",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SENT",
    "from": "USDC.e",
    "to": "SENT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x48e3883233461c2ef4cb3fcf419d6db07fb86cea",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/SWAP",
    "from": "USDC.e",
    "to": "SWAP",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x3809dcdd5dde24b37abe64a5a339784c3323c44f",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/SWG",
    "from": "USDC.e",
    "to": "SWG",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x043a3aa319b563ac25d4e342d32bffb51298db7b",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/VISION",
    "from": "USDC.e",
    "to": "VISION",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x034b2090b579228482520c589dbd397c53fc51cc",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/WISE",
    "from": "USDC.e",
    "to": "WISE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xb77e62709e39ad1cbeebe77cf493745aec0f453a",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/WRX",
    "from": "USDC.e",
    "to": "WRX",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x72d6066f486bd0052eefb9114b66ae40e0a6031a",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/ZUT",
    "from": "USDC.e",
    "to": "ZUT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe86e8beb7340659dddce61727e500e3a5ad75a90",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/ZUZ",
    "from": "USDC.e",
    "to": "ZUZ",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x232eab56c4fb3f84c6fb0a50c087c74b7b43c6ad",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/bBADGER",
    "from": "USDC.e",
    "to": "bBADGER",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x2628d301b161db70e3dbbac88d9d900ca426ff02",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/bDIGG",
    "from": "USDC.e",
    "to": "bDIGG",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xfdde616084427f0a231d0664a985e1f820e34693",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/eDAI",
    "from": "USDC.e",
    "to": "eDAI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/eUSDC",
    "from": "USDC.e",
    "to": "eUSDC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x4ebde54ba404be158262ede801744b92b9878c61",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/eUSDT",
    "from": "USDC.e",
    "to": "eUSDT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/iFARM",
    "from": "USDC.e",
    "to": "iFARM",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xab0b2ddb9c7e440fac8e140a89c0dbcbf2d7bbff",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/mDEF",
    "from": "USDC.e",
    "to": "mDEF",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x82b6205002ecd05e97642d38d61e2cfeac0e18ce",
    "from_decimal": 6,
    "to_decimal": 9
  },
  {
    "pair": "USDC.e/mOCEAN",
    "from": "USDC.e",
    "to": "mOCEAN",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x282d8efce846a88b159800bd4130ad77443fa1a1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/mRBAL",
    "from": "USDC.e",
    "to": "mRBAL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x66768ad00746ac4d68ded9f64886d55d5243f5ec",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maAAVE",
    "from": "USDC.e",
    "to": "maAAVE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maDAI",
    "from": "USDC.e",
    "to": "maDAI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe0b22e0037b130a9f56bbb537684e6fa18192341",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maLINK",
    "from": "USDC.e",
    "to": "maLINK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x98ea609569bd25119707451ef982b90e3eb719cd",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maTUSD",
    "from": "USDC.e",
    "to": "maTUSD",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xf4b8888427b00d7caf21654408b7cba2ecf4ebd9",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maUNI",
    "from": "USDC.e",
    "to": "maUNI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8c8bdbe9cee455732525086264a4bf9cf821c498",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maUSDT",
    "from": "USDC.e",
    "to": "maUSDT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xdae5f1590db13e3b40423b5b5c5fbf175515910b",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/maWETH",
    "from": "USDC.e",
    "to": "maWETH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x20d3922b4a1a8560e1ac99fba4fade0c849e2142",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/maYFI",
    "from": "USDC.e",
    "to": "maYFI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe20f7d1f0ec39c4d5db01f53554f2ef54c71f613",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/sd3Crv",
    "from": "USDC.e",
    "to": "sd3Crv",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x87f0bfee4435ce2b8643b221d0c1cad21f5328b4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/sdcrvRenWSBTC",
    "from": "USDC.e",
    "to": "sdcrvRenWSBTC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe212f92e5af85268b33d2aa587b51f49c3b945be",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/sdeursCRV",
    "from": "USDC.e",
    "to": "sdeursCRV",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xfbdb45075fb73ca4cc8b83fd6fdb4f9b696b1ba1",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/xMARK",
    "from": "USDC.e",
    "to": "xMARK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xf153eff70dc0bf3b085134928daeea248d9b30d0",
    "from_decimal": 6,
    "to_decimal": 9
  },
  {
    "pair": "USDC.e/xSDT",
    "from": "USDC.e",
    "to": "xSDT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xd921f8318cfd786bab1ea7492673f053c518ac04",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/DPI",
    "from": "USDC.e",
    "to": "DPI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x85955046df4668e1dd369d2de9f3aeb98dd2a369",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/agEUR",
    "from": "USDC.e",
    "to": "agEUR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe0b52e49357fd4daf2c15e02058dce6bc0057db4",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolAAVE",
    "from": "USDC.e",
    "to": "aPolAAVE",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xf329e36c7bf6e5e86ce2150875a84ce77f477375",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolDAI",
    "from": "USDC.e",
    "to": "aPolDAI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolUSDC",
    "from": "USDC.e",
    "to": "aPolUSDC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/aPolUSDT",
    "from": "USDC.e",
    "to": "aPolUSDT",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x6ab707aca953edaefbc4fd23ba73294241490620",
    "from_decimal": 6,
    "to_decimal": 6
  },
  {
    "pair": "USDC.e/aPolWBTC",
    "from": "USDC.e",
    "to": "aPolWBTC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x078f358208685046a11c85e8ad32895ded33a249",
    "from_decimal": 6,
    "to_decimal": 8
  },
  {
    "pair": "USDC.e/aPolWETH",
    "from": "USDC.e",
    "to": "aPolWETH",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xe50fa9b3c56ffb159cb0fca61f5c9d750e8128c8",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolWMATIC",
    "from": "USDC.e",
    "to": "aPolWMATIC",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x6d80113e533a2c0fe82eabd35f1875dcea89ea97",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolLINK",
    "from": "USDC.e",
    "to": "aPolLINK",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x191c10aa4af7c30e871e70c95db0e4eb77237530",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolEURS",
    "from": "USDC.e",
    "to": "aPolEURS",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x38d693ce1df5aadf7bc62595a37d667ad57922e5",
    "from_decimal": 6,
    "to_decimal": 2
  },
  {
    "pair": "USDC.e/aPolGHST",
    "from": "USDC.e",
    "to": "aPolGHST",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8eb270e296023e9d92081fdf967ddd7878724424",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolBAL",
    "from": "USDC.e",
    "to": "aPolBAL",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8ffdf2de812095b1d19cb146e4c004587c0a0692",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolCRV",
    "from": "USDC.e",
    "to": "aPolCRV",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x513c7e3a9c69ca3e22550ef58ac1c0088e918fff",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolDPI",
    "from": "USDC.e",
    "to": "aPolDPI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x724dc807b04555b71ed48a6896b6f41593b8c637",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolSUSHI",
    "from": "USDC.e",
    "to": "aPolSUSHI",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0xc45a479877e1e9dfe9fcd4056c699575a1045daa",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolJEUR",
    "from": "USDC.e",
    "to": "aPolJEUR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x6533afac2e7bccb20dca161449a13a32d391fb00",
    "from_decimal": 6,
    "to_decimal": 18
  },
  {
    "pair": "USDC.e/aPolAGEUR",
    "from": "USDC.e",
    "to": "aPolAGEUR",
    "from_contract": "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "to_contract": "0x8437d7c167dfb82ed4cb79cd44b7a32a1dd95c77",
    "from_decimal": 6,
    "to_decimal": 18
  }
]
module.exports = { tokenList }; 

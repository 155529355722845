import React from 'react';
import { Spinner } from "@material-tailwind/react";


const Spinners = () => {
  return (
    <div className="fixed top-0 z-50 left-0 flex items-center justify-center w-screen h-screen bg-black bg-opacity-10 backdrop-blur-md">
      <div className='flex items-center gap-5'>
   <div class="relative flex justify-center items-center">
    <div class="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-[#DD7C0B73]"></div>
    <img src="assets/MBC Logo_Final 2.png"  class="rounded-full h-18 w-24"/>
</div><br/>
   <p className='text-white text-2xl font-bold'>Fetching Prices....</p>
   </div>
</div>
  );  
};

export default Spinners; 
import '../App.css';
import React, { useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { NODE_URL } from '../config';

const Loginpage = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [visible, setvisible] = useState(false)

    const handlepassword = () => {
        setvisible(!visible);
    }

    const handleLogin = async () => {
        if (email === "") {
            toast.error("Please Enter the Email")
        }
        if (password === "") {
            toast.error("Please Enter the Password")
        }
        try {
            const response = await axios.get(`${NODE_URL}:3006/apiv4/AdminLogin?email=${email}&password=${password}`);
            if (response.data.status === 'success') {
                toast.success("Login Successfull")
                sessionStorage.setItem('adminLoggedIn', 'true');
                setTimeout(() => {
                    window.location.href = '/Admin';
                }, 1000)
            } else {
                toast.error("Invalid Credentials")
            }
        } catch (error) {
            toast.error("Invalid Credentials")
            console.error('Error during login:', error);
        }
    };

    return (
        <div className=" text-white font-poppins  ">
            <div className="bg-cover h-screen overflow-hidden overflow-y-auto bg-no-repeat bg-center pb-28"
                style={{ backgroundImage: `url(assetsgpt/bg3.png)` }}>
                <Toaster />
                <div className='bg-black/65 lg:flex lg:flex-row lg:justify-normal flex flex-col items-center md:space-x-5 md:px-20 sm:px-10 px-4 md:pt-24 pt-9 pb-20 h-auto  '>
                    <div className='rounded-full w-[20rem] h-[20rem] bg-[#E43875]/30 blur-3xl absolute -top-[7rem] -left-[3rem]'></div>
                    <div className='rounded-full w-[20rem] h-[20rem] bg-[#E43875]/30 blur-3xl absolute bottom-[0rem] right-[0rem] overflow-hidden'></div>
                    <div className='lg:w-[60%] flex justify-center items-center'>
                        <div className='z-50'>
                            <div className=''>
                                <img src="assets/mbc-bot.png" className='' />
                                <img src="assets/Ellipse 127.png" />
                            </div>
                        </div>
                        <div className=''>
                            <p className='font-bold text-xs'>You've reached the MBC Admin Login. Ready to take control? Sign in below to access your admin privileges.</p>
                        </div>
                    </div>
                    <div className='lg:w-[40%] '>
                        <div className='mt-10 md:mt-0 bg-gradient-to-br from-[#F98736]  to-[#FC02D6] p-[2px] rounded-3xl'>
                            <div className='md:pt-14 pt-8 pb-10 md:px-16 px-10 bg-black/80 backdrop-blur-md rounded-3xl'>
                                <p className='text-3xl font-bold font-Artemus text-[#F98736]'>Login</p>
                                <div className='text-[12px] flex flex-col mt-5 '>
                                    <label >Email</label>
                                    <input className='w-full focus:outline-none md:p-3 p-2 text-[13px] rounded-md text-black'
                                        type="text"
                                        placeholder="username@gmail.com"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label className='mt-5' >Password</label>
                                    <div className='relative'>
                                        <input className='w-full focus:outline-none md:p-3 p-2 text-[13px] rounded-md text-black'
                                            placeholder="Password"
                                            type={visible ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <button
                                            onClick={handlepassword}
                                            className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[60px]"
                                        >
                                            {visible ? (
                                                <FontAwesomeIcon
                                                    icon={faEye}
                                                    size="lg"
                                                    style={{ color: "#bcbfc6" }}
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faEyeSlash}
                                                    size="lg"
                                                    style={{ color: "#bcbfc6" }}
                                                />
                                            )}
                                        </button>

                                    </div>
                                </div>
                                <button className='bg-[#E43875] font-Artemus text-black w-full rounded-md text-center  p-2 mt-10 font-bold' onClick={handleLogin}>Sign in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loginpage
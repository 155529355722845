// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import { BrowserRouter } from "react-router-dom";
// import App from './App';



// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>

//   </React.StrictMode>
// );

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './App';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'

const projectId = 'ff5c5ef2c8617169454eb7152e48f800'

const mainnet = {
  chainId: 42161,
  name: 'Arbitrum',
  currency: 'ETH',
  explorerUrl: 'https://arbiscan.io',
  rpcUrl: 'https://arb1.arbitrum.io/rpc'
}

const polygon = {
  chainId: 137,
  name: 'Polygon',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com/',
  rpcUrl: 'https://polygon-mainnet.infura.io/v3/29l8LmTDhgubYwRqevSu29joCvA',
};

const metadata = {
  name: 'Ainstein',
  description: 'Decentralized Application',
  url: 'http://localhost/',
  icons: ['http://localhost/assets/MBC%20Logo_Final%201.png']
}

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true,
  enableInjected: true,
  enableCoinbase: true, 
  rpcUrl: '...', 
  defaultChainId: 137, 
})


createWeb3Modal({
  ethersConfig,
  chains: [mainnet,polygon],
  projectId,
  themeVariables: {
  '--w3m-accent	':'#0db1a9',
  },
 
  enableAnalytics: true 
})

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Routers/>
  </Router>
);


reportWebVitals();
import React, { useState } from "react";
import "../App.css";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  if (!loggedIn) {
    const isAdminLoggedIn = sessionStorage.getItem("adminLoggedIn");
    if (isAdminLoggedIn === "true") {
      setLoggedIn(true);
    } else {
      window.location.href = "/login";
      return null;
    }
  }

  const handleLogout = () => {
    setLoggedIn(false);
    sessionStorage.removeItem("adminLoggedIn");
    window.location.href = "/login";
  };

  return (
    <nav className="flex bg-black/20 px-5 items-center">
      <div className="flex items-center font-medium text-xl md:w-[10%] w-[50%] ">
        <NavLink to="/">
          <img src="assets/MBC Logo_Final 1.png" className="" />
        </NavLink>
      </div>
      <div
        className={`lg:static text-xs lg:flex-row  flex-col absolute lg:min-h-fit min-h-[30vh] lg:space-x-32 py-5 left-0 flex justify-center text-center items-center lg:justify-between ${
          open ? "top-[8%] bg-black z-30" : "top-[-100%]"
        } w-full`}
      >
        <ul className="flex z-10 lg:flex-row flex-col lg:items-center lg:gap-[4vw] gap-8">
          <li>
            <a className="hover:text-gray-500" href="/Admin">
              Dashboard
            </a>
          </li>
          <li>
            <a className="hover:text-gray-500" href="/Users">
              Users List
            </a>
          </li>
          {/* <li>
            <a className="hover:text-gray-500" href="/Purchasehistory">
              Purchases List
            </a>  
          </li> */}
          <li>
            <a className="hover:text-gray-500" href="/Whitelist">
              WhiteList
            </a>
          </li>

          <li>
            <a className="hover:text-gray-500" href="/Removelist">
              Remove Users
            </a>
          </li>
          {/* <li>
            <a className="hover:text-gray-500" href="/Addfund">
              Add Funds
            </a>
          </li>

          <li>
            <a className="hover:text-gray-500" href="/Withdraw">
              Withdraw Funds
            </a>
          </li> */}
          <li>
            <a className="hover:text-gray-500" href="/Platformstats">
              Platform Stats
            </a>
          </li>

          <a className="hover:text-gray-500" href="/Query">
            Query
          </a>
          
          <li>
            <a className="hover:text-gray-500" href="/Whitelistusers">
              Whitelisted users
            </a>
          </li>
          <li>
            <a className="hover:text-gray-500" onClick={handleLogout}>
              LogOut
            </a>
          </li>
          <li>
            <a className="hover:text-gray-500">
              <w3m-button />
            </a>
          </li>
        </ul>

        {/* <div className="">
          <div className="flex gap-4 lg:mt-0 mt-10">
            <select
              className="rounded-full text-xs md:px-3 bg-black box-border border-[1px] border-solid border-indigo-400"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              <option value="">Ethereum</option>
              <option value="apple">Arbitrum</option>
              <option value="banana">Avalanche</option>
              <option value="orange">Polygon</option>
              <option value="grape">Fantom</option>
            </select>
            <button className="flex rounded-full md:px-5 text-xs  items-center  border-[1px] border-solid border-indigo-400 ">
              <img
                className=""
                alt=""
                src="assets/image-12@2x.png"
              />
              <p className=" ">0Xr76YFUYU...</p>
            </button>
          </div>
        </div> */}
      </div>
      <div
        onClick={() => setOpen(!open)}
        className=" lg:hidden cursor-pointer absolute right-8 top-2.5"
      >
        <ion-icon name={open ? "close" : "menu"} />
      </div>
    </nav>
  );
};

export default Header;

import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Header from "./AdminHeader.js";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { NODE_URL } from "../config";

export default function PreviousQuery() {

  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [solution, setSolution] = useState(null);
  const [status, setStatus] = useState(null);

  const fetchTickets = async () => {
    try {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/fetchTotalTickets`);

      if (response.data.status === 'success') {
        const list = await Promise.all(
          response.data.data.map(async (trans) => ({
            id: trans.id,
            userAddress: trans.userAddress,
            subject: trans.subject,
            trxHash: trans.trxHash,
            description: trans.description,
            createdAt: await convertUTCToIST(trans.createdAt),
            status: trans.status,
            ticket_id: trans.ticket_id,
            resolved_description: trans.resolved_description,
          }))
        );
        setTickets(list);
      } else {
        setTickets([]);
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      setTickets([]);
    }
  };

  const convertUTCToIST = (utcDate) => {
    const date = new Date(utcDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const openCount = tickets.filter((entity) => entity.status === "Open").length;
  const closedCount = tickets.filter((entity) => entity.status === "Closed").length;

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentEntities = tickets.slice(indexOfFirstEntity, indexOfLastEntity);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleDetailsQuery = (ticket) => {
    setSelectedTicket(ticket);
    setSolution(ticket.resolved_description || "");
    setStatus(ticket.status || "");
  };

  const closeDetailsQuery = () => {
    setSelectedTicket(null);
  };

  const handleSubmit = async (id, address) => {
    try {
      if (id === null) {
        toast.error("Invalid Ticket");
        return false;
      }
      if (solution === null) {
        toast.error("Enter the Solution");
        return false;
      }
      if (status === "" || status === null || status === "null") {
        toast.error("Select the status");
        return false;
      }
      setSelectedTicket(null);
      const response = await axios.post(`${NODE_URL}:3006/apiv4/updateTickets`, {
        status: status,
        resolved_description: solution,
        address: address,
        ticket_id: id,
      }
      );
      if (response.data === 'Ticket updated') {
        toast.success("Updated Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error("Error while Updating Ticket");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Error while updating the Tickets", error);
    }
  };

  return (
    <div
      className="font-poppins relative text-white"
      style={{ backgroundImage: 'url("assets/AinsteinBG.png")' }} >
      <Header />
      <Toaster toastOptions={{ position: "top-center", }} />
      <div className="bg-gradient-to-b  py-10 from-[#000]/50 to-[#000] h-screen overflow-hidden overflow-y-auto">
        <div className="md:w-[85%]  mx-auto rounded-2xl py-5 px-10 ">
          <div className="flex justify-between">
            <div>
              <h1 className="text-lg font-bold">Previous Query</h1>
            </div>
            <div className="flex justify-end gap-10">
              <div className="text-sm flex items-center">
                <p>
                  Open:{" "}
                  <span className="text-blue-500 font-bold">{openCount}</span>
                </p>
              </div>
              <div className="text-sm flex items-center">
                <p>
                  Resolved:{" "}
                  <span className="text-blue-500 font-bold">{closedCount}</span>
                </p>
              </div>
              <div className="flex items-center">
                <p className="text-end text-sm px-3 mb-2">
                  Show no of entity
                  <select
                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                    onChange={handleDropdownChange}
                    value={entitiesPerPage}
                  >
                    <option className="text-black" value="5">
                      5
                    </option>
                    <option className="text-black" value="7">
                      7
                    </option>
                    <option className="text-black" value="10">
                      10
                    </option>
                  </select>
                </p>
              </div>
            </div>
          </div>
          <div className="pb-5 rounded-xl overflow-hidden overflow-x-auto">
            <table className="w-full bg-black/60 rounded-xl overflow-hidden text-center">
              <thead className="bg-[#D9D9D9]/60 text-black ">
                <tr>
                  <th className="py-3 rounded-tl-xl px-3">S.no</th>
                  <th className="">Ticket ID</th>
                  <th className="">Wallet Address</th>
                  <th className="">Subject</th>
                  <th className="">Date & Time</th>
                  <th className="">Status</th>
                  <th className="py-3 rounded-tr-xl">Details</th>
                </tr>
              </thead>
              <tbody className="">
                {currentEntities.map((entity, index) => (
                  <tr
                    key={index}
                    className="h-16 text-sm text-center rounded-md"
                  >
                    <td className="px-5 md:px-0 ">
                      {indexOfFirstEntity + index + 1}
                    </td>
                    <td className="px-5 md:px-0 ">#{entity.ticket_id}</td>
                    <td className="px-5 md:px-0 ">{entity.userAddress}</td>
                    <td className="px-5 md:px-0 ">{entity.subject}</td>
                    <td className="px-5 md:px-0 ">{entity.createdAt}</td>
                    <td className={`px-5 md:px-0 ${entity.status === "Open" ? "text-[#FFBA00]" : "text-[#36a90e]"}`}>
                      {entity.status}
                    </td>
                    <td className="px-5 md:px-0 ">
                      <button
                        className="bg-[#313131] px-5 py-1 rounded-full"
                        onClick={() => handleDetailsQuery(entity)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-2 flex justify-between text-xs px-3">
            <button
              className="bg-white/30 rounded-md px-5 py-1"
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <button
              className="bg-white/30 rounded-md px-5 py-1"
              onClick={handleNextClick}
              disabled={currentEntities.length < entitiesPerPage}
            >
              Next
            </button>
          </div>
          {selectedTicket && (
            <div className="xl:fixed absolute w-full mx-auto top-0 left-0 z-30 pt-16 xl:inset-0 model-overlay overflow-hidden overflow-y-auto backdrop-blur-md flex items-center">
              <div className="w-[95%] lg:w-[60%] mx-auto">
                <div className="flex justify-end">
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    className="font-bold hover:translate-y-1 duration-300 cursor-pointer"
                    onClick={closeDetailsQuery}
                  />
                </div>
                <p className="text-lg font-bold">Detailed Query</p>
                <div className="flex flex-col md:flex-row gap:5 md:gap-10 mt-5">
                  <div className="md:w-[50%]">
                    <p className="text-white/50 text-sm">Subject</p>
                    <input
                      className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 placeholder:text-sm"
                      value={selectedTicket.subject}
                      readOnly
                    />
                    <p className="text-white/50 text-sm mt-5">
                      Transaction Hash{" "}
                    </p>
                    <a
                      href={`https://polygonscan.com/tx/${selectedTicket.trxHash}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 placeholder:text-sm">
                        <p className="truncate">{selectedTicket.trxHash}</p>
                      </div>
                    </a>
                  </div>
                  <div className="md:w-[50%]">
                    <p className="text-white/50 text-sm ">Query in detail </p>
                    <textarea
                      className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 h-[85%] placeholder:text-sm"
                      value={selectedTicket.description}
                      readOnly
                    ></textarea>
                  </div>
                </div>

                <p className="text-white/50 text-sm mt-5">Give a solution</p>
                <textarea
                  className="mt-1 border rounded-2xl w-[100%] bg-transparent outline-none py-2 px-3 h-[150px] placeholder:text-sm"
                  value={solution}
                  placeholder="Provide your solution here"
                  onChange={(e) => setSolution(e.target.value)}
                ></textarea>

                <div className="flex justify-between items-center">
                  <div className="w-1/2">
                    <p className="text-white/50 text-sm mt-5">Select status</p>
                    <select
                      className="md:w-[40%] bg-transparent outline-none py-2 px-3 border rounded-2xl"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status || selectedTicket.status}
                    >
                      <option className="text-white bg-black" value="">
                        Select
                      </option>
                      <option className="text-white bg-black" value="Open">
                        Open
                      </option>
                      <option className="text-white bg-black" value="Pending">
                        Pending
                      </option>
                      <option className="text-white bg-black" value="Closed">
                        Closed
                      </option>
                    </select>
                  </div>
                  <div className="w-[30%]">
                    <button
                      className="bg-[#E43875A3] mt-10 w-full text-white font-bold text-center py-2 rounded-lg md:text-[0.75rem] text-[10px] leading-1"
                      onClick={() => handleSubmit(selectedTicket.ticket_id, selectedTicket.userAddress)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

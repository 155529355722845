import React from "react";
import Header from "./Header_new";
import { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useWeb3ModalAccount, } from "@web3modal/ethers5/react";
import axios from "axios";
import { NODE_URL } from "./config";

export default function Mbc() {

  const { address, isConnected } = useWeb3ModalAccount();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [useStatus, setUserStatus] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const toggleModal = async () => {
    if (address === undefined) {
      setIsOpen(false);
      toast.error("Please connect the wallet");
      return false;
    }
    const checkWhiteList = await checkUserRole(address);
    if (checkWhiteList === false) {
      toast.error("Account is Not Whitelisted");
      sessionStorage.setItem('isWhiteListed', 'false');
      return false;
    }
    sessionStorage.setItem('isWhiteListed', 'true');
    if (useStatus === 1) {
      navigate("/Dapp");
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    const fetchStatus = async () => {
      const result = await axios.get(`${NODE_URL}:3006/apiv4/checkStatus?address=${address}`);
      setUserStatus(result.data.status);
    };
    if (isConnected === true && address !== undefined) {
      fetchStatus();
    }
  }, [address, isConnected]);

  const proceedButton = async (event) => {
    if (isChecked === false) {
      toast.error("Please accept the conditions");
    } else {
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getusers?address=${address}`);
      console.log(response.data.status);
      if (response.data.status === "success") {
        navigate("/Dapp");
      } else {
        toast.error("Please try again");
      }
    }
  };

  const checkUserRole = async () => {
    try {
      let checkUser = await axios.get(`${NODE_URL}:3006/apiv4/getWhiteListDetails?walletAddress=${address}`)
      if (checkUser.data.status === true) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  return (
    <div className="Main-container min-h-screen">
      <div
        className="SECTION-1 bg-no-repeat bg-cover bg-center bg-black"
        style={{ backgroundImage: "url('assets/Background (4).png')" }}
      >
        <Header />
        <Toaster
          toastOptions={{
            position: "top-center",
          }}
        />
        <div className="flex justify-center w-[50%]">
          <img
            className="hidden lg:block fa-bounce"
            src="assets/Bitcoin-1.png"
          ></img>
        </div>
        <div>
          <p className="font-extrabold text-center font-syne text-white text-3xl lg:text-[48px] mt-4 lg:mt-0">
            Ainstein AI
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-center items-center">
          <div className="lg:w-[30%]">
            <img src="assets/image 226.png"></img>
          </div>
          <div className="lg:w-[40%] flex flex-col lg:gap-20 gap-5 mt-6 lg:mt-0">
            <div>
              <p className="text-white text-center font-rubik">
                Exclusively for MBC Community
              </p>
              <p className="text-white text-center font-rubik mt-3">
                Zero collateral AI powered lossless flash loan platform
              </p>
            </div>
            <div className="flex justify-center">
              <button
                className="text-white font-bold font-rubik bg-[#1c0f25] border border-[#ef443b] rounded-full px-2 py-1"
                onClick={toggleModal}
              >
                EXPLORE DAPP
              </button>
            </div>
            <div className="flex justify-center items-center gap-16">
              <p className="text-white font-rubik">Learn</p>
              <p className="text-white font-rubik">Trade</p>
              <p className="text-white font-rubik">Earn</p>
            </div>
          </div>
          <div className="lg:w-[30%]">
            <img src="assets/elephant.png"></img>
            <div className="flex justify-end px-12">
              <img src="assets/Monero-1.png"></img>
            </div>
          </div>
        </div>
      </div>
      <div
        className="SECTION-2 bg-no-repeat bg-cover bg-center bg-black pb-2"
        style={{ backgroundImage: "url('assets/bg (3).png')" }}
      >
        <div className="px-10 -translate-y-12 ">
          <img className="w-1/12 fa-shake" src="assets/Etherium-2.png"></img>
        </div>
        <div className="flex flex-col gap-3">
          <p className="font-extrabold text-center font-syne text-white text-xl lg:text-[38px]">
            How to Trade With Ainstein
          </p>
          <p className="text-[#a7959c] font-rubik text-center  ">
            Zero collateral, lossless trading platform
          </p>
        </div>
        <div className="flex flex-col lg:flex-row justify-center mt-3">
          <div className="lg:w-[50%] flex justify-center">
            <img className="w-7/12" src="assets/Video.png"></img>
          </div>
          <div className="lg:w-[50%] flex justify-center items-center">
            <div className="flex flex-col gap-3">
              <p className="font-bold font-rubik text-center text-[#ef443b] text-[36px]">
                Steps to trade
              </p>
              <div className="flex justify-center">
                <div className="flex flex-col gap-4">
                  <button className="flex items-center gap-3 shadow-xl shadow-[#48121a] w-[99%] px-2 py-2 bg-gradient-to-b from-[#54142b] to-[#320b19] text-[#ef443b] font-bold rounded-full border border-[#4e131b]">
                    <img className="w-2/12" src="assets/bg (4).png"></img>
                    Connect Wallet
                  </button>
                  <button className="flex items-center gap-3 shadow-xl shadow-[#48121a] w-[99%] px-2 py-2 bg-gradient-to-b from-[#54142b] to-[#320b19] text-[#ef443b] font-bold rounded-full border border-[#4e131b]">
                    <img className="w-2/12" src="assets/bg (4).png"></img>Learn
                    the platform
                  </button>
                  <button className="flex items-center gap-3 shadow-xl shadow-[#48121a] w-[99%] px-2 py-2 bg-gradient-to-b from-[#54142b] to-[#320b19] text-[#ef443b] font-bold rounded-full border border-[#4e131b]">
                    <img className="w-2/12" src="assets/bg (4).png"></img>Trade
                    on
                  </button>
                  <button className="flex items-center gap-3 shadow-xl shadow-[#48121a] w-[99%] px-2 py-2 bg-gradient-to-b from-[#54142b] to-[#320b19] text-[#ef443b] font-bold rounded-full border border-[#4e131b]">
                    <img className="w-2/12" src="assets/bg (4).png"></img>Earn
                    profit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="SECTION-3 bg-no-repeat bg-cover bg-center pb-28"
        style={{ backgroundImage: "url('assets/Bg.png')" }}
      >
        <div className="px-10 -translate-y-12 flex justify-end">
          <img className="w-1/12 fa-shake" src="assets/Etherium-2.png"></img>
        </div>
        <div className="px-10">
          <img src="assets/Monero-1 (1).png"></img>
        </div>
        <div className="flex justify-center">
          <div className="mt-5 w-[90%]">
            <p className="text-white font-bold text-center font-syne  2xl:text-[72px] xl:text-[72px] lg:text-[72px]">
              Most advanced crypto trading platfrom ever!
            </p>
          </div>
        </div>
      </div>
      <div
        className="SECTION-4 bg-no-repeat bg-cover bg-center bg-black py-20"
        style={{ backgroundImage: "url('assets/Background (4).png')" }}
      >
        <div className="">
          <p className="font-extrabold text-center font-syne text-white text-3xl lg:text-[38px]">
            Attractive Features
          </p>
        </div>
        <div className="px-10">
          <img className="w-1/12 fa-bounce" src="assets/LiteCoin-3.png"></img>
        </div>
        <div className="flex flex-col gap-16">
          <div className="flex flex-col lg:gap-1 gap-5">
            <div className="flex justify-center">
              <div className="w-[80%] flex flex-col lg:flex-row justify-center gap-6 lg:gap-0">
                <div className="lg:w-[33%] flex items-center gap-2">
                  <img src="assets/Icons.png"></img>
                  <p className="text-white font-rubik font-bold text-2xl">
                    AI assist Trading system
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2 ">
                  <img src="assets/ic_outline-account-balance-wallet.png"></img>
                  <p className="text-white font-rubik font-bold text-2xl ">
                    Zero collateral <br></br>fee
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2">
                  <img src="assets/Icons (1).png"></img>
                  <p className="text-white font-rubik font-bold text-2xl">
                    Loss less trading experience{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center ">
              <div className="w-[80%] flex flex-col lg:flex-row justify-center gap-6 lg:gap-0">
                <div className="lg:w-[33%] flex items-center gap-2">
                  <p className="text-[#b5a5ab] font-rubik text-xl">
                    Optimize trades with AI for maximum efficiency and profit.
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2">
                  <p className="text-[#b5a5ab] font-rubik text-xl">
                    {" "}
                    Trade without needing upfront collateral.
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2">
                  <p className="text-[#b5a5ab] font-rubik text-xl">
                    Minimize risks for a safer trading environment.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:gap-1 gap-5">
            <div className="flex justify-center">
              <div className="w-[80%] flex flex-col lg:flex-row justify-center gap-5 lg:gap-0">
                <div className="lg:w-[33%] flex items-center gap-2">
                  <img src="assets/Icons (2).png"></img>
                  <p className="text-white font-rubik font-bold text-2xl">
                    Real time <br></br>settlement
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2 ">
                  <img src="assets/ic_baseline-card-giftcard.png"></img>
                  <p className="text-white font-rubik font-bold text-2xl ">
                    Faster<br></br> Transaction{" "}
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2">
                  <img src="assets/ic_outline-headset-mic.png"></img>
                  <p className="text-white font-rubik font-bold text-2xl">
                    AI arbitrage Integration{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center ">
              <div className="w-[80%] flex flex-col lg:flex-row justify-center gap-5 lg:gap-0">
                <div className="lg:w-[33%] flex items-center gap-2">
                  <p className="text-[#b5a5ab] font-rubik text-xl">
                    {" "}
                    Instant transaction processing <br></br>for quicker results.
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2">
                  <p className="text-[#b5a5ab] font-rubik text-xl">
                    {" "}
                    Execute trades rapidly to capitalize on market <br></br>
                    movements.
                  </p>
                </div>
                <div className="lg:w-[33%] flex items-center gap-2">
                  <p className="text-[#b5a5ab] font-rubik text-xl">
                    Automated arbitrage opportunities identified by AI for
                    profit maximization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end lg:-translate-y-20">
          <img src="assets/Bitcoin-1 (1).png"></img>
        </div>
        <div className="flex justify-center">
          <div className="w-[80%] flex flex-col lg:flex-row gap-5 justify-center border-t border-[#58404b]">
            {/* <p className="text-white font-rubik">
              Privacy & Terms&nbsp;Contact Us
            </p> */}
            <p className="text-white font-rubik">Copyright @ ainstein</p>
            {/* <p className="flex items-center ">
              <img
                className=""
                src="assets/foundation_social-youtube.png"
              ></img>
              <img src="assets/foundation_social-linkedin.png"></img>
              <img src="assets/foundation_social-facebook.png"></img>
              <img src="assets/foundation_bitcoin-circle.png"></img>
            </p> */}
          </div>
        </div>
      </div>
      <div>
        {isOpen && (
          <div className=" w-full  fixed inset-0  bg-black bg-opacity-10 backdrop-blur-md">
            <div className="modal-container w-full flex justify-center mt-20 ">
              <div
                className="modal-content lg:w-[80%]  px-5 py-2 bg-no-repeat bg-cover bg-center bg-black"
                style={{ backgroundImage: "url('assets/Background (4).png')" }}
              >
                <div className="flex justify-end">
                  <button
                    className=" shadow-xl rounded-lg text-xl text-red-500 font-bold    transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2"
                    onClick={toggleModal}
                  >
                    <i class="fa-solid fa-xmark "></i>
                  </button>
                </div>
                <div>
                  <p className="text-white text-center font-syne text-2xl font-bold">
                    Understanding Flash Loans in DeFi:
                  </p>
                  <p className="text-white text-center text-sm">
                    {" "}
                    Theoretical Documentation on Blockchain Technology
                  </p>
                </div>

                <div className="flex flex-col gap-5 font-syne mt-3 max-h-[350px] overflow-y-scroll">
                  <li className="text-white">
                    <span className="font-bold text-xl">
                      1. Introduction to Blockchain
                    </span>
                    <br></br>
                    Blockchain is a decentralized, distributed ledger technology
                    that records transactions across many computers in such a
                    way that the registered transactions cannot be altered
                    retroactively. This ensures the security and transparency of
                    data.<br></br>
                    <span className="font-bold px-5">
                      Core Characteristics:
                    </span>
                    <li>
                      Decentralization: No central authority controls the data.
                    </li>
                    <li>
                      {" "}
                      Immutability: Once data is recorded, it cannot be changed
                      or deleted.
                    </li>
                    <li>
                      Transparency: All transactions are visible to
                      participants.
                    </li>
                    <li>
                      Security: Cryptographic techniques ensure data integrity
                      and security.
                    </li>
                  </li>
                  <li className="text-white">
                    <span className="font-bold text-xl">
                      2. Types of Blockchains
                    </span>
                    <br></br>
                    <span className="font-bold">1. Public Blockchain:</span>
                    <li>
                      - Description: Open to everyone, anyone can join and
                      participate.
                    </li>
                    <li> - Examples: Bitcoin, Ethereum.</li>
                    <li>
                      - Use Cases: Cryptocurrencies, public records, supply
                      chain tracking.
                    </li>
                    <span className="font-bold">2. Private Blockchain:</span>
                    <li>
                      - Description: Restricted access, only authorized
                      participants can join.
                    </li>
                    <li> - Examples: Hyperledger, Corda.</li>
                    <li>
                      - Use Cases: Enterprise solutions, confidential data
                      management, internal voting systems.
                    </li>
                    <span className="font-bold">3. Consortium Blockchain:</span>
                    <li>
                      - Description: Controlled by a group of organizations,
                      rather than a single entity.
                    </li>
                    <li> - Examples: Quorum, Energy Web Foundation.</li>
                    <li>
                      - Use Cases: Banking and finance, cross-organization
                      operations, consortium-driven projects.
                    </li>
                    <span className="font-bold">4. Hybrid Blockchain:</span>
                    <li>
                      - Description: Combines elements of both public and
                      private blockchains
                    </li>
                    <li> - Examples: Dragonchain, XinFin.</li>
                    <li>
                      - Use Cases: Supply chain management, asset management,
                      regulatory compliance.
                    </li>
                  </li>
                  <li className="text-white">
                    <span className="font-bold text-xl">
                      3. Use Cases of Blockchain
                    </span>
                    <br></br>
                    <span className="font-bold">1. Cryptocurrencies:</span>
                    <li>
                      - Description: Digital currencies that use blockchain
                      technology for secure transactions.
                    </li>
                    <li> - Examples: Bitcoin, Ethereum, Litecoin.</li>
                    <li>
                      - Functionalities: Peer-to-peer transactions,
                      decentralized finance (DeFi) applications, smart
                      contracts.
                    </li>
                    <span className="font-bold">
                      2. Supply Chain Management:
                    </span>
                    <li>
                      - Description: Blockchain ensures transparency and
                      traceability in the supply chain.
                    </li>
                    <li> - Examples: IBM Food Trust, VeChain.</li>
                    <li>
                      - Functionalities: Tracking product origin, verifying
                      authenticity, reducing fraud.
                    </li>
                    <span className="font-bold">3. Healthcare:</span>
                    <li>
                      - Description: Blockchain can secure and streamline
                      healthcare records.
                    </li>
                    <li> - Examples: MedicalChain, Patientory.</li>
                    <li>
                      - Functionalities: Secure patient records,
                      interoperability between healthcare providers, patient
                      consent management.
                    </li>
                    <span className="font-bold">4. Voting Systems:</span>
                    <li>
                      - Description: Blockchain can create secure, transparent,
                      and tamper-proof voting systems.
                    </li>
                    <li> - Examples: Follow My Vote, Voatz.</li>
                    <li>
                      - Functionalities: Voter registration, vote casting, vote
                      counting and verification.
                    </li>
                    <span className="font-bold">5. Real Estate:</span>
                    <li>
                      - Description: Blockchain can simplify and secure real
                      estate transactions.
                    </li>
                    <li> - Examples: Propy, RealBlocks.</li>
                    <li>
                      - Functionalities: Property title management, transparent
                      property transactions, fractional ownership.
                    </li>
                    <span className="font-bold">6. Identity Management:</span>
                    <li>
                      - Description: Blockchain can provide secure and
                      verifiable identity solutions.
                    </li>
                    <li> - Examples: Civic, uPort.</li>
                    <li>
                      - Functionalities: Identity verification, digital identity
                      creation, privacy-preserving identity management.
                    </li>
                    <span className="font-bold">
                      7. Internet of Things (IoT):
                    </span>
                    <li>
                      - Description: Blockchain can enhance the security and
                      interoperability of IoT devices.
                    </li>
                    <li> - Examples: IOTA, IoTeX.</li>
                    <li>
                      - Functionalities: Secure device communication, automated
                      smart contracts, decentralized IoT networks.
                    </li>
                  </li>
                  <li className="text-white">
                    <span className="font-bold text-xl">
                      4. Blockchain Functionalities
                    </span>
                    <br></br>
                    <span className="font-bold">1. Smart Contracts:</span>
                    <li>
                      - Description: Self-executing contracts with the terms
                      directly written into code.
                    </li>
                    <li>
                      {" "}
                      - Benefits: Automate and streamline processes, reduce need
                      for intermediaries, enforce contract terms automatically.
                    </li>
                    <span className="font-bold">
                      2. Decentralized Applications (dApps):
                    </span>
                    <li>
                      - Description: Applications that run on a blockchain
                      network rather than a centralized server.
                    </li>
                    <li>
                      {" "}
                      - Benefits: Increased security, transparency, and
                      resistance to censorship.
                    </li>
                    <span className="font-bold">3. Tokenization:</span>
                    <li>
                      - Description: Converting rights to an asset into a
                      digital token on the blockchain.
                    </li>
                    <li>
                      {" "}
                      - Benefits: Improved liquidity, fractional ownership,
                      easier transferability.
                    </li>
                    <span className="font-bold">4. Consensus Mechanisms:</span>
                    <li>
                      - Description: Methods to achieve agreement on the state
                      of the blockchain among distributed nodes.
                    </li>
                    <li>
                      {" "}
                      - Types: Proof of Work (PoW), Proof of Stake (PoS),
                      Delegated Proof of Stake (DPoS), Byzantine Fault Tolerance
                      (BFT).
                    </li>
                    <li>- Benefits: Ensures network security and integrity.</li>
                    <span className="font-bold">5. Cryptographic Hashing:</span>
                    <li>
                      - Description: Creating a unique digital fingerprint of
                      data.
                    </li>
                    <li>
                      {" "}
                      - Benefits: Ensures data integrity, secures transactions,
                      prevents tampering.
                    </li>
                    <span className="font-bold">
                      6. Distributed Ledger Technology (DLT):
                    </span>
                    <li>
                      - Description: Database spread across multiple nodes.
                    </li>
                    <li>
                      {" "}
                      - Benefits: Enhances transparency, security, and
                      decentralization.
                    </li>
                  </li>
                  <li className="text-white">
                    <span className="font-bold text-xl">
                      Introduction of Flash loan:
                    </span>
                    <br></br>
                    <span className="font-bold">1. What are Flash Loans?</span>
                    <li>
                      - Flash loans are unique financial instruments in
                      decentralized finance (DeFi) that allow users to borrow
                      assets without providing collateral, as long as the
                      borrowed amount plus a fee is returned within the same
                      transaction block.
                    </li>

                    <span className="font-bold">
                      2. Why Flash Loans are Revolutionary?
                    </span>
                    <li>
                      - Flash loans exploit the unique properties of blockchain
                      transactions, enabling instantaneous and temporary
                      borrowing for various advanced financial operations.
                    </li>

                    <span className="font-bold"> Conceptual Overview:</span>
                    <li>1. How Flash Loans Work:</li>
                    <li>
                      {" "}
                      - Flash loans operate within the confines of a single
                      blockchain transaction. If the loan and fees are not
                      repaid by the end of the transaction, the transaction
                      reverts, ensuring no risk to the lender.
                    </li>
                    <li className="">2. Key Characteristics:</li>
                    <li>- No collateral needed.</li>
                    <li>- Must be repaid within one block.</li>
                    <li>
                      - Mainly used by developers due to the complexity
                      involved.
                    </li>

                    <span className="font-bold ">
                      {" "}
                      Technical Execution Flow:
                    </span>
                    <li>1. Initiating a Flash Loan:</li>
                    <li>
                      {" "}
                      - The borrower’s contract requests a flash loan from the
                      liquidity pool.
                    </li>
                    <li>
                      - The pool checks the request and transfers the funds to
                      the borrower’s contract.
                    </li>
                    <li>2. Using the Borrowed Funds:</li>
                    <li>
                      {" "}
                      - The borrower’s contract performs any desired operations
                      with the borrowed funds, such as arbitrage or collateral
                      swapping.
                    </li>
                    <li>3. Repayment within the Same Transaction:</li>
                    <li>
                      {" "}
                      - The borrower’s contract must approve the pool to
                      withdraw the loaned amount plus a fee by the end of the
                      transaction.
                    </li>
                    <li>
                      - If funds are insufficient or approval is not given, the
                      transaction reverts.
                    </li>
                    <span className="font-bold">
                      Types of Flash Loans in Aave V3:
                    </span>
                    <li>1. flashLoan:</li>
                    <li> - Allows borrowing from multiple reserves.</li>
                    <li>
                      - Option to open a stable or variable rate debt position.
                    </li>
                    <li>
                      - Flash loan fees can be waived for approved borrowers.
                    </li>
                    <li>2. flashLoanSimple:</li>
                    <li> - Allows borrowing from a single reserve.</li>
                    <li>
                      - More gas-efficient, suitable for simpler operations.
                    </li>
                    <li>
                      - Fees are not waived and no debt position can be opened.
                    </li>
                    <span className="font-bold">
                      {" "}
                      Applications of Flash Loans:
                    </span>
                    <li>1. Arbitrage:</li>
                    <li>
                      {" "}
                      - Exploiting price differences across exchanges without
                      needing upfront capital.
                    </li>
                    <li>2. Collateral Swapping:</li>
                    <li>
                      {" "}
                      - Replacing one type of collateral with another to
                      optimize debt positions.
                    </li>
                    <li>3. Liquidation:</li>
                    <li>
                      {" "}
                      - Facilitating the repayment of debt positions by using
                      the collateral to repay the flash loan.
                    </li>
                    <span className="font-bold"> Fee Structure:</span>
                    <li>1. Flash Loan Fee:</li>
                    <li>
                      {" "}
                      - Initially set at 0.05%, can be updated via governance.
                    </li>
                    <li>
                      {" "}
                      - Split between liquidity providers (LPs) and the protocol
                      treasury.
                    </li>
                  </li>

                  <div className="flex justify-center mt-3">
                    <p className="flex gap-1 items-center text-white font-rubik">
                      <input
                        type="checkbox"
                        id="accept"
                        onChange={handleCheckboxChange}
                      ></input>
                      &nbsp;<p>I read all the above details.</p>
                    </p>
                  </div>
                  <div className="flex justify-center">
                    <div
                      className="flex items-center cursor-pointer gap-3 shadow-xl shadow-[#48121a]  px-2 py-2 bg-gradient-to-b from-[#54142b] to-[#320b19] text-[#ef443b] font-bold rounded-full border border-[#4e131b]"
                      onClick={proceedButton}
                      disabled={!isChecked}
                    >
                      <img className="w-2/12" src="assets/bg (4).png"></img>
                      Proceed
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "../App.css";
import AdminHeader from "./AdminHeader";
import Chart from "react-apexcharts";
import axios from "axios";
import DataSpinners from "../FetchDatas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NODE_URL } from "../config";

const Users = () => {
  const [TotalUsers, setTotalUsers] = useState(0);
  const [FetchingDatasSpinner, setFetchingDatasSpinner] = useState(false);
  const [userEnteredAddress, setUserEnteredAddress] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);
  const [graph, setGraph] = useState([]);
  const [TotalTransactions, setTotalTransactions] = useState([]);

  const fetchData = async () => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersCount`);
      const res = response.data;
      setTotalUsers(res.userCount);
      setFetchingDatasSpinner(false);
    } catch (error) {
      console.log("Error while fetching user count: ", error);
      setFetchingDatasSpinner(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchHistory();
    fetchCount();
  }, []);

  const fetchHistory = async () => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersInfo`);
      const res = response.data.usersInfo;
      setTransactions(res);
      setTotalTransactions(res);
      setFetchingDatasSpinner(false);
    } catch (error) {
      console.log("Error while fetching transactions: ", error);
    }
  };

  const handleFilterSubmit = async () => {
    try {
      let filteredData = transactions;
      if (userEnteredAddress.trim() !== "") {
        filteredData = filteredData.filter((item) =>
          item.userAddress.toLowerCase() === userEnteredAddress.trim().toLowerCase());
      }
      setTransactions(filteredData);
      setCurrentPage(1);
    } catch (error) {
      console.error("Error while using filter : ", error);
    }
  };

  const itemsPerPage = 10;

  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = transactions.slice(
    indexOfFirstTransaction,
    indexOfLastTransaction
  );
  const totalPages = Math.ceil(transactions.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const fetchCount = async () => {
    try {
      setFetchingDatasSpinner(true);
      const response = await axios.get(`${NODE_URL}:3006/apiv4/getUsersCountForPlatform`);
      const res = response.data;
      if (Array.isArray(res)) {
        setGraph(res);
      } else {
        setGraph([0, 0, 0, 0, 0, 0, 0]);
      }
      return res;
    } catch (error) {
      console.log("Error while fetching users Count For Platform: ", error);
      setFetchingDatasSpinner(false);
    }
  };

  const getPreviousDays = () => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const today = new Date().getDay();
    const previousDays = [];

    for (let i = today; i >= 0; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }
    for (let i = daysOfWeek.length - 1; i > today; i--) {
      previousDays.unshift(daysOfWeek[i]);
    }
    return previousDays;
  };

  const clearFilter = () => {
    setUserEnteredAddress("");
    setTransactions(TotalTransactions);
    setCurrentPage(1);
  };

  const state = {
    options: {
      colors: ['#F98736'],
      stroke: { width: 2, curve: "smooth" },
      chart: {
        id: "basic-bar",
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: getPreviousDays(),
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        style: {
          background: "#9381FF",
        },
        theme: "dark",
        y: {
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
      },
    },
    series: [
      {
        name: "New Users",
        data: graph,
      },
    ],
  };

  return (
    <div className=" text-white font-poppins">
      <div
        className=" bg-cover h-screen overflow-hidden overflow-y-auto bg-black h-full"
        style={{ backgroundImage: `url(assets/bg.jpeg)` }}
      >
        <div className="md:absolute z-0 md:top-0 md:-left-10 md:w-[30rem] md:h-[30rem] md:filter md:blur-2xl   md:bg-[#E43875A3]/15 md:rounded-full "></div>
        <div className="">
          <AdminHeader />

          <div className="lg:px-16 md:px-10 px-5  pb-20">
            <div className="lg:flex justify-center items-center  lg:gap-10">
              <div className="lg:w-[30%] mt-10  p-[2px] rounded-2xl bg-[#FFFFFF0A]">
                <p className="text-xl font-bold font-Artemus text-[#F98736] text-center mt-5">
                  Total Users
                </p>
                <div className="flex px-5 py-5 items-center rounded-2xl">
                  <div className="w-[50%]">
                    <div>
                      <img
                        src="./assets/user1.png"
                        className="lg:w-[9rem] md:w-[7rem] sm:w-[5rem] w-20 object-contain brightness-200"
                      ></img>
                    </div>
                  </div>
                  <div className="w-[50%] text-center">
                    <p className="font-bold text-2xl mt-1">{TotalUsers} +</p>
                  </div>
                </div>
              </div>

              <div className="lg:w-[70%] h-52 sm:h-96  xl:h-[12rem] mt-5 p-[2px] #FFFFFF14/14 rounded-2xl">
                <div className="h-full py-5 p-2 rounded-2xl block ">
                  <p className="font-bold text-left mx-5 "> Overview</p>
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="line"
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center mt-5  gap-5">
              <input
                className="w-[90%] z-10 text-[10px] md:text-xs rounded-xl px-3 py-2 outline-none bg-[#D9D9D930]"
                type="text"
                placeholder="Search for the wallet Address"
                value={userEnteredAddress}
                onChange={(e) => setUserEnteredAddress(e.target.value)}
              />
              {userEnteredAddress && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className=""
                  onClick={clearFilter}
                />
              )}
              <button
                className="bg-[#E43875A3]/40 md:px-8 px-4 py-2 text-xs font-semibold rounded-xl"
                onClick={handleFilterSubmit}
              >
                Search
              </button>
            </div>

            <div className="p-[2px] rounded-2xl mt-5 bg-[#E4387536]">
              <div className="h-full py-3 p-2 rounded-2xl bg-cover pt-7 overflow-hidden overflow-x-scroll">
                <table className="table-auto text-xs w-full">
                  <thead>
                    <tr>
                      <th className="px-4 pb-5 text-base text-center">S.No</th>
                      <th className="px-4 pb-5 text-base text-center">
                        Wallet Address
                      </th>
                      <th className="px-4 pb-5 text-base text-center">
                        Date of Subscription
                      </th>
                      <th className="px-4 pb-5 text-base text-center">
                        Total Limit
                      </th>
                      <th className="px-4 pb-5 text-base text-center">
                        Transactions Run
                      </th>
                      <th className="px-4 pb-5 text-base text-center">
                        Total Profits
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm">
                    {currentTransactions && currentTransactions.length > 0 ? (
                      currentTransactions.map((trans, index) => (
                        <tr key={index}>
                          <td className="px-4 py-5 font-bold text-center break-all">
                            {indexOfFirstTransaction + index + 1}
                          </td>
                          <td className="px-4 py-5 font-bold text-center break-all">
                            {trans.userAddress}
                          </td>
                          <td className="px-4 py-5 text-center">
                            {trans.firstTransactionDate}
                          </td>
                          <td className="px-4 py-5 text-center font-bold">
                            {trans.TotalTradeLimit}
                          </td>
                          <td className="px-4 py-5 text-center font-bold">
                            {trans.transactionCount}
                          </td>
                          <td className="px-4 py-5 text-xs text-[#1EEF32] text-center">
                            ${trans.totalProfitUSD ?? 0}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="px-4 py-5 text-center" colSpan="5">
                          No transactions available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="flex justify-between mt-4 lg:px-10 px-6 py-2 text-xs">
                  <button
                    className="mr-20 hover:bg-[#E43875A3] rounded-lg md:text-base text-xs text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875A3]"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous Page
                  </button>
                  <div className="flex items-center">
                    <p>{`Page ${currentPage} of ${totalPages}`}</p>
                  </div>
                  <button
                    className="ml-20 hover:bg-[#E43875A3] md:text-base text-xs rounded-lg text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875A3]"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next Page
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {FetchingDatasSpinner ? <DataSpinners /> : ""}
      </div>
    </div>
  );
};

export default Users;

import { useState, useEffect } from 'react';
import polytokens from './ToPolygon.json'

export const ImageComponent = ({ symbol }) => {
  const [imageUrl, setImageUrl] = useState('');

  const imgCheck = async (symbol) => {
    //console.log(symbol)
    if (symbol == 'WMATIC') {
      return "https://static.coinpaprika.com/coin/wmatic-wrapped-matic-wormhole/logo.png?rev=10995429";
    }
    else if (symbol == 'LINK') {
      return 'https://s2.coinmarketcap.com/static/img/coins/200x200/1975.png'
    }
    else if (symbol == 'AAVE') {
      return "https://s2.coinmarketcap.com/static/img/coins/200x200/7278.png"
    }
    else if(symbol == null){
      return "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    }
    else {
      const token = polytokens.find((token) => token.symbol === symbol);
      return token.img;
    }

  }
  useEffect(() => {
    const fetchImageUrl = async () => {
      const imgUrl = await imgCheck(symbol);
      setImageUrl(imgUrl);
    };
    try {
      fetchImageUrl();
    } catch (err) {
      console.log(err)
    }
  }, [symbol]);

  return <img src={imageUrl} className='w-[20px]' alt={symbol} />;
};

import React, { useState, useEffect } from 'react'
import '../App.css'
import AdminHeader from "./AdminHeader.js";
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";


const Purchasehistory = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const [TotalTrades, setTotalTrades] = useState('');
  const [transactions, setTransactions] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState();


  const dateformat = async (date) => {
    const originalDate = new Date(date);

    const day = originalDate.getUTCDate();
    const month = originalDate.getUTCMonth() + 1;
    const year = originalDate.getUTCFullYear() % 100;
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year.toString().padStart(2, '0')}`;

    return formattedDate
  }

  const planMapping = {
    license1: 'Basic',
    license2: 'Survival',
    license3: 'Professional',
    license4: 'Premium',
    license5: 'Rocketship'
  };


  const fetchData = async (filter) => {
    try {
      const response = await axios.get(`http://localhost:3006/apiv4/getSubscriptionInfo`);
      const res = response.data.SubscriptionInfo;
      console.log(filter)
      const filteredData = filter
        ? res.filter(item => item.plan === filter)
        : res;

      setTotalTrades(filteredData.length);


      const transactions = await Promise.all(filteredData.map(async (trans) => {
        const formattedDate = await dateformat(trans.date);

        const mappedPlan = planMapping[trans.plan] || 'Unknown Plan';

        return {
          userAddress: trans.userAddress,
          plan: mappedPlan,
          amount: Math.round(trans.amount),
          type: trans.type,
          hash: trans.hash,
          date: formattedDate,
        };
      }));

      setTransactions(transactions);
    } catch (error) {
      console.log('Error while fetching: ', error);
    }
  };


  useEffect(() => {
    fetchData()
  }, []);


  const itemsPerPage = 10;

  const indexOfLastTransaction = currentPage * itemsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - itemsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  const totalPages = Math.ceil(TotalTrades / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFilterSelect = (selectedFilter) => {
    console.log(selectedFilter)
    setIsOpen(false);
    setCurrentPage(1);
    setFilter(planMapping[selectedFilter]);
    fetchData(selectedFilter);
  };


  return (
    <div className="bg-black text-white font-poppins bg-cover bg-no-repeat h-screen bg-center" style={{ backgroundImage: `url(assets/light_bg.png)` }}>

      <div
        className=" bg-cover bg-no-repeat bg-black min-h-screen"
        style={{ backgroundImage: `url(assets/bg.jpeg)` }}>

        <div className="md:absolute md:top-14 md:-left-48 md:w-[30rem] md:h-[35rem]  md:filter md:blur-3xl  md:bg-[#DD7C0B]/10 md:rounded-full"></div>
        <div className="md:absolute md:bottom-24 md:right-0 md:w-[24rem] md:h-[24rem]  md:filter md:blur-3xl  md:bg-[#DD7C0B]/10 md:rounded-full"></div>
        <div className='relative z-30'>
          <AdminHeader />
          <div className='md:px-16 px-5 md:mt-5 mt-10 pb-20 '>
            <div className='flex space-x-2 items-center '>
              <img className="w-5 h-5" src="assets/Sparkle.svg" />
              <h1 className="text-lg font-bold font-Artemus text-[#F98736]">Purchase History</h1>
            </div>

            <div className="flex items-center mt-10 space-x-5">
              <input
                className="w-[80%] text-[10px] md:text-xs rounded-xl px-5 py-2 outline-none bg-[#D9D9D930]"
                type="text"
                placeholder="Search for the wallet Address"
              />
              <div className="relative">
                <button className="sm:flex items-center md:mt-1 md:space-x-2" onClick={toggleDropdown}>
                  <FontAwesomeIcon icon={faSliders} />
                  <p className="text-sm">{filter === null ? 'Filter' : filter}</p>
                </button>



                {isOpen && (
                  <div className="absolute w-44 mt-2 z-10 bg-black shadow-md rounded-md">
                    <div className="px-10 text-xs py-2 border-b border-gray-500">Recently Added</div>
                    <ul className="flex flex-col text-xs gap-3 p-5 px-10">
                      <button className="flex justify-center py-1 text-[#15803d] border-white border rounded-md focus:outline-none"
                        onClick={() => handleFilterSelect('license1')}
                      >
                        Basic
                      </button>
                      <button className="flex justify-center py-1 border border-white rounded-md text-[#2563eb] focus:outline-none"
                        onClick={() => handleFilterSelect('license2')}
                      >
                        Survival
                      </button>
                      <button className="flex justify-center py-1 border border-white rounded-md text-[#97079A] focus:outline-none"
                        onClick={() => handleFilterSelect('license3')}
                      >
                        Professional
                      </button>
                      <button className="flex justify-center py-1 border border-white rounded-md text-[#FFBA00] focus:outline-none"
                        onClick={() => handleFilterSelect('license4')}
                      >
                        Premium
                      </button>
                      <button className="flex justify-center py-1 border-white border rounded-md text-[#dc2626] focus:outline-none"
                        onClick={() => handleFilterSelect('license5')}
                      >
                        Rocketship
                      </button> </ul>
                  </div>
                )}
              </div>
              <button className="bg-[#E43875A3] md:px-8 px-4 py-2 text-xs font-semibold rounded-xl">
                Search
              </button>
            </div>

            <div className=" p-[2px] rounded-2xl mt-5">
              <div className='pt-5 py-3 overflow-hidden  bg-[#E4387524] overflow-x-scroll h-full  p-2 rounded-2xl block'>
                <table class="table-auto text-xs">
                  <thead className='text-base'>
                    <tr className="">
                      <th className="px-10 pb-5 flex justify-start">Wallet Address</th>
                      <th className="px-10 pb-5">Plans</th>
                      <th className="px-10 pb-5">Amount</th>
                      <th className="px-10 pb-5">Payment type</th>
                      <th className="px-10 pb-5">Transaction Hash</th>
                      <th className="px-10 pb-5">Payment Date</th>
                    </tr>
                  </thead>
                  <tbody className="text-xs ">
                    {currentTransactions && currentTransactions.length > 0 ? (
                      currentTransactions.map((trans) => (
                        <tr className=''>
                          <td className="px-10 py-5 font-bold" style={{ paddingRight: "2.5rem" }}>
                            {trans.userAddress}
                          </td>
                          <td className="px-10 py-5 font-bold text-[#FFBA00] text-center">
                            {trans.plan === 'Survival' ? (
                              <span className="text-[#2563eb]">Survival</span>
                            ) : trans.plan === 'Premium' ? (
                              <span className="text-[#FFBA00]">Premium</span>
                            ) : trans.plan === 'Professional' ? (
                              <span className="text-[#97079A]">Professional</span>
                            ) : trans.plan === 'Basic' ? (
                              <span className="text-[#15803d]">Basic</span>
                            ) : trans.plan === 'Rocketship' ? (
                              <span className="text-[#dc2626]">Rocketship</span>
                            ) : (
                              <span className="text-[#FF0000]">Unknown Plan</span>
                            )}

                          </td>
                          <td className="flex justify-center px-10 py-5 text-center">$ {trans.amount}</td>
                          <td className=" px-10 py-5 text-center">{trans.date}</td>
                          <td className="text-xs px-10 py-5 text-center">
                            <a
                              href={`https://polygonscan.com/tx/${trans.hash}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-[#1EEF32] hover:text-[#1EEF32] hover:underline"
                            >
                              {trans.hash.length > 10 ? trans.hash.slice(0, 10) + '...' : trans.hash}
                            </a>
                          </td>
                          <td className=" px-10 py-5 text-center">{trans.date}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className=' py-10 text-xs' colSpan="5" style={{ textAlign: 'center', fontSize: '18px' }}>
                          No transactions available
                        </td>
                      </tr>

                    )}
                  </tbody>
                </table>

                <div className="flex justify-between mt-4 px-10 py-2 text-xs">
                  <button
                    className="mr-20 hover:bg-[#E43875A3] rounded-lg text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875A3]"
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous Page
                  </button>
                  <div className="flex items-center">
                    <p>{`Page ${currentPage} of ${totalPages}`}</p>
                  </div>
                  <button
                    className="ml-20 hover:bg-[#E43875A3] rounded-lg text-white font-semibold p-3 bg-transparent hover:border-transparent border border-[#E43875A3]"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next Page
                  </button>
                </div>
              </div>
            </div>

          </div>


        </div>

      </div>
    </div>
  )
}

export default Purchasehistory